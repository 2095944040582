import React from 'react';
import {useSelector} from 'react-redux';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RefreshIcon from '@mui/icons-material/Refresh';
import {Grid, Slider} from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ParentSize from '@visx/responsive/lib/components/ParentSizeModern';
import moment from 'moment';

import ActivityGraph from './ActivityGraph';

const PlaybackControls = ({
  polygons,
  time = moment().startOf('day').valueOf(),
  startTime,
  speed,
  pause,
  setTime,
  setSpeed,
  setPause,
}) => {
  const frameRate = useSelector((state) => state.reports.frameRate);
  const from = useSelector((state) => state.reports.from);
  const to = useSelector((state) => state.reports.to);
  const handleSliderChange = (event, value) => {
    setTime(value);
  };

  return (
    <>
      <Grid
        item
        container
        xs={12}
        sx={{
          display: 'flex',
          direction: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px 0px',
        }}>
        Time: {time && new Date(time).toLocaleTimeString('en-GB')}
      </Grid>
      <Grid item xs={12}>
        <ParentSize className="graph-container" debounceTime={10}>
          {({width, height}) => <ActivityGraph polygons={polygons} width={width} height={20} setTime={setTime} />}
        </ParentSize>
      </Grid>
      <Grid item xs={12}>
        <Slider size="small" step={frameRate} min={from} max={to} value={time} onChange={handleSliderChange} />
      </Grid>
      <Grid item xs={12}>
        <IconButton
          color="primary"
          aria-label="replay"
          component="span"
          onClick={() => setTime(startTime)}
          size="large">
          <RefreshIcon />
        </IconButton>
        <IconButton
          color="primary"
          aria-label="play"
          component="span"
          onClick={() => setPause(false)}
          disabled={!pause}
          size="large">
          <PlayArrowIcon />
        </IconButton>
        <IconButton
          color="primary"
          aria-label="pause"
          component="span"
          onClick={() => setPause(true)}
          disabled={pause}
          size="large">
          <PauseIcon />
        </IconButton>
        <Button
          size="small"
          color="primary"
          aria-label="half-speed"
          component="span"
          onClick={() => setSpeed(1)}
          sx={speed === 1 ? {border: 1} : null}
          disabled={speed === 1}>
          x1
        </Button>
        <Button
          size="small"
          color="primary"
          aria-label="normal-speed"
          component="span"
          onClick={() => setSpeed(0.2)}
          sx={speed === 0.2 ? {border: 1} : null}
          disabled={speed === 0.2}>
          x5
        </Button>
        <Button
          size="small"
          color="primary"
          aria-label="double-speed"
          component="span"
          onClick={() => setSpeed(0.1)}
          sx={speed === 0.1 ? {border: 1} : null}
          disabled={speed === 0.1}>
          x10
        </Button>
      </Grid>
    </>
  );
};

export default React.memo(PlaybackControls);
