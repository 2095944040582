import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormControl, ListSubheader, MenuItem, Select, Typography} from '@mui/material';
import {deleteDashboardKeys, setDashboardSource} from 'redux/dashboard.reducer';

import {DEFAULTDASHBOARDSETTINGS} from '../helpers';

const SourceSelector = ({dropdownData, updateChart, isDisabled}) => {
  const dispatch = useDispatch();
  const dashboardSettings = useSelector((state) => state.dashboard.settings);

  const handleSourceChange = useCallback(
    (event) => {
      const selectedSource = dropdownData.find((dd) => dd.id === event.target.value);
      const dashboardSettingsClone = {...dashboardSettings};
      dashboardSettingsClone.sourceType = selectedSource.type;
      dashboardSettingsClone.sourceId = event.target.value;
      dashboardSettingsClone.keys = [];
      if (dashboardSettingsClone.dashboardType === 'occupancy') {
        dashboardSettingsClone.calculateCapacity = !!selectedSource.capacity;
      } else {
        dashboardSettingsClone.calculateCapacity = false;
      }
      dispatch(
        setDashboardSource({
          sourceId: event.target.value,
          sourceType: selectedSource.type,
          counters: DEFAULTDASHBOARDSETTINGS[dashboardSettings.dashboardType].counters,
          calculateCapacity: dashboardSettingsClone.calculateCapacity,
        })
      );
      dispatch(deleteDashboardKeys());
      updateChart(dashboardSettingsClone);
    },
    [dashboardSettings, dispatch, dropdownData, updateChart]
  );

  const handleRenderValue = useCallback(
    (value) => (value ? dropdownData.find((dd) => dd.id === value)?.name : 'There are no sources available'),
    [dropdownData]
  );

  const dropdown = useMemo(() => {
    const firstGroupIndex = dropdownData.findIndex((source) => source.type === 'group');
    const firstSensorIndex = dropdownData.findIndex((source) => source.type === 'sensor');
    return dropdownData.map((source, i) => [
      firstGroupIndex === i && (
        <ListSubheader>
          <Typography variant="overline">Sensor groups</Typography>
        </ListSubheader>
      ),
      firstSensorIndex === i && (
        <ListSubheader>
          <Typography variant="overline">Sensors</Typography>
        </ListSubheader>
      ),
      <MenuItem key={source.id} value={source.id}>
        {source.name}
      </MenuItem>,
    ]);
  }, [dropdownData]);

  return (
    <FormControl style={{textAlign: 'center'}}>
      <Select
        variant="standard"
        aria-describedby="helper-sources"
        id="sources-select"
        disabled={isDisabled}
        value={dropdownData.length === 0 ? '' : dashboardSettings.sourceId}
        displayEmpty
        renderValue={handleRenderValue}
        label="Sources"
        onChange={handleSourceChange}>
        {dropdown}
      </Select>
    </FormControl>
  );
};

export default React.memo(SourceSelector);
