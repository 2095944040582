import React, {useMemo} from 'react';
import {Trans} from '@lingui/macro';
import SaveIcon from '@mui/icons-material/Save';
import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import {styled} from '@mui/material/styles';
import {spacing} from '@mui/system';

/**
 * Applies the spacing system to the material UI Button
 */
const Button = styled(MuiButton)(spacing);

export const SaveButton = (props) => {
  const isSaving = !!props.isSaving;
  const buttonIcon = useMemo(() => {
    if (isSaving) {
      return <CircularProgress size={18} />;
    }
    return <SaveIcon />;
  }, [isSaving]);

  const newProps = {...props};
  delete newProps.isSaving;

  return (
    <Button size="small" variant="contained" ml={2} startIcon={buttonIcon} {...newProps}>
      <Trans>Save</Trans>
    </Button>
  );
};
