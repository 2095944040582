import {LogLevel} from '@azure/msal-browser';
import log from 'loglevel';
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    navigateToLoginRequestUrl: true, // Return to the page that initiated the request
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    authority: process.env.REACT_APP_AUTH_AUTHORITY,
    knownAuthorities: [process.env.REACT_APP_AUTH_DOMAIN],
    redirectUri: '/',
    postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_URL,
  },
  cache: {
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
    cacheLocation: 'localStorage',
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            log.error(message);
            return;
          case LogLevel.Info:
            log.info(message);
            return;
          case LogLevel.Verbose:
            log.debug(message);
            return;
          case LogLevel.Warning:
            log.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: [process.env.REACT_APP_SCOPE_API],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
