import React from 'react';
import {Trans} from '@lingui/macro';
import Button from '@mui/material/Button';

import {withAuthHandler} from '../../oauth';

const SignOutButton = ({authHandler, className}) => (
  <Button onClick={authHandler.doSignOut} color="secondary" size="small" variant="contained" className={className}>
    <Trans>Sign Out</Trans>
  </Button>
);
export default withAuthHandler(SignOutButton);
