import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormControl, ListItemText, MenuItem, Select} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import {setDashboardResolution} from 'redux/dashboard.reducer';

import {RESOLUTIONS} from '../helpers';

const ResolutionSelector = ({updateChart, isDisabled}) => {
  const dispatch = useDispatch();
  const dashboardSettings = useSelector((state) => state.dashboard.settings);

  const resolutionDropdown = useMemo(
    () =>
      RESOLUTIONS[dashboardSettings.view].map((r) => (
        <MenuItem key={r.value} value={r.value}>
          <ListItemText primary={r.name} />
        </MenuItem>
      )),
    [dashboardSettings.view]
  );

  const hanldeResolutionChange = useCallback(
    (event) => {
      const dashboardSettingsClone = {...dashboardSettings};
      dashboardSettingsClone.resolution = event.target.value;
      dispatch(setDashboardResolution(event.target.value));
      updateChart(dashboardSettingsClone);
    },
    [dashboardSettings, dispatch, updateChart]
  );

  return (
    <FormControl variant="standard">
      <InputLabel id="resolution-label">Resolution</InputLabel>
      <Select
        labelId="resolution-label"
        id="resolution-select"
        disabled={isDisabled}
        value={dashboardSettings.resolution}
        label="Resolution"
        onChange={hanldeResolutionChange}>
        {resolutionDropdown}
      </Select>
    </FormControl>
  );
};

export default React.memo(ResolutionSelector);
