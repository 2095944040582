import React, {useCallback, useMemo} from 'react';
import {Group} from '@visx/group';
import {Bar} from '@visx/shape';

const Bars = ({chartData, keys, innerHeight, innerWidth, xScale, yScale}) => {
  // Bar chart is displayed only in Counters and Flow dashboards, where comparisons
  // are not allowed. So Bars always has only one graph (key)
  const getValue = useCallback((d) => d[keys[0].name], [keys]);

  const generatedBars = useMemo(() => {
    const spaceBetweenBars = 0;
    // generating a bar for each data point
    return chartData.map((d, i) => {
      const date = d.start;
      let barWidth;
      if (i === 0) {
        // xScale(timestamp) gets the coordinate of the timestamp on the x axis.
        // The x coordinate of the first data point is 0. The first bar might be narrower then others because
        // it might start at irregular time (e.g. at 8:14) while the rest are regular (e.g. 9:00).
        // The first bar should end where the second data point starts.
        // So we calculate the width of the first bar as 0 + xScale(timestamp of the second datapoint)
        barWidth = xScale(chartData[1].start) - spaceBetweenBars;
      } else {
        barWidth = innerWidth / chartData.length - spaceBetweenBars;
      }
      let barHeight = 0;
      if (!getValue(d)) {
        // barHeight of 0 px will cause the bar to not be drawn
        // We dont want to display bars for datapoints with null value e.g. if the camera was off.
        // But if sensor worked and there were 0 objects detected, then we want
        // to show it by giving the bar a hight of 1 px
        barHeight = getValue(d) === 0 ? 1 : 0;
      } else {
        barHeight =
          yScale.domain()[1] >= 0
            ? innerHeight - (yScale(getValue(d)) ?? 0) // if there are only positive values in the chart
            : Math.abs(yScale(getValue(d)) - yScale(0)); // if there are negative and positive values
      }
      const barX = xScale(d.start); // where the bar should start on the x axis
      // where the bar should start on the y axis
      // if the chart has only positive values, then its bars are relative to the chart hight
      // if the chart has both positive and negative values then its bars are relative to the positive of 0 on y axis
      const barY =
        yScale.domain()[1] >= 0 ? innerHeight - barHeight : getValue(d) > 0 ? yScale(getValue(d)) : yScale(0);
      return <Bar key={`bar-${date}`} x={barX} y={barY} width={barWidth} height={barHeight} fill="#3b89bf" />;
    });
  }, [chartData, getValue, innerHeight, innerWidth, xScale, yScale]);

  return <Group>{generatedBars}</Group>;
};

export default React.memo(Bars);
