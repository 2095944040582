const initialState = {
  drawer: {
    drawerOpen: true,
  },
  theme: 'light',
};

export const setDrawerOpen = (open) => ({
  type: 'SET_DRAWER_OPEN',
  payload: open,
});

export const toggleDrawerOpen = () => ({
  type: 'TOGGLE_DRAWER_OPEN',
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_DRAWER_OPEN':
      return {
        ...state,
        drawer: {...state.drawer, drawerOpen: action.payload},
      };
    case 'TOGGLE_DRAWER_OPEN':
      return {
        ...state,
        drawer: {...state.drawer, drawerOpen: !state.drawer.drawerOpen},
      };
    default:
      return state;
  }
}
