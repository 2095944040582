import React from 'react';
import {Route} from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {green, purple} from '@mui/material/colors';
import Container from '@mui/material/Container';
import {adaptV4Theme} from '@mui/material/styles';
import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {SnackbarProvider} from 'notistack';
import PropTypes from 'prop-types';

import Logo from '../../assets/img/logo.png';
// TODO make the constants exported in organization-themes
import Copyright from '../../components/Copyright';

export const NonAuthLayoutRoute = ({component: Component, ...rest}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <NonAuthLayout>
          <Component {...props} />
        </NonAuthLayout>
      )}
    />
  );
};

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: purple[500],
      },
      secondary: {
        main: green[500],
      },
    },
  })
);

export function NonAuthLayout({children}) {
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={5}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          ref={notistackRef}
          action={(key) => <Button onClick={onClickDismiss(key)}>Dismiss</Button>}>
          <div>
            <Container
              maxWidth="sm"
              sx={{
                p: 0,
                // backgroundColor: theme.palette.secondary.main,
                mt: 10, // TODO if mobile no margin
                border: 'solid 1px',
                boxShadow: '5px 10px 20px 15px #888888',
              }}>
              <Box
                sx={{
                  backgroundColor: 'primary.dark',
                  pt: 2,
                  pb: 1,
                  borderBottom: 'solid 3px',
                  textAlign: 'center',
                }}>
                <img width="200px" src={Logo} alt="Logo" />
              </Box>
              <Box
                sx={{
                  minHeight: 750,
                }}>
                {children}
              </Box>
              <Copyright />
            </Container>
          </div>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
NonAuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
