import {getCameras} from 'api/cameras';
import log from 'loglevel';

import {getDeployments} from '../api/deployments';
import {getDevices} from '../api/devices';
import {setCamerasErrorCode, setCamerasList, setIsLoadingCameras} from '../redux/cameras.reducer';
import {setDeploymentsErrorCode, setDeploymentsList, setIsLoadingDeployments} from '../redux/deployments.reducer';
import {setDevicesErrorCode, setDevicesList, setIsLoadingDevices} from '../redux/devices.reducer';

export const getDeviceStatus = (device) => {
  switch (device.deviceEdgeStatus) {
    case 'CONNECTED':
      return 'Online';
    case 'DISCONNECTED':
      return 'Offline';
    default:
      return 'Error';
  }
};

export const refreshDevices = (dispatch, enqueueSnackbar, currentOrganization) => {
  dispatch(setIsLoadingDevices(true));
  dispatch(setDevicesErrorCode(''));

  getDevices()
    .then((response) => {
      dispatch(setIsLoadingDevices(false));
      dispatch(setDevicesErrorCode(''));
      dispatch(setDevicesList(response));
    })
    .catch((error) => {
      dispatch(setIsLoadingDevices(false));
      dispatch(setDevicesErrorCode(error.message));
      log.error('Error getting devices: ', error);
      if (enqueueSnackbar) {
        enqueueSnackbar('Error getting devices: ' + error.message, {variant: 'error'});
      }
    });
};

export const refreshCameras = (dispatch, enqueueSnackbar, currentOrganization) => {
  dispatch(setIsLoadingCameras(true));
  dispatch(setCamerasErrorCode(''));

  getCameras()
    .then((response) => {
      dispatch(setIsLoadingCameras(false));
      dispatch(setCamerasErrorCode(''));
      dispatch(setCamerasList(response));
    })
    .catch((error) => {
      dispatch(setIsLoadingCameras(false));
      dispatch(setCamerasErrorCode(error.message));
      log.error('Error getting cameras: ', error);
      if (enqueueSnackbar) {
        enqueueSnackbar('Error getting cameras: ' + error.message, {variant: 'error'});
      }
    });
};

export const refreshDeployments = (dispatch, enqueueSnackbar) => {
  dispatch(setIsLoadingDeployments(true));
  dispatch(setDeploymentsErrorCode(''));

  getDeployments()
    .then((response) => {
      dispatch(setIsLoadingDeployments(false));
      dispatch(setDeploymentsErrorCode(''));
      dispatch(setDeploymentsList(response));
    })
    .catch((error) => {
      dispatch(setIsLoadingDeployments(false));
      dispatch(setDeploymentsErrorCode(error.message));
      log.error('Error getting deployments', error);
      if (enqueueSnackbar) {
        enqueueSnackbar('Error getting deployments: ' + error.message, {variant: 'error'});
      }
    });
};

export var localDownload = (function () {
  var a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  return function (data, fileName) {
    var blob = new Blob([data], {type: 'octet/stream'}),
      url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();
