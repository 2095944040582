import React from 'react';
import {withRouter} from 'react-router-dom';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';
import log from 'loglevel';
import {compose} from 'recompose';

import SignOutButton from '../../components/SignOut';
import {withAuthHandler} from '../../oauth';
import {withAuthorization} from '../../oauth/Session';
import * as Conditions from '../../routes/Conditions';

import StepOne from './StepOne';
import StepThree from './StepThree';
import StepTwo from './StepTwo';

const steps = [<StepOne />, <StepTwo />, <StepThree />];

export function OnboardingPage(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
      log.debug('Clicked complete');
      completeOnboarding();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  function completeOnboarding() {
    // onboardManager(props.authHandler.firebase)
    //   .then((response) => {
    //     // before pushing routes, the credentials needs to be updated so that onboarded is true,
    //     props.authHandler.credentials.doGetCredentials(props.authHandler.firebase).then((response) => {
    //       log.debug('Could new credentials, should be onboarded now.');
    //       props.history.push(ROUTES.DASHBOARD);
    //     });
    //   })
    //   .catch((error) => {
    //     log.error('Something went wrong: ' + JSON.stringify(error));
    //     props.history.push(ROUTES.SIGN_IN);
    //   });
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Check if user actually needs onboarding, else redirect

  return (
    <>
      <Box
        sx={{
          mt: 1,
          height: 750,
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
        }}>
        {steps[activeStep]}
        <Box
          sx={{
            mt: 'auto',
          }}>
          <SignOutButton />
          <MobileStepper
            steps={maxSteps}
            // position="bottom"
            position="static"
            variant="dots"
            activeStep={activeStep}
            nextButton={
              <Button size="small" onClick={handleNext}>
                {activeStep === maxSteps - 1 ? (
                  <>Complete</>
                ) : (
                  <>
                    Next
                    <KeyboardArrowRight />
                  </>
                )}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
        </Box>
      </Box>
    </>
  );
}

export default compose(withRouter, withAuthHandler, withAuthorization(Conditions.NON_ONBOARDED))(OnboardingPage);
