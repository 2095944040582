import React, {useContext} from 'react';
import {Trans} from '@lingui/macro';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import log from 'loglevel';

import {UserContext} from '../../../oauth/Session';

function Appearance(props) {
  const {themeMode, setThemeMode, locale, setLocale} = useContext(UserContext);

  const handleThemeModeChange = (event) => {
    log.debug('Setting theme mode to ' + event.target.value);
    setThemeMode(event.target.value);
  };

  const handleLocaleChange = (event) => {
    log.debug('Setting locale to ' + event.target.value);
    setLocale(event.target.value);
  };

  return (
    <Grid item container xs={12}>
      <Grid item container xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <Trans>Theme mode</Trans>
          </FormLabel>
          <RadioGroup row aria-label="theme-mode" name="thememode" value={themeMode} onChange={handleThemeModeChange}>
            <FormControlLabel value="light" control={<Radio />} label={<Trans>Light</Trans>} />
            <FormControlLabel value="dark" control={<Radio />} label={<Trans>Dark</Trans>} />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item container xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{<Trans>Language</Trans>}</FormLabel>
          <RadioGroup row aria-label="locale" name="locale" value={locale} onChange={handleLocaleChange}>
            <FormControlLabel value="enUS" control={<Radio />} label={<Trans>English</Trans>} />
            <FormControlLabel value="svSE" control={<Radio />} label={<Trans>Swedish</Trans>} />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default Appearance;
