import React from 'react';
import Paper from '@mui/material/Paper';

function FlexiblePaper(props) {
  return (
    <>
      <Paper
        sx={{
          padding: 2,
          display: 'flex',
          overflow: 'auto',
          flexDirection: 'column',
        }}
        {...props}>
        {props.children}
      </Paper>
    </>
  );
}

export default FlexiblePaper;
