import {API} from './api';

export async function getSensorGroups() {
  return API.get('/sensor_groups')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function createSensorGroup(payload) {
  return API.post('/sensor_groups/group', payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function deleteSensorGroup(groupId) {
  return API.delete('/sensor_groups/' + groupId)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getSensorGroup(groupId) {
  return API.get('/sensor_groups/' + groupId)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function updateSensorGroup(payload) {
  return API.patch('/sensor_groups/' + payload.id, payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getSensors() {
  return API.get('/sensor_groups/sensorsByOrg')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function updateSensors(payload) {
  return API.patch('/sensors', payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function deleteSensor(sensorId) {
  return API.delete('/sensors/' + sensorId)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
