import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Checkbox, FormControl, FormControlLabel, MenuItem, Select, Typography} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import {
  setDashboardCalculateCapacity,
  setDashboardCombineGraphs,
  setDashboardCounters,
  setDashboardHighlights,
  setDashboardShowDays,
} from 'redux/dashboard.reducer';

import {COUNTERS, WEEK} from '../helpers';

const useStyles = makeStyles((theme) => ({
  accordion: {
    width: '100%',
    '&.MuiAccordion-root:before': {
      height: 0,
    },
    '&.Mui-expanded': {
      marginTop: '0px',
    },
  },
  root: {
    minHeight: '30px',
    '&.Mui-expanded': {
      minHeight: '30px',
      margin: '0px',
    },
  },
  content: {
    flexGrow: 0,
    margin: '0px',
    '&.Mui-expanded': {
      margin: '0px',
    },
  },
  expandIcon: {
    padding: '6px',
  },
}));

const AdvancedSettings = ({updateChart, isDisabled, sources}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dashboardSettings = useSelector((state) => state.dashboard.settings);

  const handleCounterChange = useCallback(
    (e) => {
      const dashboardSettingsClone = {...dashboardSettings};
      dashboardSettingsClone.counters = [e.target.value];
      dispatch(setDashboardCounters([e.target.value]));
      updateChart(dashboardSettingsClone);
    },
    [dashboardSettings, dispatch, updateChart]
  );
  const countersDropdown = useMemo(
    () =>
      COUNTERS.map((counter) => (
        <MenuItem key={counter.id} value={counter.id}>
          {counter.name}
        </MenuItem>
      )),
    []
  );

  const handleCombineGraphsChange = useCallback(
    (e) => {
      dispatch(setDashboardCombineGraphs(e.target.checked));
    },
    [dispatch]
  );

  const handleCalculateCapacityChange = useCallback(
    (e) => {
      const dashboardSettingsClone = {...dashboardSettings};
      dashboardSettingsClone.calculateCapacity = e.target.checked;
      dispatch(setDashboardCalculateCapacity(e.target.checked));
      updateChart(dashboardSettingsClone);
    },
    [dashboardSettings, dispatch, updateChart]
  );

  const handleHighlightChange = useCallback(
    (e) => {
      const dashboardSettingsClone = {...dashboardSettings};
      dashboardSettingsClone.highlights = e.target.value;
      dispatch(setDashboardHighlights(e.target.value));
    },
    [dashboardSettings, dispatch]
  );
  const highlightsDropdown = useMemo(
    () =>
      WEEK.map((day) => (
        <MenuItem key={day.id + day.name} value={day.id}>
          <Checkbox checked={dashboardSettings.highlights.includes(day.id)} />
          {day.name}
        </MenuItem>
      )),
    [dashboardSettings.highlights]
  );

  const handleShowDaysChange = useCallback(
    (e) => {
      const dashboardSettingsClone = {...dashboardSettings};
      dashboardSettingsClone.showDays = e.target.value;
      dispatch(setDashboardShowDays(e.target.value));
    },
    [dashboardSettings, dispatch]
  );
  const showDaysDropdown = useMemo(
    () =>
      WEEK.map((day) => (
        <MenuItem key={day.id + day.name} value={day.id}>
          <Checkbox checked={dashboardSettings.showDays.includes(day.id)} />
          {day.name}
        </MenuItem>
      )),
    [dashboardSettings.showDays]
  );

  const capacitySetting = useMemo(() => {
    if (
      dashboardSettings.dashboardType !== 'occupancy' ||
      !sources.find((source) => source.id === dashboardSettings.sourceId)?.capacity
    ) {
      return null;
    }
    return (
      <FormControlLabel
        sx={{
          ml: 0,
          mt: 1,
          mr: 1,
        }}
        onChange={handleCalculateCapacityChange}
        checked={!!dashboardSettings.calculateCapacity}
        control={<Checkbox />}
        label="Display count as % of capacity"
        labelPlacement="start"
      />
    );
  }, [
    dashboardSettings.calculateCapacity,
    dashboardSettings.dashboardType,
    dashboardSettings.sourceId,
    handleCalculateCapacityChange,
    sources,
  ]);

  return (
    <Accordion elevation={0} className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="small" />}
        classes={{
          root: classes.root,
          content: classes.content,
          expandIcon: classes.expandIcon,
        }}
        aria-controls="settings-content"
        id="settings">
        <Typography variant="body2">Settings</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{paddingRight: 0, paddingLeft: 0, paddingBottom: 3}}>
        <Grid container direction="row">
          {(dashboardSettings.sourceType === 'sensor' || dashboardSettings.sourceType === 'group') && (
            <Grid item sm={4}>
              <FormControl fullWidth size="small" variant="standard">
                <InputLabel id="counter-label">Counters</InputLabel>
                <Select
                  labelId="counter-label"
                  aria-describedby="helper-counter"
                  id="counter-select"
                  style={{width: '170px'}}
                  disabled={isDisabled}
                  value={dashboardSettings.counters[0]}
                  label="Counters"
                  onChange={handleCounterChange}>
                  {countersDropdown}
                </Select>
              </FormControl>
              {dashboardSettings.view !== 'year' && (
                <FormControlLabel
                  sx={{
                    ml: 0,
                    mt: 1,
                  }}
                  onChange={handleCombineGraphsChange}
                  checked={!!dashboardSettings.combineGraphs}
                  control={<Checkbox />}
                  label="Combine graphs"
                  labelPlacement="start"
                />
              )}
              {capacitySetting}
            </Grid>
          )}
          {dashboardSettings.view !== 'day' && (
            <>
              <Grid item sm={4}>
                <FormControl fullWidth size="small" variant="standard">
                  <InputLabel id="highlight-label">Highlight days</InputLabel>
                  <Select
                    labelId="highlight-label"
                    aria-describedby="helper-highlight"
                    id="highlight-select"
                    style={{width: '170px'}}
                    multiple
                    disabled={isDisabled}
                    value={dashboardSettings.highlights}
                    renderValue={(selected) => selected.map((dayNum) => moment().day(dayNum).format('dddd')).join(', ')}
                    label="highlight day"
                    onChange={handleHighlightChange}>
                    {highlightsDropdown}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={4}>
                <FormControl fullWidth size="small" variant="standard">
                  <InputLabel id="include-days-label">Show days</InputLabel>
                  <Select
                    labelId="include-days-label"
                    aria-describedby="helper-include-days"
                    id="include-days-select"
                    style={{width: '170px'}}
                    multiple
                    disabled={isDisabled}
                    value={dashboardSettings.showDays}
                    renderValue={(selected) =>
                      selected.length === 7
                        ? 'All days'
                        : selected.map((dayNum) => moment().day(dayNum).format('dddd')).join(', ')
                    }
                    label="include days"
                    onChange={handleShowDaysChange}>
                    {showDaysDropdown}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(AdvancedSettings);
