import React, {useEffect, useState} from 'react';
import {useParams, withRouter} from 'react-router-dom';
import {Trans} from '@lingui/macro';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import {useTheme} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import log from 'loglevel';
import {useSnackbar} from 'notistack';
import {compose} from 'recompose';

import {getUser, getUserRoles, patchUser} from '../../api/users';
import NavigationToolbar from '../../components/NavigationToolbar';
import Timestamp from '../../components/Timestamp';
import Title from '../../components/Typography/Title';
import {withAuthorization} from '../../oauth/Session';
import * as Conditions from '../../routes/Conditions';
import * as ROUTES from '../../routes/Routes';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, roles, theme) {
  let x;
  for (x in roles) {
    if (roles[x].role_name === name) {
      return {fontWeight: theme.typography.fontWeightMedium};
    }
  }
  return {fontWeight: theme.typography.fontWeightRegular};
  // return {
  //   fontWeight: roles.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  // };
}

function User(props) {
  const theme = useTheme();
  const {enqueueSnackbar} = useSnackbar();
  let {uid} = useParams();
  const [user, setUser] = useState({roles: []});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [availableRoles, setAvailableRoles] = useState([]);

  useEffect(() => {
    setLoading(true);
    getUser(uid)
      .then((response) => {
        log.debug('Got data: ' + JSON.stringify(response));
        setUser(response);
        setLoading(false);
      })
      .catch((error) => {
        log.error('Error getting table data');
        setError(error);
        setLoading(false);
      });

    getUserRoles().then((response) => {
      log.debug('Got manager roles: ' + JSON.stringify(response.data));
      setAvailableRoles(response.data);
    });
  }, [uid]);

  function setUserRoles(roles) {
    log.debug('Setting user roles to: ' + JSON.stringify(roles));
    setUser({...user, roles: roles});
  }

  function updateUser() {
    // TODO get the patch, i.e. only the changes
    const user_patch = user;
    patchUser(uid, user_patch)
      .then((response) => {
        log.debug('Response: ' + JSON.stringify(response));
        enqueueSnackbar('Successfully updated user', {variant: 'success'});
        props.history.push(ROUTES.USERS);
      })
      .catch((error) => {
        enqueueSnackbar('Unable to update', {variant: 'error'});
        log.error('Error when updating user: ' + error);
      });
  }

  return (
    <>
      <NavigationToolbar routes={[<Trans>Users</Trans>, <Trans>User</Trans>]} />
      <Grid container spacing={3}>
        {!loading && error && (
          <Typography align="center" variant="h3">
            {error.message}
          </Typography>
        )}
        {!loading && !error && (
          <>
            <Grid item xs={12}>
              <Paper
                sx={{
                  p: 2,
                  overflow: 'auto',
                  flexDirection: 'column',
                }}>
                <Grid container justifyContent="space-evenly" alignItems="flex-end">
                  <Grid item xs>
                    <Container>
                      <Title>
                        <Trans>Email verified</Trans>
                      </Title>
                      <Typography component="p" variant="h4">
                        {user.email_verified ? 'Yes' : 'No'}
                      </Typography>
                    </Container>
                  </Grid>
                  <Grid item xs>
                    <Container>
                      <Title>
                        <Trans>Last sign in</Trans>
                      </Title>
                      <Typography component="p" variant="h4">
                        <Timestamp time={user.last_sign_in_time_stamp / 1000} />
                      </Typography>
                    </Container>
                  </Grid>
                  <Grid item xs>
                    <Container>
                      <Title>
                        <Trans>Last refresh</Trans>
                      </Title>
                      <Typography component="p" variant="h4">
                        <Timestamp time={user.last_refresh_timestamp / 1000} />
                      </Typography>
                    </Container>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Paper
                sx={{
                  p: 2,
                  overflow: 'auto',
                  flexDirection: 'column',
                }}>
                <>
                  <Box
                    sx={{
                      '& .MuiTextField-root': {
                        m: 1,
                        width: '25ch',
                      },
                    }}
                    noValidate
                    autoComplete="off">
                    <div>
                      <TextField
                        required
                        id="user-first-name"
                        label={<Trans>First Name</Trans>}
                        variant="outlined"
                        value={user.first_name}
                        onChange={(e) => setUser({...user, first_name: e.target.value})}
                        helperText="The first name of the user"
                      />
                      <TextField
                        required
                        id="user-last-name"
                        label={<Trans>Last Name</Trans>}
                        variant="outlined"
                        value={user.last_name}
                        onChange={(e) => setUser({...user, last_name: e.target.value})}
                        helperText="The last name of the user"
                      />
                    </div>
                    <div>
                      <TextField
                        required
                        id="user-email"
                        label={<Trans>Email</Trans>}
                        variant="outlined"
                        value={user.email}
                        onChange={(e) => setUser({...user, email: e.target.value})}
                        helperText="User email"
                      />
                    </div>
                  </Box>
                  <div>
                    <FormControl
                      sx={{
                        m: 1,
                        width: 400,
                      }}>
                      <InputLabel id="roles-mutiple-chip-label">Roles</InputLabel>
                      <Select
                        labelId="role-mutiple-chip-label"
                        id="role-mutiple-chip"
                        multiple
                        value={user.roles}
                        onChange={(e) => setUserRoles(e.target.value)}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                            }}>
                            {selected.map((role) => (
                              <Chip key={role.rid} label={role.role_name} sx={{m: 2}} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}>
                        {availableRoles.map((role) => (
                          <MenuItem
                            key={role.rid}
                            value={{rid: role.rid, role_name: role.role_name}}
                            style={getStyles(role.role_name, user.roles, theme)}>
                            {role.role_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Typography variant="body1">Onboarded at: {user.onboarded_at}</Typography>
                    <Typography variant="body1">Phone: {user.phone_number}</Typography>
                  </div>

                  <Button
                    color="primary"
                    size="medium"
                    variant="outlined"
                    aria-label="update user"
                    sx={{ml: 1}}
                    onClick={updateUser}>
                    <Trans>Save</Trans>
                  </Button>
                </>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Toolbar>
                <Typography variant="h3">
                  <Trans>Raw data</Trans>
                </Typography>
              </Toolbar>
              <Paper
                sx={{
                  p: 2,
                  overflow: 'auto',
                  flexDirection: 'column',
                }}>
                <pre>{JSON.stringify(user, null, 2)}</pre>
              </Paper>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default compose(withRouter, withAuthorization(Conditions.ANY))(User);
