import React, {useState} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import log from 'loglevel';

import {UserContext} from './context';

// TODO too many variables here, forces rerendering of everything since theme is at the top,
//  performance is bad, look into other options such as https://github.com/dai-shi/react-tracked

const withUser = (Component) => {
  function WithUser(props) {
    const [currentOrganization, setCurrentOrganization] = useState(() => {
      const value = localStorage.getItem('currentOrganization');
      // TODO set organization to null and force the organization selector to open
      return value !== null ? JSON.parse(value) : 'flowity';
    });

    React.useEffect(() => {
      localStorage.setItem('currentOrganization', JSON.stringify(currentOrganization));
    }, [currentOrganization]);

    const prefersDarkTheme = useMediaQuery('(prefers-color-scheme: dark)');
    const [themeMode, setThemeMode] = useState(() => {
      const value = localStorage.getItem('themeMode');
      return value !== null ? JSON.parse(value) : prefersDarkTheme === true ? 'dark' : 'light';
    });

    React.useEffect(() => {
      log.debug('Storing theme mode: ' + themeMode);
      localStorage.setItem('themeMode', JSON.stringify(themeMode));
    }, [themeMode]);

    const defaultLocale = 'enUS';
    const [locale, setLocale] = useState(() => {
      const value = localStorage.getItem('locale');
      return value !== null ? JSON.parse(value) : defaultLocale;
    });

    React.useEffect(() => {
      log.debug('Storing locale: ' + locale);
      localStorage.setItem('locale', JSON.stringify(locale));
    }, [locale]);

    return (
      <UserContext.Provider
        value={{
          currentOrganization,
          setCurrentOrganization,
          themeMode,
          setThemeMode,
          locale,
          setLocale,
        }}>
        <Component {...props} />
      </UserContext.Provider>
    );
  }

  return WithUser;
};

export default withUser;
