import React, {useCallback} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Trans} from '@lingui/macro';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import {red} from '@mui/material/colors';
import {styled} from '@mui/material/styles';
import {spacing} from '@mui/system';
/**
 * Applies the spacing system to the material UI Button
 */
const Button = styled(MuiButton)(spacing);

export const HeaderButton = (props) => {
  return <Button size="small" variant="contained" mr={2} {...props}></Button>;
};

export const HeaderButtonDelete = (props) => {
  return (
    <Button
      size="small"
      variant="contained"
      mr={2}
      style={{backgroundColor: red[400]}}
      startIcon={<DeleteIcon />}
      {...props}></Button>
  );
};

export const HeaderButtonRefresh = (props) => {
  const isLoading = !!props.isLoading;
  const useButton = useCallback(() => {
    if (isLoading) {
      return <CircularProgress size={18} />;
    }
    return <RefreshIcon />;
  }, [isLoading]);

  const newProps = {...props};
  delete newProps.isLoading;
  return (
    <HeaderButton
      sx={{
        height: 30,
      }}
      startIcon={useButton()}
      {...newProps}
      disabled={isLoading}
      variant="outlined">
      <Trans>Refresh</Trans>
    </HeaderButton>
  );
};
export const HeaderButtonBack = (props) => {
  const to = props.to;

  return (
    <RouterLink to={to} style={{textDecoration: 'none'}}>
      <HeaderButton startIcon={<ArrowBackIosIcon />}>
        <Trans>Back</Trans>
      </HeaderButton>
    </RouterLink>
  );
};
