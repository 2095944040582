import axios from 'axios';
import _ from 'lodash';
import log from 'loglevel';
import moment from 'moment';

import {msalInstance} from '../oauth/auth';
import {loginRequest} from '../oauth/authConfig';
import {setCredentials} from '../redux/credentials';
import {setCurrentOrganization, setLoggedIn} from '../redux/session';
import {setAccessToken, setAccessTokenExpiration} from '../redux/session';

import {getCredentials} from './credentials';

let store = null;

export const setStore = (newStore) => {
  log.info('Setting store in api');
  store = newStore;
};

export const refreshCredentials = () => {
  log.debug('Refreshing credentials');
  // TODO check that the accessToken is still valid
  //check local storage
  // store.dispatch(setToken(adasd));

  return getCredentials().then((r) => {
    log.debug('Got credentials: ' + JSON.stringify(r));
    if (!_.isEqual(store.getState().credentials, r)) {
      log.debug('Credentials have changed, updating state');
      store.dispatch(setCredentials(r));
    } else {
      log.debug('No change in credentials.');
    }

    const currentOrganization = store.getState().session.currentOrganization;

    if (!currentOrganization || !currentOrganization.id || currentOrganization.id.length < 10) {
      log.info('Current session does not have a selected organization');
      log.debug(r.organizations);
      if (r.organizations && r.organizations.length > 0) {
        const organization = r.organizations[0];
        log.info('Set default currentORganization', organization.id);
        store.dispatch(setCurrentOrganization(organization.id, organization.name));
      }
    }
  });
};

const BASE_URL = process.env.REACT_APP_API_URL + '/v1';

const BASE_HEADERS = {
  mode: 'cors', // no-cors, cors, *same-origin
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  // credentials: 'same-origin', // include, *same-origin, omit
  headers: {
    // 'Access-Control-Allow-Origin': '*',
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  redirect: 'follow', // manual, *follow, error
  referrer: 'no-referrer', // no-referrer, *client
};

export const API = axios.create({
  ...BASE_HEADERS,
  baseURL: BASE_URL,
  // withCredentials: true, //Allows the cookie to be included, todo, perhaps not always
});

// Add a request interceptor
API.interceptors.request.use(async function (config) {
  //overrides the default Accept header when requesting a csv report
  if (
    (config.url === '/measurements' || config.url === '/groupmeasurements/presencedata_csv') &&
    config.method === 'post'
  ) {
    config.headers.Accept = 'application/csv';
  }

  const currentOrganization = store.getState().session.currentOrganization;

  if (currentOrganization && currentOrganization.id && currentOrganization.id.length > 10) {
    // organization id needs to be present in headers
    config.headers.organization = currentOrganization.id;
  } else {
    log.warn('No organization id found for API request');
  }

  const accessTokenExpirationTime = store.getState().session.tokenExpirationTime;
  const storedAccessToken = store.getState().session.accessToken;

  if (moment(accessTokenExpirationTime).isBefore() || !storedAccessToken) {
    // if access token expired, acquire a new one before making a request
    const account = store.getState().session.account;
    return msalInstance
      .acquireTokenSilent({
        ...loginRequest,
        account: account,
      })
      .then((response) => {
        log.info('NEW Got response from acquireTokenSilent: ' + JSON.stringify(response));
        log.info('NEW Returning accesstoken: ' + JSON.stringify(response.accessToken));
        const token = `Bearer ${response.accessToken}`;
        store.dispatch(setAccessTokenExpiration(response.expiresOn));
        store.dispatch(setAccessToken(token));
        config.headers.Authorization = token;
        return config;
      })
      .catch((error) => {
        return msalInstance.acquireTokenRedirect(loginRequest);
      });
  } else {
    const accessToken = store.getState().session.accessToken;
    config.headers.Authorization = accessToken;
    return config;
  }
});

// Add a response interceptor for redirecting if user receives a unauthorized
API.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log('Response interceptor.');
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      log.error('Error is: ' + JSON.stringify(error, null, 2));
      const {status, data} = error.response;
      if (status === 401) {
        log.error('Interceptor Received 401!! ' + JSON.stringify(data));
        store.dispatch(setAccessToken(null));
        store.dispatch(setCredentials(null));
        store.dispatch(setLoggedIn(false));
        // TODO history push
        // use https://sweetalert2.github.io/ to inform the user first?
        // swal({
        //     title: "Session Expired",
        //     text: "Your session has expired. Would you like to be redirected to the login page?",
        //     type: "warning",
        //     showCancelButton: true,
        //     confirmButtonColor: "#DD6B55",
        //     confirmButtonText: "Yes",
        //     closeOnConfirm: false
        // }, function(){
        //     window.location = '/login';
        // });

        //window.location = '/login';
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      log.error('Error', error.message);
    } else {
      // Something happened in setting up the request that triggered an Error
      log.error('Error', error.message);
    }

    return Promise.reject(error);
  }
);
