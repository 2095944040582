import {API} from './api';
import {mockedResponse} from './mock';

const useMock = false;

const mockDetectionClasses = [
  {
    id: 'e7e42c44-7770-479a-bafd-0f4496141154',
    name: 'Detection Model 1',
    description: 'This is description for Detection Model 1',
    version: '1.0',
    classes: ['person', 'car'],
  },
  {
    id: 'e7e42c44-7770-479a-bafd-0f4496145555',
    name: 'Detection Model 2',
    description: 'This is description for Detection Model 2',
    version: '1.2',
    classes: ['cat', 'dog'],
  },
];

export async function getDetectionModels() {
  if (useMock) {
    return mockedResponse(mockDetectionClasses);
  }
  return API.get('/detectionmodels')
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
}
