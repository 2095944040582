export const colorList = [
  '#00FF00',
  '#FF0000',
  '#00FFFF',
  '#FFCC00',
  '#FF00FF',
  '#99FF99',
  '#FF9999',
  '#FFFF99',
  '#FF99FF',
  '#008800',
  '#880000',
  '#008888',
  '#888800',
  '#880088',
];

export const getColor = (index) => {
  return colorList[index % colorList.length] ? colorList[index % colorList.length] : colorList[0];
};
