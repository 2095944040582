import {cloneDeep} from 'lodash';

const initialState = {
  isLoadingDevices: true,
  devicesErrorCode: '',
  devicesList: [],
  newDevices: [],
};

export const setDevicesList = (list) => ({
  type: 'DEVICES_SET_LIST',
  payload: list,
});

export const setNewDevices = (list) => ({
  type: 'DEVICES_SET_NEW',
  payload: list,
});

export const setIsLoadingDevices = (isLoading) => ({
  type: 'DEVICES_SET_IS_LOADING',
  payload: !!isLoading,
});

export const setDevicesErrorCode = (errorCode) => ({
  type: 'DEVICES_SET_ERROR_CODE',
  payload: errorCode,
});
export const putDevice = (device) => ({
  type: 'DEVICES_PUT_DEVICE',
  payload: device,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'DEVICES_SET_LIST':
      return Object.assign({...state}, {devicesList: action.payload});

    case 'DEVICES_SET_NEW':
      return Object.assign({...state}, {newDevices: cloneDeep(action.payload)});

    case 'DEVICES_SET_IS_LOADING':
      return Object.assign({...state}, {isLoadingDevices: action.payload});

    case 'DEVICES_SET_ERROR_CODE':
      return Object.assign({...state}, {devicesErrorCode: action.payload});

    case 'DEVICES_PUT_DEVICE':
      return Object.assign(
        {...state},
        {devicesList: state.devicesList.map((d) => (d.id === action.payload.id ? action.payload : d))}
      );

    default:
      return state;
  }
}
