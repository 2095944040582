import React, {useCallback, useEffect, useMemo, useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Alert, IconButton} from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import {useSnackbar} from 'notistack';

import {deleteSensorGroup, getSensorGroups} from '../../../api/sensors';
import Modal from '../../../components/Modal';

function SensorGroupList({setIsEditingGroup, setSelectedGroup}) {
  const {enqueueSnackbar} = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingGroup, setDeletingGroup] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [sensorGroups, setSensorGroups] = useState([]);
  const [sensorsToShow, setSensorsToShow] = useState();

  const groupListContent = useMemo(
    () =>
      sensorGroups.length > 0 ? (
        sensorGroups.map((g) => (
          <ListItem
            key={g.id}
            button
            sx={
              sensorsToShow && sensorsToShow.id === g.id
                ? {
                    backgroundColor: 'background.default',
                    '&:hover button': {
                      display: 'flex',
                    },
                  }
                : {
                    '&:hover button': {
                      display: 'flex',
                    },
                  }
            }
            onClick={() => setSensorsToShow(g)}>
            <ListItemText
              sx={{
                '& > *': {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                },
              }}
              primary={g.name}
              secondaryTypographyProps={{component: 'div'}}
              secondary={
                <>
                  <p>{g.description}</p>
                  <p>{g.capacity > 0 && 'Capacity: ' + g.capacity}</p>
                </>
              }
            />
            <IconButton
              aria-label="delete sensor group"
              sx={{display: 'none'}}
              onClick={() => {
                setDeletingGroup({id: g.id, name: g.name});
                setShowModal(true);
              }}
              size="small">
              <DeleteIcon fontSize="medium" />
            </IconButton>
            <IconButton
              aria-label="edit sensor group"
              sx={{display: 'none'}}
              onClick={() => {
                setSelectedGroup(g);
                setIsEditingGroup(true);
              }}
              size="small">
              <EditIcon fontSize="medium" />
            </IconButton>
          </ListItem>
        ))
      ) : (
        <ListItem>
          <ListItemText primary="There are no groups yet." />
        </ListItem>
      ),
    [sensorGroups, sensorsToShow, setIsEditingGroup, setSelectedGroup]
  );

  const sensorsListContent = useMemo(
    () =>
      sensorsToShow && sensorsToShow.sensorReferences.length > 0 ? (
        sensorsToShow.sensorReferences.map((s) => (
          <ListItem key={s.sensorId}>
            <ListItemText
              sx={{
                '& > *': {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                },
              }}
              primary={s.description || s.sensorId}
              secondary={s.type}
            />
          </ListItem>
        ))
      ) : (
        <ListItem>
          <ListItemText primary="There are no sensors yet." />
        </ListItem>
      ),
    [sensorsToShow]
  );

  const getData = useCallback(() => {
    setIsLoading(true);
    getSensorGroups()
      .then((response) => {
        setSensorGroups(response.data);
      })
      .catch((error) => {
        enqueueSnackbar(`Error getting sensor groups: ${error.message}`, {variant: 'error'});
      })
      .finally(() => setIsLoading(false));
  }, [enqueueSnackbar]);

  const handleDelete = useCallback(
    (group) => {
      setIsDeleting(true);
      deleteSensorGroup(group.id)
        .then(() => {
          enqueueSnackbar(`Sensor group ${group.name} deleted successfully`, {
            variant: 'success',
          });
          setDeletingGroup(null);
          setShowModal(false);
          getData();
        })
        .catch((error) => {
          enqueueSnackbar('Error deleting sensor group: ' + error.message, {variant: 'error'});
        })
        .finally(() => {
          setIsDeleting(false);
        });
    },
    [enqueueSnackbar, getData]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Grid item container xs={12} md={6}>
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            border: '1px solid #0000001f',
            borderRadius: 1,
            maxHeight: 300,
            '& ul': {padding: 0},
          }}
          subheader={
            <ListSubheader
              sx={{
                borderBottom: '1px solid #0000001f',
              }}>
              <Grid item container xs={12} alignItems="center" justifyContent="space-between">
                Groups
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  sx={{
                    height: 30,
                    ml: 2,
                    right: 0,
                  }}
                  onClick={() => setIsEditingGroup(true)}>
                  Add new group
                </Button>
              </Grid>
            </ListSubheader>
          }>
          {isLoading ? <LinearProgress /> : groupListContent}
        </List>
      </Grid>
      {sensorsToShow && (
        <Grid item container xs={12} md={6}>
          <List
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              position: 'relative',
              overflow: 'auto',
              border: '1px solid #0000001f',
              borderRadius: 1,
              maxHeight: 300,
              '& ul': {padding: 0},
            }}
            subheader={
              <ListSubheader
                sx={{
                  borderBottom: '1px solid #0000001f',
                }}>
                <Grid item container xs={12} alignItems="center" justifyContent="space-between">
                  Associated sensors
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    sx={{
                      height: 30,
                      ml: 2,
                      right: 0,
                    }}
                    onClick={() => {
                      setSelectedGroup(sensorsToShow);
                      setIsEditingGroup(true);
                    }}>
                    Edit sensors
                  </Button>
                </Grid>
              </ListSubheader>
            }>
            {sensorsListContent}
          </List>
        </Grid>
      )}
      <Modal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
        }}>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Alert severity="warning">Warning: Potential destructive action!</Alert>
            <p>
              You are about to delete sensor group {deletingGroup && deletingGroup.name}. Are you sure you want to do
              it?
            </p>
          </Grid>
          <Grid item xs={12} align="right">
            <Button
              startIcon={isDeleting && <CircularProgress size={18} />}
              disabled={isDeleting}
              color="error"
              variant="contained"
              onClick={() => handleDelete(deletingGroup)}>
              Delete
            </Button>
            <Button
              variant="contained"
              startIcon={<CloseIcon />}
              sx={{ml: 3}}
              onClick={() => {
                setShowModal(false);
                setDeletingGroup(null);
              }}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}

export default SensorGroupList;
