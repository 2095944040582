import React from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

import Chart from './Chart';

const ChartWrapper = ({chartData, chartPeriod, view, colorRange, keys, dashboardType}) => {
  return (
    <div style={{position: 'relative', width: '100%', height: '350px'}}>
      <ParentSize className="graph-container" debounceTime={10}>
        {({width, height}) => (
          <Chart
            width={width}
            height={height}
            chartData={chartData}
            colorRange={colorRange}
            chartPeriod={chartPeriod}
            keys={keys}
            view={view}
            dashboardType={dashboardType}
          />
        )}
      </ParentSize>
    </div>
  );
};

export default React.memo(ChartWrapper);
