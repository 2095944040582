import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Grid} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {PickersDay} from '@mui/x-date-pickers/PickersDay';
import moment from 'moment';
import {setDashboardPeriod} from 'redux/dashboard.reducer';

// styles for highlighting all days in a selected week
const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'notSelected' && prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({theme, notSelected, dayIsBetween, isFirstDay, isLastDay}) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&.Mui-disabled': {
      color: theme.palette.common.white,
    },
    width: '40px',
  }),
  ...(isFirstDay && {
    borderRadius: 0,
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&.Mui-disabled': {
      color: theme.palette.common.white,
    },
    width: '40px',
  }),
  ...(isLastDay && {
    borderRadius: 0,
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&.Mui-disabled': {
      color: theme.palette.common.white,
    },
    width: '40px',
  }),
  ...(notSelected && {
    width: '40px',
  }),
}));

const DateSelector = ({updateChart, isDisabled}) => {
  const dispatch = useDispatch();
  const dashboardSettings = useSelector((state) => state.dashboard.settings);
  // The time period used for the main graph. Time periods of comparison graphs will depend on it.
  // null value is considered Last 24h/Last 7 days/Last 4 weeks/Last 365 days
  const [mainPeriod, setMainPeriod] = useState();
  const [pickerOpen, setPickerOpen] = useState(false);

  // displayed in the toolbar of the datepicker
  const lastLabel = useMemo(() => {
    switch (dashboardSettings.view) {
      case 'day':
        return 'Last 24h';
      case 'week':
        return 'Last 7 days';
      case 'month':
        return 'Last 4 weeks';
      default:
        return 'Last 365 days';
    }
  }, [dashboardSettings.view]);

  // render a week picker that calculates which days are selected within a week and highlights them
  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    let start;
    let end;
    if (!dashboardSettings.period) {
      // Last 7 days
      end = moment();
      start = moment(end).subtract(7, 'day');
    } else {
      start = moment(dashboardSettings.period).startOf('week');
      end = moment(dashboardSettings.period).endOf('week');
    }
    const dayIsBetween = date.isBetween(start, end);
    const isFirstDay = date.isSame(start, 'day');
    const isLastDay = date.isSame(end, 'day');
    return (
      <Fragment key={pickersDayProps.key + 'group'}>
        {/* render selectable week number in front of the first day of the week */}
        {date.isoWeekday() === 1 && (
          <Grid
            container
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              width: '32px',
              fontSize: '0.75rem',
              fontWeight: 500,
              color: pickersDayProps.disabled && '#00000061',
              cursor: 'pointer',
              pointerEvents: pickersDayProps.disabled && 'none',
              borderRadius: '50%',
              ':hover': {backgroundColor: '#14316c0a'},
            }}
            onClick={(event) => pickersDayProps.onDaySelect(pickersDayProps.day)}>
            {date.week()}
          </Grid>
        )}
        {/* render all other days */}
        <CustomPickersDay
          {...pickersDayProps}
          disableMargin
          dayIsBetween={dayIsBetween}
          isFirstDay={isFirstDay}
          isLastDay={isLastDay}
          notSelected={!dayIsBetween && !isFirstDay && !isLastDay}
        />
      </Fragment>
    );
  };

  const renderWeekPickerHeader = (day) => (
    <>
      {day === 'Mon' && <span key="WN">WN</span>}
      <span key={day} style={{marginLeft: day === 'Mon' ? '13px' : '30px'}}>
        {day}
      </span>
    </>
  );

  // fromat displayed in the input field
  const handleInputFormat = useCallback(
    (date) => {
      let dateClone = moment(date, 'DD/MM/YYYY');
      switch (dashboardSettings.view) {
        case 'day':
          return dateClone && dateClone.isValid() ? dateClone.format('MMM D') : date;
        default:
          //week
          return dateClone && dateClone.isValid() ? dateClone.format('[Week] w') : date;
      }
    },
    [dashboardSettings.view]
  );

  const handleLastLabelClick = useCallback(() => {
    if (dashboardSettings.period) {
      const dashboardSettingsClone = {...dashboardSettings};
      dashboardSettingsClone.period = null;
      dispatch(setDashboardPeriod(null));
      updateChart(dashboardSettingsClone);
    }
  }, [dashboardSettings, dispatch, updateChart]);

  const handleDateChange = useCallback(
    (date) => {
      const dashboardSettingsClone = {...dashboardSettings};
      dashboardSettingsClone.period = date;
      dispatch(setDashboardPeriod(date));
      updateChart(dashboardSettingsClone);
    },
    [dashboardSettings, dispatch, updateChart]
  );

  useEffect(() => {
    setMainPeriod(dashboardSettings.period);
  }, [dashboardSettings.period]);

  return (
    <>
      {/* datepicker in month and year view */}
      {(dashboardSettings.view === 'month' || dashboardSettings.view === 'year') && (
        <DatePicker
          open={pickerOpen}
          onClose={() => setPickerOpen(false)}
          value={mainPeriod}
          renderInput={(props) => (
            <TextField
              {...props}
              variant="standard"
              sx={{width: 130}}
              onClick={() => setPickerOpen(true)}
              inputProps={{
                ...props.inputProps,
                value: props.inputProps.value === '' ? lastLabel : props.inputProps.value,
                readOnly: true,
              }}
            />
          )}
          inputFormat={dashboardSettings.view === 'month' ? 'MMM yyyy' : 'yyyy'}
          disabled={isDisabled}
          maxDate={moment()}
          minDate={moment('01-01-2022', 'MM-DD-YYYY')}
          showToolbar
          ToolbarComponent={(props) => (
            <Box sx={{pr: 3, pt: 3}} display="flex" justifyContent="flex-end">
              <Button
                variant="text"
                sx={{p: 0}}
                onClick={() => {
                  handleLastLabelClick();
                  setPickerOpen(false);
                }}>
                {lastLabel}
              </Button>
            </Box>
          )}
          clearable
          openTo={dashboardSettings.view}
          views={dashboardSettings.view === 'month' ? ['year', 'month'] : ['year']}
          onAccept={(date) => handleDateChange(date)}
          onChange={(date) => setMainPeriod(date)}
        />
      )}
      {/* datepicker in day and week view */}
      {(dashboardSettings.view === 'day' || dashboardSettings.view === 'week') && (
        <DatePicker
          open={pickerOpen}
          onClose={() => setPickerOpen(false)}
          value={mainPeriod}
          renderInput={(props) => (
            <TextField
              {...props}
              variant="standard"
              sx={{width: 130}}
              onClick={() => setPickerOpen(true)}
              inputProps={{
                ...props.inputProps,
                value: props.inputProps.value === '' ? lastLabel : props.inputProps.value,
                readOnly: true,
              }}
            />
          )}
          rifmFormatter={handleInputFormat}
          disabled={isDisabled}
          maxDate={moment()}
          minDate={moment('01-01-2022', 'MM-DD-YYYY')}
          showDaysOutsideCurrentMonth
          showToolbar
          ToolbarComponent={(props) => (
            <Box sx={{pr: 3, pt: 3}} display="flex" justifyContent="flex-end">
              <Button
                variant="text"
                sx={{p: 0}}
                onClick={() => {
                  handleLastLabelClick();
                  setPickerOpen(false);
                }}>
                {lastLabel}
              </Button>
            </Box>
          )}
          clearable
          onAccept={(date) => handleDateChange(date)}
          onChange={(date) => setMainPeriod(date)}
          dayOfWeekFormatter={dashboardSettings.view === 'week' ? renderWeekPickerHeader : null}
          renderDay={dashboardSettings.view === 'week' ? renderWeekPickerDay : null}
        />
      )}
    </>
  );
};

export default React.memo(DateSelector);
