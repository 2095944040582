import React from 'react';
import {Trans} from '@lingui/macro';
import BlockIcon from '@mui/icons-material/Block';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

function Error404(props) {
  return (
    <>
      <Typography variant="h2" align="center">
        <Trans>404 Page not found</Trans>
      </Typography>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          overflow: 'auto',
          flexDirection: 'column',
          height: 240,
        }}>
        <Typography align="center">
          <BlockIcon style={{fontSize: 120}} />
        </Typography>
        <Typography variant="subtitle1" align="center">
          <Trans>The resource could not be found</Trans>
        </Typography>
      </Paper>
    </>
  );
}

export default Error404;
