/* eslint-disable react/style-prop-object */
import React, {useCallback, useState} from 'react';
import ReactMapGL, {Layer, Marker, NavigationControl, Source} from 'react-map-gl';
import debounce from 'lodash.debounce';

import Arrow from './Arrow';
import MapShape from './MapShape';
import Pin from './Pin';

const navControlStyle = {
  left: 10,
  top: 10,
};

function ConfiguratorMap(props) {
  // Generic Mapbox configuration
  const [config] = useState({
    mapboxAccessToken: 'pk.eyJ1IjoiZXJpay1hZnJ5IiwiYSI6ImNrbnZ0bWtsMDBxYTcyeHJ2Ym54MGxqYzAifQ.UZAQI8EK7eUV79jBAoG9SQ',
    mapStyle: 'mapbox://styles/mapbox/dark-v9',
    attributionControl: false,
  });

  // Viewport settings
  const [viewport, setViewport] = useState({
    initialViewState: {
      bounds: [props.overlayBounds[0], props.overlayBounds[2]],
    },
    bearing: 0,
    pitch: 0,
  });

  // Viewport clamping; i.e. bounds for panning
  const setViewportClamped = (viewport) => {
    if (props.clamp) {
      if (viewport.latitude > props.maxLatitude) {
        viewport.latitude = props.maxLatitude || 0;
      }
      if (viewport.latitude < props.minLatitude) {
        viewport.latitude = props.minLatitude || 0;
      }
      if (viewport.longitude > props.maxLongitude) {
        viewport.longitude = props.maxLongitude || 0;
      }
      if (viewport.longitude < props.minLongitude) {
        viewport.longitude = props.minLongitude || 0;
      }
    }
    setViewport(viewport);
  };

  const [settings] = useState({
    dragPan: props.allowInteraction,
    dragRotate: false,
    scrollZoom: props.allowInteraction,
    touchZoom: props.allowInteraction,
    touchRotate: false,
    keyboard: props.allowInteraction,
    doubleClickZoom: props.allowInteraction,
    minZoom: props.minZoom || 5,
    maxZoom: props.maxZoom || 8,
    minPitch: 0,
    maxPitch: 0,

    pitchWithRotate: false,
    touchZoomRotate: false,
  });

  const getOverlayBounds = () => {
    if (props.mode === modes.IMAGE && !props.overlayBounds) {
      return [
        [0, 1],
        [1, 1],
        [1, 0],
        [0, 0],
      ];
    }
    return props.overlayBounds;
  };

  const getOverlay = () => {
    if (props.overlayUrl) {
      const overlaySource = {
        id: 'overlay',
        type: 'image',
        url: props.overlayUrl,
        coordinates: getOverlayBounds(),
      };
      const overlayLayer = {
        id: 'overlay',
        source: 'overlay',
        type: 'raster',
        paint: {'raster-opacity': 1},
      };
      return (
        <Source {...overlaySource}>
          <Layer {...overlayLayer} />
        </Source>
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(
    debounce((polygon) => props.onModifyPolygons(polygon), 100),
    []
  );

  const onMarkerDrag = useCallback(
    (event, coordinatIndex, polygon) => {
      polygon.coordinates[coordinatIndex] = event.lngLat.toArray();
      if (props.onModifyPolygons) {
        // props.onModifyPolygons(polygon);
        debouncedSave(polygon);
      }
    },
    [debouncedSave, props]
  );

  const onClick = useCallback(
    (event) => {
      for (let i = 0; i < props.polygons.length; i++) {
        const polygon = props.polygons[i];
        if (polygon.isEditable) {
          polygon.coordinates.push(event.lngLat.toArray());
          debouncedSave(polygon);
        }
      }
    },
    [props.polygons, debouncedSave]
  );

  const markers = [];

  // Map markers for drag/drop
  props.polygons.forEach((p, polygonIndex) => {
    if (p.isEditable) {
      const color = p.color;
      p.coordinates.forEach((coord, coordinatIndex) => {
        if (coordinatIndex < p.coordinates.length) {
          markers.push(
            <Marker
              key={polygonIndex + '-' + coordinatIndex}
              longitude={coord[0]}
              latitude={coord[1]}
              offsetTop={-40}
              offsetLeft={-20}
              draggable
              onDrag={(event) => onMarkerDrag(event, coordinatIndex, p)}>
              <Pin size={40} color={color} />
            </Marker>
          );
        }
      });
    }
  });

  // "in" arrows on lines
  props.polygons.forEach((p, polygonIndex) => {
    const coords = p.coordinates;
    if (coords.length > 1 && p.shapeType === 'line') {
      const color = p.color;
      for (let i = 1; i < coords.length; i++) {
        const coord1 = coords[i - 1];
        const coord2 = coords[i];

        const x1 = coord1[0];
        const x2 = coord2[0];
        const y1 = coord1[1];
        const y2 = coord2[1];

        const angle = (Math.atan2(x1 - x2, y1 - y2) * 180) / Math.PI;

        markers.push(
          <Marker
            title={angle}
            key={polygonIndex + '-arrow-' + i}
            longitude={(x1 + x2) / 2}
            latitude={(y1 + y2) / 2}
            offsetTop={-20}
            offsetLeft={-20}>
            <Arrow size={40} color={color} angle={angle + 'deg'} />
          </Marker>
        );
      }
    }
  });

  const polygons = props.polygons.map((p, index) => {
    const coords = Object.assign([], p.coordinates);
    if (coords.length === 2 && p.shapeType === 'line') {
      coords.push([coords[1][0], coords[1][1] + 0.005]);
    }
    return <MapShape id={p.id} key={p.id} coordinates={coords} color={p.color} shapeType={p.shapeType} />;
  });

  return (
    <>
      <ReactMapGL
        {...config}
        style={{width: '100%', height: '100%'}}
        {...viewport}
        {...settings}
        onMove={(event) => setViewportClamped(event.viewState)}
        onClick={onClick}>
        {getOverlay()}
        {polygons}
        {markers}
        {props.allowInteraction && <NavigationControl style={navControlStyle} showCompass={false} />}
      </ReactMapGL>
    </>
  );
}

export const modes = {
  MAP: 'MAP',
  IMAGE: 'IMAGE',
  BOTH: 'BOTH',
};

ConfiguratorMap.defaultProps = {
  // Viewport
  width: '600px',
  height: '400px',
  viewport: {
    latitude: 37.7577,
    longitude: -122.4376,
    zoom: 6,
  },

  // Clamping
  clamp: false,
  minLatitude: -90,
  maxLatitude: 90,
  minLongitude: -180,
  maxLongitude: 180,

  // Restrictions
  minZoom: 4,
  maxZoom: 8,

  mode: modes.MAP,

  allowInteraction: true,

  overlayBounds: [
    [0, 1],
    [1, 1],
    [1, 0],
    [0, 0],
  ],

  polygons: [],
};

export default ConfiguratorMap;
