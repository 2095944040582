import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Trans} from '@lingui/macro';
import SettingsIcon from '@mui/icons-material/Settings';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';

import * as ROUTES from '../../routes/Routes';

const useStyles = makeStyles((theme) => ({
  linkItem: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));

export default function FooterListItems(props) {
  const classes = useStyles();
  const drawerOpen = useSelector((state) => state.ui.drawer.drawerOpen);
  return (
    <List>
      <Link to={ROUTES.SETTINGS} className={classes.linkItem}>
        <Tooltip title={<Trans>Settings</Trans>} placement="right" disableHoverListener={drawerOpen}>
          <ListItem button sx={drawerOpen ? {pl: 4} : null}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
        </Tooltip>
      </Link>
    </List>
  );
}
