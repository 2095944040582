import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {TableCell, TableRow} from '@mui/material';
import {setDashboardSelectedFlow} from 'redux/dashboard.reducer';

const hoverStyles = {
  '&:hover': {
    backgroundColor: '#0000000a',
    cursor: 'pointer',
  },
};

const topAndBottomBorder = {
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
  borderTop: '1px solid rgba(224, 224, 224, 1)',
};

function FlowTableRow({entry, entryName, exits, flowData, updateChart, isCellSelected}) {
  const dispatch = useDispatch();
  const dashboardSettings = useSelector((state) => state.dashboard.settings);
  const [expanded, setExpanded] = useState(false);
  const [classes] = useState([...new Set(flowData.map((e) => e.class))].sort((a, b) => a.localeCompare(b)));

  const onEntryRowSelection = useCallback(
    (entry) => {
      const dashboardSettingsClone = {...dashboardSettings};
      dashboardSettingsClone.selectedFlow = {entry, exit: null, detectionClass: null};
      dispatch(setDashboardSelectedFlow({entry, exit: null, detectionClass: null}));
      updateChart(dashboardSettingsClone);
    },
    [dashboardSettings, dispatch, updateChart]
  );

  const onCellSelection = useCallback(
    (entry, exit) => {
      const dashboardSettingsClone = {...dashboardSettings};
      dashboardSettingsClone.selectedFlow = {entry, exit, detectionClass: null};
      dispatch(setDashboardSelectedFlow({entry, exit, detectionClass: null}));
      updateChart(dashboardSettingsClone);
    },
    [dashboardSettings, dispatch, updateChart]
  );

  const onClassSelection = useCallback(
    (entry, exit, detectionClass) => {
      const dashboardSettingsClone = {...dashboardSettings};
      dashboardSettingsClone.selectedFlow = {entry, exit, detectionClass};
      dispatch(setDashboardSelectedFlow({entry, exit, detectionClass}));
      updateChart(dashboardSettingsClone);
    },
    [dashboardSettings, dispatch, updateChart]
  );

  return (
    <>
      <TableRow
        key={'row' + entry}
        sx={{
          '&:last-child td, &:last-child th': {borderBottom: 0},
          height: '42px',
        }}>
        {flowData.length > 0 ? (
          <TableCell
            component="th"
            scope="row"
            onClick={() => setExpanded(!expanded)}
            sx={{
              ...topAndBottomBorder,
              paddingRight: 1,
              paddingLeft: 1,
              ...hoverStyles,
            }}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </TableCell>
        ) : (
          <TableCell
            component="th"
            scope="row"
            sx={{
              ...topAndBottomBorder,
              paddingRight: 1,
              paddingLeft: 1,
            }}></TableCell>
        )}
        <TableCell
          component="th"
          scope="row"
          onClick={() => onEntryRowSelection(entry)}
          sx={{
            ...topAndBottomBorder,
            borderRight: '1px solid rgba(224, 224, 224, 1)',
            ...hoverStyles,
            fontWeight: entry === 'Total' ? 'bold' : 'unset',
            backgroundColor: isCellSelected(entry, null, null) ? '#f0f1f4' : 'inherit',
          }}>
          {entryName}
        </TableCell>
        {exits.map((exit) => (
          <TableCell
            key={'cell' + exit}
            align="right"
            onClick={() => onCellSelection(entry, exit)}
            sx={{
              ...topAndBottomBorder,
              fontWeight: entry === 'Total' ? 'bold' : 'unset',
              ...hoverStyles,
              backgroundColor: isCellSelected(entry, exit, null) ? '#f0f1f4' : 'inherit',
            }}>
            {flowData.filter((fd) => fd.exit.sensorId === exit).length}
          </TableCell>
        ))}
        <TableCell
          align="right"
          onClick={() => onCellSelection(entry, 'Total')}
          sx={{
            ...topAndBottomBorder,
            fontWeight: 'bold',
            ...hoverStyles,
            backgroundColor: isCellSelected(entry, 'Total', null) ? '#f0f1f4' : 'inherit',
          }}>
          {flowData.length}
        </TableCell>
      </TableRow>
      {expanded &&
        classes.map((c) => (
          <TableRow key={'row' + c}>
            <TableCell
              component="th"
              scope="row"
              sx={{
                border: 0,
              }}></TableCell>
            <TableCell
              component="th"
              scope="row"
              onClick={() => onClassSelection(entry, null, c)}
              sx={{
                border: 0,
                ...hoverStyles,
                backgroundColor: isCellSelected(entry, null, c) ? '#f0f1f4' : 'inherit',
              }}>
              {c}
            </TableCell>
            {exits.map((exit) => (
              <TableCell
                key={'cell' + exit}
                align="right"
                onClick={() => onClassSelection(entry, exit, c)}
                sx={{
                  border: 0,
                  ...hoverStyles,
                  backgroundColor: isCellSelected(entry, exit, c) ? '#f0f1f4' : 'inherit',
                }}>
                {flowData.filter((fd) => fd.exit.sensorId === exit && fd.class === c).length}
              </TableCell>
            ))}
            <TableCell
              align="right"
              onClick={() => onClassSelection(entry, 'Total', c)}
              sx={{
                border: 0,
                fontWeight: 'bold',
                ...hoverStyles,
                backgroundColor: isCellSelected(entry, 'Total', c) ? '#f0f1f4' : 'inherit',
              }}>
              {flowData.filter((fd) => fd.class === c).length}
            </TableCell>
          </TableRow>
        ))}
    </>
  );
}

export default FlowTableRow;
