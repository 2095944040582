// MSAL imports
import {PublicClientApplication} from '@azure/msal-browser';
import log from 'loglevel';

import {refreshCredentials} from '../api/api';
import {setCredentials} from '../redux/credentials';
import {setAccessToken, setAccessTokenExpiration, setAccount, setLoggedIn, setLogOut} from '../redux/session';

import {loginRequest, msalConfig} from './authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

class AuthHandler {
  constructor(store) {
    // this.loggedIn = false;
    this.store = store;
    this.msalInstance = msalInstance;
    this.credentialTimer = null;
    // on first load we check if user has an access token in local storage
    this.checkIfUserIsLoggedInOrLogInUser().then((r) => {
      // if (this.loggedIn) {
      this.handleRefreshCredentials();
    });
  }

  handleRefreshCredentials() {
    refreshCredentials().catch((error) => {
      if (error.response) {
        // if (error.response.status === 401) {
        //   // User is no longer logged in.
        //   log.debug('getCredentials returned 401, need to sign in again.');
        //   // clearInterval(this.credentialTimer);
        //   // This needs to be in a state or bind?
        //   // this.loggedIn = false;
        //   this.store.dispatch(setAccessToken(null));
        //   this.store.dispatch(setCredentials(null));
        //   this.store.dispatch(setLoggedIn(false));
        // } else {
        log.debug('getCredentials returned other error: ' + JSON.stringify(error));
        // }
      } else {
        log.debug('Error on get credentials: ' + error);
        //Network error etc, perhaps show other modal saying that the backend is down?
      }
    });
  }

  logoutCurrentUser() {
    // const account = this.msalInstance.getActiveAccount();

    // if (!account) {
    //   console.log('No active account');
    //   return;
    // }

    clearInterval(this.credentialTimer);
    const logoutRequest = {
      // account: account,
    };
    log.info('Logging out: ' + JSON.stringify(logoutRequest));

    //TODO remove more of the state?
    this.store.dispatch(setCredentials(null));
    this.store.dispatch(
      setLogOut({account: {name: ''}, accessToken: null, tokenExpirationTime: null, loggedIn: false})
    );

    // TODO handle promise?
    return this.msalInstance.logoutRedirect(logoutRequest);
  }

  checkIfUserIsLoggedInOrLogInUser() {
    const sessionState = this.store.getState().session;
    let accessToken = sessionState.accessToken;
    log.info('auth.js session state is: ' + JSON.stringify(sessionState));
    if (!!accessToken && accessToken !== '') {
      log.info('User is logged in, setting logged in to true.');
      // TODO check if the session is still valid.
      this.store.dispatch(setLoggedIn(true));
      // this.loggedIn = true;
      return Promise.resolve(true);
    }
    log.info('No access token, need to get a token');
    return this.getAuthenticationHeader();
  }

  getLoggedInUser = () => {
    return this.msalInstance.handleRedirectPromise().then((redirectResponse) => {
      // If this is the return redirect of the login flow
      log.info('redirect response is: ' + JSON.stringify(redirectResponse));
      if (redirectResponse && redirectResponse.account) {
        return redirectResponse;
      }
      log.info('Login redirect');
      return this.msalInstance.loginRedirect(loginRequest);
    });
  };

  getAuthenticationHeader() {
    let account = this.msalInstance.getActiveAccount();
    if (!account) {
      log.info('User is not signed in!!!');
      return this.getLoggedInUser()
        .then((userResponse) => {
          account = userResponse.account;
          // const currentAccounts = this.msalInstance.getAllAccounts();
          // log.info('Got all logged in accounts: ' + JSON.stringify(currentAccounts));
          // if (currentAccounts === null) {
          //   // no accounts detected
          //   log.info('No accounts detected');
          // } else if (currentAccounts.length > 1) {
          //   // Add choose account code here
          //   // TODO choose which account to use as the active
          //   log.info('More than one account logged in!!! Needs to be handled, using first account for now');
          //   account = currentAccounts[0];
          // } else if (currentAccounts.length === 1) {
          //   log.info('Only one user logged in, setting it as active.');
          //   account = currentAccounts[0];
          // }
          this.msalInstance.setActiveAccount(account);
          this.store.dispatch(setAccount(account));
          const token = `Bearer ${userResponse.accessToken}`;
          this.store.dispatch(setAccessTokenExpiration(userResponse.expiresOn));
          this.store.dispatch(setAccessToken(token));
          log.info('Setting logged in to true');
          // TODO this doesn't cause rerendering
          // this.loggedIn = true;
          this.store.dispatch(setLoggedIn(true));
          return;

          //TODO THIS IS NOT NEEDED AS THE accessToken is in the account
          // return this.msalInstance
          //   .acquireTokenSilent({
          //     ...loginRequest,
          //     account: account,
          //   })
          //   .then((response) => {
          //     log.info('Got response from acquireTokenSilent: ' + JSON.stringify(response));
          //     log.info('Returning accesstoken: ' + JSON.stringify(response.accessToken));
          //     const token = `Bearer ${response.accessToken}`;
          //     this.store.dispatch(setAccessToken(token));
          //     return token;
          //   });
        })
        .catch((error) => {
          if (error.message.includes('AADB2C90091')) {
            // if user cancels password reset
            window.location.reload();
          }
          log.error('Error on sign in: ', error);
        });
    }
    return this.msalInstance
      .acquireTokenSilent({
        ...loginRequest,
        account: account,
      })
      .then((response) => {
        log.info('Got response from acquireTokenSilent: ' + JSON.stringify(response));
        log.info('Returning accesstoken: ' + JSON.stringify(response.accessToken));
        const token = `Bearer ${response.accessToken}`;
        this.store.dispatch(setAccessToken(token));
        this.store.dispatch(setLoggedIn(true));
        // credentials is null
        // credentialTimer is not set
        return token;
      });
  }
}

export default AuthHandler;
