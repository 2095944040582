import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function StepOne() {
  return (
    <Box>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 4,
          backgroundColor: 'background.default',
        }}>
        <Typography variant="h2">Hello, we just need some more information.</Typography>
      </Paper>
      <img
        style={{
          height: 255,
          // maxWidth: 400,
          overflow: 'hidden',
          display: 'block',
          width: '100%',
        }}
        src="https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60"
        alt={'just a label'}
      />
      <Box sx={{p: 2}}>
        <Typography component="h3">Hello this is step 1</Typography>
        <Typography component="p">You're seeing this since this is the first time you're logging in.</Typography>
      </Box>
    </Box>
  );
}
