import React from 'react';
import {useDispatch} from 'react-redux';
import {Trans} from '@lingui/macro';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import {addPolygon} from '../../redux/configurator';

import {getColor} from './colors';
import ConfiguratorPolygon from './ConfiguratorPolygon';

const ConfiguratorToggles = ({
  polygons,
  onCommit,
  isLine,
  showAreaDescription,
  spatialSubKey,
  deviceId,
  sensorGroupUpdates,
  setSensorGroupUpdates,
}) => {
  const dispatch = useDispatch();

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item container xs={12}>
          {polygons.map((polygon) => (
            <ConfiguratorPolygon
              key={polygon.id}
              polygon={polygon}
              onCommit={onCommit}
              showAreaDescription={showAreaDescription}
              spatialSubKey={spatialSubKey}
              deviceId={deviceId}
              sensorGroupUpdates={sensorGroupUpdates}
              setSensorGroupUpdates={setSensorGroupUpdates}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          {isLine ? (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                dispatch(
                  addPolygon({
                    id: 'line-' + (Math.random() + '').substr(2),
                    shapeType: 'line',
                    coordinates: [],
                    isEditable: true,
                    color: getColor(polygons.length),
                    areaDescription: '',
                    capacity: 0,
                    isFlow: false,
                  })
                );
              }}>
              <Trans>Add New Line</Trans>
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                dispatch(
                  addPolygon({
                    id: 'area-' + (Math.random() + '').substr(2),
                    shapeType: 'polygon',
                    coordinates: [],
                    isEditable: true,
                    color: getColor(polygons.length),
                    areaDescription: '',
                    capacity: 0,
                    isFlow: false,
                  })
                );
              }}>
              <Trans>Add New Area</Trans>
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ConfiguratorToggles;
