import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {curveLinear} from '@visx/curve';
import {localPoint} from '@visx/event';
import {Group} from '@visx/group';
import {scaleLinear, scaleTime} from '@visx/scale';
import {LinePath} from '@visx/shape';
import {TooltipWithBounds, useTooltip} from '@visx/tooltip';
import _ from 'lodash';

const ActivityGraph = ({height, width, setTime, polygons}) => {
  const {tooltipData, tooltipLeft = 0, tooltipTop = 0, showTooltip, hideTooltip} = useTooltip();
  const from = useSelector((state) => state.reports.from);
  const to = useSelector((state) => state.reports.to);
  // bounds
  const xMax = width;
  const yMax = height;

  // accessors return values of that data item
  const x = useCallback((d) => new Date(d.observationTime).valueOf(), []);
  const y = useCallback((d) => d.objects, []);

  const ploygonsXDomain = useMemo(() => [from, to], [from, to]);

  const xScale = useMemo(
    () =>
      scaleTime({
        range: [0, xMax],
        domain: ploygonsXDomain,
      }),
    [ploygonsXDomain, xMax]
  );

  const polygonsYRange = useMemo(() => _.range(from, to, 100), [from, to]);
  const polygonsYData = useMemo(
    () =>
      polygonsYRange.reduce((s, a) => {
        s.push({observationTime: a, objects: polygons.filter((p) => p.observationTime === a).length});
        return s;
      }, []),
    [polygons, polygonsYRange]
  );

  const ploygonsYDomainMax = useMemo(() => Math.max(...polygonsYData.map(y)), [polygonsYData, y]);
  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [0, yMax],
        domain: [ploygonsYDomainMax, 0],
      }),
    [ploygonsYDomainMax, yMax]
  );

  const handleMouseMove = useCallback(
    (event) => {
      const coords = localPoint(event.target.ownerSVGElement, event);
      const {x} = localPoint(event) || {x: 0};
      const x0 = xScale.invert(x);
      const time = `${x0.valueOf()}`;
      const data = polygonsYData.find((pd) => `${pd.observationTime}`.includes(time.substring(0, 11)));
      showTooltip({
        tooltipLeft: coords?.x || x,
        tooltipTop: coords?.y || yMax,
        tooltipData: data,
      });
    },
    [polygonsYData, showTooltip, xScale, yMax]
  );

  const handleClick = useCallback(
    (event) => {
      const {x} = localPoint(event) || {x: 0};
      const x0 = xScale.invert(x);
      const time = `${x0.valueOf()}`;
      const data = polygonsYData.find((pd) => `${pd.observationTime}`.includes(time.substring(0, 11)));
      setTime(data.observationTime);
    },
    [polygonsYData, setTime, xScale]
  );

  return (
    <div style={{position: 'relative'}}>
      <svg width={width} height={height} onMouseMove={handleMouseMove} onMouseOut={hideTooltip} onClick={handleClick}>
        <Group top={0} left={0}>
          <LinePath
            data={polygonsYData}
            curve={curveLinear}
            x={(d) => xScale(x(d))}
            y={(d) => yScale(y(d))}
            stroke="#d95940"
            strokeWidth={1}
          />
        </Group>
      </svg>
      {tooltipData && (
        <TooltipWithBounds key={tooltipData.observationTime} top={tooltipTop} left={tooltipLeft}>
          <strong>{tooltipData.objects}</strong> object(s) at{' '}
          <strong>{new Date(tooltipData.observationTime).toLocaleTimeString('en-GB')}</strong>
        </TooltipWithBounds>
      )}
    </div>
  );
};

export default React.memo(ActivityGraph);
