import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import log from 'loglevel';
import {PersistGate} from 'redux-persist/integration/react';

import {setStore} from './api/api';
import App from './App';
import configureStore from './store';

// TODO set this to WARN in production
log.setDefaultLevel(log.levels.TRACE);

const {store, persistor} = configureStore();

setStore(store);

const Loading = () => {
  return (
    <>
      <h2>Please wait while we're restoring your session.</h2>
    </>
  );
};

const root = createRoot(document.querySelector('#root'));

root.render(
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
