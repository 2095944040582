import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ModalComponent = ({children, isOpen = false, onClose}) => {
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Modal
      open={open}
      aria-labelledby="modal"
      disableEnforceFocus
      disableAutoFocus
      onClose={handleClose}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(128,128,128,0.5)',
        },
      }}>
      <Box
        style={modalStyle}
        sx={{
          position: 'absolute',
          width: '80%',
          minWidth: 400,
          maxWidth: 800,
          overflow: 'auto',
          maxHeight: '90%',
          backgroundColor: 'background.paper',
          border: '1px solid #999',
          boxShadow: 5,
          pt: 4,
          pr: 4,
          pl: 4,
          pb: 3,
        }}>
        {children}
      </Box>
    </Modal>
  );
};

export default ModalComponent;
