import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Trans} from '@lingui/macro';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Tab} from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {withAuthorization} from '../../oauth/Session';
import {setDashboardSettings} from '../../redux/dashboard.reducer';
import * as Conditions from '../../routes/Conditions';

import Counters from './Counters';
import Flow from './Flow';
import {DEFAULTDASHBOARDSETTINGS} from './helpers';
import Occupancy from './Occupancy';

function Dashboard(props) {
  const dispatch = useDispatch();
  const selectedTab = useSelector((state) => state.dashboard.settings.dashboardType);

  const handleTabChange = useCallback(
    (event, newValue) => dispatch(setDashboardSettings(DEFAULTDASHBOARDSETTINGS[newValue])),
    [dispatch]
  );

  useEffect(() => {
    // Occupancy is the default dashboard
    dispatch(setDashboardSettings(DEFAULTDASHBOARDSETTINGS['occupancy']));
  }, [dispatch]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">
            <Trans>Dashboard</Trans>
          </Typography>
        </Grid>
        <Grid item sx={{minWidth: 0, width: '100%'}}>
          <TabContext value={selectedTab}>
            <TabList onChange={handleTabChange} aria-label="report-type" centered>
              <Tab label="Occupancy" value="occupancy" />
              <Tab label="Counters" value="counters" />
              <Tab label="Flow" value="flow" />
            </TabList>
            <TabPanel value="occupancy" sx={{p: 0}}>
              <Occupancy />
            </TabPanel>
            <TabPanel value="counters" sx={{p: 0}}>
              <Counters />
            </TabPanel>
            <TabPanel value="flow" sx={{p: 0}}>
              <Flow />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </>
  );
}

export default withAuthorization(Conditions.ANY)(Dashboard);
