import React from 'react';
import {useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {compose} from 'recompose';

import NoAccess from '../../components/NoAccess';
import {withAuthHandler} from '../';

import {AuthorizationContext} from './context';

const withAuthorization = (condition) => (Component) => {
  const WithAuthorization = (props) => {
    const credentials = useSelector((state) => state.credentials);
    if (condition !== 'ANY' && !credentials.userTypes.includes(condition)) {
      return (
        <AuthorizationContext.Provider value={credentials}>
          <NoAccess {...props} />
        </AuthorizationContext.Provider>
      );
    }

    return (
      <AuthorizationContext.Provider value={credentials}>
        <Component {...props} />
      </AuthorizationContext.Provider>
    );
  };
  return compose(withRouter, withAuthHandler)(WithAuthorization);
};

export default withAuthorization;
