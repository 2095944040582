import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export default function StepTwo() {
  return (
    <Box>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 4,
          backgroundColor: 'background.default',
        }}>
        <Typography variant="h2">Please continue.</Typography>
      </Paper>
      <img
        style={{
          height: 255,
          // maxWidth: 400,
          overflow: 'hidden',
          display: 'block',
          width: '100%',
        }}
        src="https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60"
        alt={'just a label'}
      />
      <Box sx={{p: 2}}>
        <Typography>Hello this is step 2</Typography>
      </Box>
    </Box>
  );
}
