import React, {useCallback, useEffect, useState} from 'react';
import {FormControl, FormHelperText, TextField} from '@mui/material';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Button from '../../../components/Button';

const defaultSettings = {
  sendIntervallMillis: 1000,
};

const FlowityOutputConfig = ({editableConfig, updateFromObject}) => {
  const flowitySettings = editableConfig.output.Flowity;
  const [hasFlowitySettings, setHasFlowitySettings] = useState(false);

  useEffect(() => {
    if (flowitySettings) {
      setHasFlowitySettings(flowitySettings.sendIntervallMillis > 0);
    } else {
      setHasFlowitySettings(false);
    }
  }, [flowitySettings]);

  const toggleHasFlowitySettings = useCallback(() => {
    const newSettings = {
      ...editableConfig,
      output: {
        ...editableConfig.output,
        Flowity: {...defaultSettings},
      },
    };
    if (hasFlowitySettings) {
      delete newSettings.output.Flowity;
    }
    updateFromObject(newSettings);
  }, [editableConfig, hasFlowitySettings, updateFromObject]);

  const updateProperty = useCallback(
    (key, newValue) => {
      const outputObject = {
        ...editableConfig.output,
        Flowity: {...flowitySettings},
      };
      outputObject.Flowity[key] = newValue;
      updateFromObject({...editableConfig, output: outputObject});
    },
    [editableConfig, flowitySettings, updateFromObject]
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Flowity Output Config</Typography>
        </Grid>
        {hasFlowitySettings && flowitySettings && (
          <>
            <Grid item xs={12}>
              <Alert severity="info">Flowity output is enabled.</Alert>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={(event) => {
                  toggleHasFlowitySettings();
                }}>
                Disable Flowity output
              </Button>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  variant="standard"
                  label="sendIntervallMillis"
                  aria-describedby="helper-sendIntervallMillis"
                  value={flowitySettings.sendIntervallMillis + ''}
                  type="number"
                  onChange={(event) => {
                    updateProperty('sendIntervallMillis', parseInt(event.target.value));
                  }}
                />
                <FormHelperText id="helper-sendIntervallMillis">
                  How often should we send data from the device? Unit: milliseconds
                </FormHelperText>
              </FormControl>
            </Grid>
          </>
        )}
        {!hasFlowitySettings && (
          <>
            <Grid item xs={12}>
              <Alert severity="info">Flowity output is disabled.</Alert>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={(event) => {
                  toggleHasFlowitySettings();
                }}>
                Enable Flowity output
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default FlowityOutputConfig;
