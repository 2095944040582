import React, {useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import Grid from '@mui/material/Grid';
import {addDeployment} from 'api/deployments';
import {useSnackbar} from 'notistack';

import Button from '../../../components/Button';

const CreateMeasurementForm = ({setIsModalOpen}) => {
  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();

  const devicesList = useSelector((state) => state.devices.devicesList);

  const [selectedDevice, setSelectedDevice] = useState('');

  const devicesDropdown = useMemo(
    () =>
      devicesList.map((device, i) => (
        <MenuItem key={device.id} value={device.id}>
          {device.name}
        </MenuItem>
      )),
    [devicesList]
  );

  const handleDeviceChange = useCallback((event) => setSelectedDevice(event.target.value), []);

  const add = useCallback(() => {
    addDeployment(selectedDevice)
      .then((deployment) => {
        history.push('/deployments/' + deployment.id);
      })
      .catch((error) => {
        enqueueSnackbar('Error adding deployment: ' + error.message, {variant: 'error'});
      });
  }, [enqueueSnackbar, history, selectedDevice]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth size="small" variant="standard">
            <InputLabel id="hardware-label">Hardware</InputLabel>
            <Select
              variant="standard"
              aria-describedby="helper-hardware"
              id="hardware-select"
              value={selectedDevice}
              displayEmpty
              label="Sources"
              onChange={handleDeviceChange}>
              {devicesDropdown}
            </Select>
          </FormControl>
        </Grid>
        <Grid item conatiner xs={12} align="right" justify="space-between">
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedDevice}
            onClick={(event) => {
              add();
            }}>
            Create new measurement
          </Button>
          <Button
            variant="contained"
            sx={{marginLeft: 1}}
            startIcon={<CloseIcon />}
            onClick={(event) => {
              setIsModalOpen(false);
            }}>
            Close
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateMeasurementForm;
