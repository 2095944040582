import React, {useState} from 'react';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import Modal from '../../../components/Modal';

import EditDeviceForm from './EditDeviceForm';

function DevicePropertiesCard({device}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Grid container>
        <TableContainer component={Paper} elevation={1}>
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{
                  height: 60,
                }}>
                <TableCell>
                  <Typography
                    sx={{
                      fontWeight: 600,
                    }}>
                    Properties
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="edit device"
                    onClick={(event) => {
                      setIsModalOpen(true);
                    }}
                    size="large">
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key="Name"
                sx={{
                  height: 60,
                }}>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">{device.name}</TableCell>
              </TableRow>
              <TableRow
                key="Type"
                sx={{
                  height: 60,
                }}>
                <TableCell align="left">Device Type</TableCell>
                <TableCell align="left">{device.deviceType}</TableCell>
              </TableRow>
              <TableRow
                key="Position"
                sx={{
                  height: 60,
                }}>
                <TableCell align="left">Position</TableCell>
                <TableCell align="left">{device.position}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Modal
        isOpen={isModalOpen}
        onClose={(event) => {
          setIsModalOpen(false);
        }}>
        <EditDeviceForm
          device={device}
          onClose={(event) => {
            setIsModalOpen(false);
          }}
        />
      </Modal>
    </>
  );
}

export default DevicePropertiesCard;
