import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {refreshDeployments, refreshDevices} from 'helpers';
import {useSnackbar} from 'notistack';

import {deleteDeployment, toggleDeployment} from '../../api/deployments';
import Button from '../../components/Button';
import DebugItem from '../../components/DebugItem';
import {HeaderButtonDelete, HeaderButtonRefresh} from '../../components/HeaderButtons';
import Modal from '../../components/Modal';
import InformationalLoader from '../../components/Progress/InformationalLoader';
import {withAuthorization} from '../../oauth/Session';
import * as Conditions from '../../routes/Conditions';

import DeploymentConfig from './DeploymentConfig';
import EditDeploymentForm from './EditDeploymentForm';

function DeploymentPage(props) {
  const {did} = useParams('did');
  const history = useHistory();
  const dispatch = useDispatch();
  const currentOrganization = useSelector((state) => state.session.currentOrganization);
  const errorCode = useSelector((state) => state.deployments.deploymentsErrorCode);
  const isLoading = useSelector((state) => state.deployments.isLoadingDeployments);
  const list = useSelector((state) => state.deployments.deploymentsList);
  const deployment = list.filter((d) => d.id === did)[0];
  const devicesList = useSelector((state) => state.devices.devicesList);
  let currentDevice = null;
  if (deployment) {
    currentDevice = devicesList.filter((d) => d.id === deployment.deviceId)[0];
  }

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isActivating, setIsActivating] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
  const {enqueueSnackbar} = useSnackbar();
  const refresh = useCallback(() => {
    refreshDevices(dispatch, enqueueSnackbar, currentOrganization);
    refreshDeployments(dispatch);
  }, [currentOrganization, dispatch, enqueueSnackbar]);

  useEffect(() => {
    // making sure we have the latest data and notify the user if somebody else changes the same config
    const interval = setInterval(refresh, 60000);
    refresh();
    return () => clearInterval(interval);
  }, [refresh]);

  const doDelete = useCallback(() => {
    if (deployment) {
      const id = deployment.id;
      setIsDeleting(true);
      deleteDeployment(id)
        .then(() => {
          history.push('/measurements');
        })
        .catch((error) => {
          setDeleteErrorMessage(error.message);
          setIsDeleting(false);
          enqueueSnackbar('Error deleting deployment: ' + error.message, {variant: 'error'});
        });
    }
  }, [deployment, history, enqueueSnackbar]);

  const transformSensorsToOldFormat = useCallback((deployment) => {
    // the source of truth for footfall and area detect sensors used to be deployment.jcoreConfig.functions
    // but it was moved to deployment.sensors.
    const sensorsInOldFormat = deployment.sensors.map((sensor) => ({
      color: sensor.color,
      coordinates: sensor.coordinates,
      id: sensor.sensorId,
      areaDescription: sensor.description,
      capacity: sensor.capacity,
      isFlow: sensor.isFlow,
    }));
    deployment.jcoreConfig.functions.footfalls = sensorsInOldFormat.filter(
      (sensor, i) => deployment.sensors[i].type === 'FOOTFALL'
    );
    deployment.jcoreConfig.functions.areaDetect = sensorsInOldFormat.filter(
      (sensor, i) => deployment.sensors[i].type === 'AREA_PRESENCE'
    );
    return deployment;
  }, []);

  const mainContent = useCallback(() => {
    const deploymentWithFormattedSensors = transformSensorsToOldFormat(deployment);
    if (errorCode !== '') {
      return (
        <Grid item xs={12}>
          <Alert severity="error">{errorCode}</Alert>
        </Grid>
      );
    } else if (deploymentWithFormattedSensors) {
      const toggleDeploymentButton = (
        <Button
          startIcon={isActivating && <CircularProgress size={18} />}
          disabled={isActivating}
          color="primary"
          variant="contained"
          onClick={() => {
            if (
              currentDevice.deviceType === 'FSU' &&
              currentDevice.currentDeployments.length > 0 &&
              !deploymentWithFormattedSensors.isEnabled
            ) {
              setShowActivationModal(true);
              return;
            }
            if (
              currentDevice.deviceType === 'vFSU' &&
              currentDevice.currentDeployments.length >= 10 &&
              !deploymentWithFormattedSensors.isEnabled
            ) {
              enqueueSnackbar(
                'Failed to activate the deployment. This device has already reached its limit of simultaneous active deployments (10).',
                {
                  variant: 'error',
                }
              );
              return;
            }
            setIsActivating(true);
            toggleDeployment(deploymentWithFormattedSensors.id, {isEnabled: !deploymentWithFormattedSensors.isEnabled})
              .catch((error) => {
                enqueueSnackbar('Error updating deployment: ' + error.message, {variant: 'error'});
              })
              .finally(() => {
                setIsActivating(false);
                refresh();
              });
          }}>
          {deploymentWithFormattedSensors.isEnabled ? 'Deactivate' : 'Activate'}
        </Button>
      );
      return (
        <>
          <Grid item xs={12} md={6}>
            <TableContainer component={Paper} elevation={1}>
              <Table size="small">
                <TableHead>
                  <TableRow
                    sx={{
                      height: 60,
                    }}>
                    <TableCell>
                      <Typography
                        sx={{
                          fontWeight: 600,
                        }}>
                        Properties
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="edit deployment"
                        onClick={(event) => {
                          setShowEditModal(true);
                        }}
                        size="large">
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="delete deployment" onClick={() => setShowDeleteModal(true)} size="large">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key="Name"
                    sx={{
                      height: 60,
                    }}>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">{deploymentWithFormattedSensors.name}</TableCell>
                  </TableRow>
                  <TableRow
                    key="Position"
                    sx={{
                      height: 60,
                    }}>
                    <TableCell align="left">View</TableCell>
                    <TableCell align="left">{deploymentWithFormattedSensors.sensorView}</TableCell>
                  </TableRow>
                  <TableRow
                    key="Status"
                    sx={{
                      height: 60,
                    }}>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">
                      {deploymentWithFormattedSensors.isEnabled
                        ? deploymentWithFormattedSensors.runtimeStatus.charAt(0) +
                          deploymentWithFormattedSensors.runtimeStatus.slice(1).toLowerCase()
                        : 'Inactive'}
                      {deploymentWithFormattedSensors.jcoreConfigUpdateStatusSuccessful === false && (
                        <Alert severity="error">{deploymentWithFormattedSensors.jcoreConfigUpdateStatusMessage}</Alert>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow key="activate">
                    <TableCell
                      colSpan="2"
                      sx={{
                        height: 60,
                        textAlign: 'center',
                      }}>
                      {toggleDeploymentButton}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <DeploymentConfig
              deployment={deploymentWithFormattedSensors}
              onSaveComplete={() => {
                refresh();
              }}
            />
          </Grid>
        </>
      );
    } else if (isLoading) {
      return (
        <Grid item xs={12}>
          <InformationalLoader message="Loading deployment..." />
        </Grid>
      );
    }

    return (
      <Grid item xs={12}>
        <Alert severity="error">Cannot load this deployment!</Alert>
      </Grid>
    );
  }, [
    transformSensorsToOldFormat,
    deployment,
    errorCode,
    isLoading,
    isActivating,
    currentDevice.deviceType,
    currentDevice.currentDeployments.length,
    enqueueSnackbar,
    refresh,
  ]);

  if (!deployment || !currentDevice) {
    return <>This deployment does not exist</>;
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item container xs={12} md={12} alignItems="center">
          <Typography
            variant="h4"
            sx={{
              mr: 1,
            }}>
            {deployment.name}
          </Typography>
          <HeaderButtonRefresh
            isLoading={isLoading}
            onClick={(event) => {
              refresh();
            }}
          />
        </Grid>
        {mainContent()}
        <Grid item xs={12}>
          <DebugItem name={'Deployment'} item={deployment} />
        </Grid>
      </Grid>

      <Modal
        isOpen={showDeleteModal}
        onClose={(event) => {
          setShowDeleteModal(false);
        }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Alert severity="warning">Warning: Potential destructive action!</Alert>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua.
            </p>
            <p>
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
              pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
              id est laborum.
            </p>
            {deleteErrorMessage && <Alert severity="error">{deleteErrorMessage}</Alert>}
          </Grid>
          <Grid item xs={12} align="right">
            <HeaderButtonDelete
              onClick={() => doDelete()}
              mr={3}
              size="default"
              startIcon={isDeleting ? <CircularProgress size={18} /> : <DeleteIcon />}>
              Delete anyway
            </HeaderButtonDelete>
            <Button
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={() => {
                setShowDeleteModal(false);
              }}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Modal>

      <Modal
        isOpen={showActivationModal}
        onClose={(event) => {
          setShowActivationModal(false);
        }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Alert severity="warning">Warning: Potential destructive action!</Alert>
            <p>
              This device has already reached its limit of simultaneous active deployments (1). Activating this
              deployment will deactivate all other deployments on this device. Are you sure you want to activate this
              deployment?
            </p>
            {deleteErrorMessage && <Alert severity="error">{deleteErrorMessage}</Alert>}
          </Grid>
          <Grid item xs={12} align="right">
            <Button
              startIcon={isActivating && <CircularProgress size={18} />}
              disabled={isActivating}
              mr={3}
              color="primary"
              variant="contained"
              onClick={() => {
                setIsActivating(true);
                toggleDeployment(currentDevice.currentDeployments[0].id, {isEnabled: false})
                  .then(() => toggleDeployment(deployment.id, {isEnabled: true}))
                  .catch((error) => {
                    enqueueSnackbar('Error updating deployment: ' + error.response.data.message, {variant: 'error'});
                  })
                  .finally(() => {
                    setShowActivationModal(false);
                    setIsActivating(false);
                    refresh();
                  });
              }}>
              Activate
            </Button>
            <Button
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={() => {
                setShowActivationModal(false);
              }}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        isOpen={showEditModal}
        onClose={(event) => {
          setShowEditModal(false);
        }}>
        <EditDeploymentForm
          deployment={deployment}
          onClose={(event) => {
            setShowEditModal(false);
          }}
        />
      </Modal>
    </>
  );
}

export default withAuthorization(Conditions.ANY)(DeploymentPage);
