import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import {setDashboardPeriod} from 'redux/dashboard.reducer';

const PeriodArrow = ({direction, updateChart, isDisabled}) => {
  const dispatch = useDispatch();
  const dashboardSettings = useSelector((state) => state.dashboard.settings);
  const handleClick = useCallback(() => {
    let newPeriod;
    if (direction === 'back') {
      newPeriod =
        dashboardSettings.period && moment(dashboardSettings.period).isValid()
          ? moment(dashboardSettings.period).subtract(1, dashboardSettings.view + 's')
          : moment();
    } else {
      newPeriod = moment(dashboardSettings.period).isSame(new Date(), dashboardSettings.view)
        ? null // Last 24h/Last 7 days/Last 4 weeks/Last 365 days
        : moment(dashboardSettings.period).add(1, dashboardSettings.view + 's');
    }
    const dashboardSettingsClone = {...dashboardSettings};
    dashboardSettingsClone.period = newPeriod;
    dispatch(setDashboardPeriod(newPeriod));
    updateChart(dashboardSettingsClone);
  }, [direction, dashboardSettings, dispatch, updateChart]);

  return (
    <IconButton
      key={direction}
      aria-label={'date ' + direction}
      size="small"
      disabled={direction === 'back' ? isDisabled : !dashboardSettings.period || isDisabled}
      onClick={handleClick}>
      {direction === 'back' ? <ArrowLeftIcon /> : <ArrowRightIcon />}
    </IconButton>
  );
};

export default React.memo(PeriodArrow);
