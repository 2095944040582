import React from 'react';
import {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {clearDashboardSettings} from 'redux/dashboard.reducer';

import {withAuthHandler} from '../../oauth/index';
import {setDeploymentsList} from '../../redux/deployments.reducer';
import {setDevicesList, setNewDevices} from '../../redux/devices.reducer';
import {setOrganizations} from '../../redux/organizations.reducer';
import {setCurrentOrganization as dispatchSetCurrentOrganization} from '../../redux/session';

function OrganizationSelector() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const currentOrganization = useSelector((state) => state.session.currentOrganization);
  const currentOrganizationId = currentOrganization ? currentOrganization.id : '?';
  const organizations = useSelector((state) =>
    state.credentials
      ? state.credentials.organizations.sort((a, b) => {
          return a.name === b.name ? 0 : a.name > b.name ? 1 : -1;
        })
      : []
  );

  const organizationsDropdown = useMemo(
    () =>
      organizations.map((org) => {
        return (
          <MenuItem value={org.id} key={org.id}>
            {org.name}
          </MenuItem>
        );
      }),
    [organizations]
  );

  const handleOrganizationChange = (event) => {
    const organization = organizations.filter((org) => org.id === event.target.value);
    if (organization.length) {
      const newOrganizationId = organization[0].id;
      const newOrganizationName = organization[0].name;
      dispatch(dispatchSetCurrentOrganization(newOrganizationId, newOrganizationName));

      dispatch(setDevicesList([]));
      dispatch(setDeploymentsList([]));
      dispatch(setNewDevices([]));
      dispatch(setOrganizations([]));
      dispatch(clearDashboardSettings());
      const devicePages = /\/devices|\/deployments/.test(location.pathname);
      if (devicePages) {
        history.push('/devices');
      }
    }
  };

  return (
    <FormControl variant="standard" sx={{m: 1, height: 1}}>
      <Select
        sx={{
          color: 'primary.contrast',
          '.MuiSelect-icon': {
            color: 'primary.contrast',
          },
        }}
        aria-label="select project"
        id="select-organization-select"
        IconComponent={KeyboardArrowDownIcon}
        disableUnderline
        value={currentOrganizationId}
        autoWidth={false}
        onChange={handleOrganizationChange}>
        {organizationsDropdown}
      </Select>
    </FormControl>
  );
}

export default withAuthHandler(OrganizationSelector);
