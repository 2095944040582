import {API} from './api';

export async function getCameras() {
  return API.get('/cameras')
    .then((response) => {
      return response.data.data.sort((a, b) => (a.name > b.name ? 1 : -1));
    })
    .catch(function (error) {
      throw error;
    });
}

export async function getCamera(cameraId) {
  return API.get('/cameras/' + cameraId)
    .then((response) => {
      return response.data.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export async function createCamera(camera) {
  return API.post('/cameras', camera)
    .then((response) => {
      return true;
    })
    .catch(function (error) {
      throw error;
    });
}

export async function updateCamera(id, payload) {
  return API.patch('/cameras/' + id, payload)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export async function deleteCamera(id) {
  return API.delete('/cameras/' + id)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}
