import {API} from './api';
import {deploymentsMock, mockedResponse, uuidv4} from './mock';

const useMock = false;

const mockJCoreConfig = {
  configSchemaVersion: '1.0',
  cameraId: 'None',
  version: '1',
  cameraUri: 'None',
  initializerClassName: 'io.flowity.jcoreconfigs.v1.JetsonV1',
  initializerArguments: '',
  rotateVideoFrameDegrees: 0,
  output: {
    Flowity: {
      sendIntervallMillis: 10000,
    },
  },
  masks: {
    detectionMasks: [],
    noNewTracksMasks: [],
  },
  functions: {
    areaDetect: [],
    footfalls: [],
  },
  trackClasses: ['person'],
  detectClasses: ['person', 'car'],
};

export async function getDeployments() {
  if (useMock) {
    return mockedResponse(deploymentsMock.data);
  }
  return API.get('/deployments')
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
}
export async function addDeployment(deviceId) {
  if (useMock) {
    const id = uuidv4();
    const deployment = {
      id,
      runtimeStatus: 'NEEDS_CONFIG',
      tag: 'flowity-deployment',
      name: 'Deployment-' + id.substr(0, 6),
      deviceId,
      sensorView: 'Test view',
      jcoreConfig: mockJCoreConfig,
    };
    deploymentsMock.data.push(deployment);
    return mockedResponse(deployment);
  }
  const newDeployment = {
    deviceId,
    tag: 'flowity-deployment',
    sensorView: '',
    name: 'MyDeployment',
  };

  return API.post('/deployments', newDeployment)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
export async function deleteDeployment(deploymentId) {
  if (useMock) {
    deploymentsMock.data = deploymentsMock.data.filter((d) => d.id !== deploymentId);
    return mockedResponse({});
  }
  return API.delete('/deployments/' + deploymentId)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
}
export async function deactivateDeployment(deploymentId) {
  if (useMock) {
    deploymentsMock.data.filter((d) => d.id === deploymentId).forEach((d) => (d.runtimeStatus = 'PENDING'));
    return mockedResponse({});
  }
  return API.post('/deployments/' + deploymentId + '/deactivate')
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
}
export async function toggleDeployment(deploymentId, isEnabled) {
  return API.patch('/deployments/' + deploymentId, isEnabled)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function setJcoreConfig(deploymentId, jcoreConfig, savedConfigVersion, cameraId) {
  if (useMock) {
    deploymentsMock.data.filter((d) => d.id === deploymentId).forEach((d) => (d.jcoreConfig = jcoreConfig));
    return mockedResponse({});
  }
  return API.patch('/deployments/' + deploymentId, {cameraId, savedConfigVersion, jcoreConfig})
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function patchDeployment(deploymentId, payload) {
  return API.patch('/deployments/' + deploymentId, payload)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function takePicture(deploymentId) {
  if (useMock) {
    return mockedResponse({});
  }
  return API.post('/deployments/' + deploymentId + '/take_picture')
    .then((response) => {
      return true;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getPictureStatus(deploymentId) {
  if (useMock) {
    return mockedResponse({});
  }
  return API.get('/deployments/' + deploymentId + '/picture_status')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getPicture(deploymentId) {
  if (useMock) {
    return mockedResponse({});
  }
  return API.get('/deployments/' + deploymentId + '/picture')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
