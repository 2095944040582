import log from 'loglevel';

const initialState = {
  loggedIn: null,
  accessToken: null,
  tokenExpirationTime: null,
  account: {name: ''},
  currentOrganization: {name: '?', id: ''},
};

export const setAccessToken = (accessToken) => ({
  type: 'SET_ACCESS_TOKEN',
  payload: accessToken,
});

export const setAccessTokenExpiration = (tokenExpirationTime) => ({
  type: 'SET_ACCESS_TOKEN_EXPIRATION',
  payload: tokenExpirationTime,
});

export const setLoggedIn = (loggedIn) => ({
  type: 'SET_LOGGED_IN',
  payload: loggedIn,
});

export const setAccount = (account) => ({
  type: 'SET_ACCOUNT',
  payload: account,
});

export const setCurrentOrganization = (id, name) => ({
  type: 'SET_CURRENT_ORGANIZATION',
  payload: {id, name},
});

export const setLogOut = (logoutState) => ({
  type: 'SET_LOG_OUT',
  payload: logoutState,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_ACCESS_TOKEN':
      log.info('Setting access token: ' + JSON.stringify(action.payload));
      return {
        ...state,
        accessToken: action.payload,
      };
    case 'SET_ACCESS_TOKEN_EXPIRATION':
      return {
        ...state,
        tokenExpirationTime: action.payload,
      };
    case 'SET_LOGGED_IN':
      return {
        ...state,
        loggedIn: action.payload,
      };
    case 'SET_CURRENT_ORGANIZATION':
      return {
        ...state,
        currentOrganization: action.payload,
      };
    case 'SET_ACCOUNT':
      return {
        ...state,
        account: action.payload,
      };
    case 'SET_LOG_OUT':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
