import React, {useEffect, useState} from 'react';
import {Trans} from '@lingui/macro';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import log from 'loglevel';

function CustomTable(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const getDataFunction = props.dataSource;
  const showRefresh = props.showRefresh;

  const getData = () => {
    setLoading(true);
    getDataFunction()
      .then((response) => {
        // console.log('Got data: ' + JSON.stringify(response));
        setLoading(false);
        if (response !== undefined && response.data !== undefined) {
          setData(response.data);
        } else {
          setError({message: 'Something went wrong'});
        }
      })
      .catch((error) => {
        log.error('Error getting table data');
        setLoading(false);
        setError(error);
      });
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line

  const {tableHeaders, tableRows} = props;
  return (
    <TableContainer
      sx={{
        padding: 2,
        overflow: 'auto',
        flexDirection: 'column',
      }}
      component={Paper}>
      {!!showRefresh && (
        <Toolbar>
          <IconButton
            aria-label="refresh"
            color="primary"
            sx={{
              marginLeft: 'auto',
            }}
            onClick={getData}
            size="large">
            <AutorenewIcon />
          </IconButton>
        </Toolbar>
      )}
      <Table
        sx={{
          minWidth: 650,
        }}
        size="small"
        aria-label="simple table">
        <TableHead>
          <TableRow>{tableHeaders}</TableRow>
        </TableHead>
        <TableBody>{!loading && !data.length ? null : tableRows(data)}</TableBody>
      </Table>
      {loading && <LinearProgress />}
      {!loading && !error && !data.length && (
        <Typography align="center" variant="h3">
          <Trans>No items</Trans>
        </Typography>
      )}
      {!loading && error && (
        <Typography align="center" variant="h3">
          {error.message}
        </Typography>
      )}
    </TableContainer>
  );
}

export default CustomTable;
