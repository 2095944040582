import log from 'loglevel';

import {API} from './api';
import {mockedResponse} from './mock';

const useMock = false;
const mockedCredentials = {
  id: '08dafdc2-02f3-4a24-96d0-faccf2c0b55f',
  azureId: '3216bfa5-1faf-4b2a-9b2d-ae21381fd819',
  userTypes: ['FLOWITY_USER'],
  organizations: [
    {
      id: '7a9d7c95-f7d1-4385-b3e3-a8f4e26fdf5a',
      name: 'AFRY-Göteborg-office1',
      roles: [{id: '7c6c4742-5117-11ec-bf63-0242ac130002', name: 'ADMIN'}],
    },
    {
      id: '55a4b2ac-8b03-465d-8ab8-53fa58841270',
      name: 'Flowity Staging',
      roles: [{id: '7c6c4742-5117-11ec-bf63-0242ac130002', name: 'ADMIN'}],
    },
    {
      id: '27ce7a96-ee40-4923-9625-8a2ca47eff6e',
      name: 'AFRY-Göteborg-office2',
      roles: [{id: '7c6c4742-5117-11ec-bf63-0242ac130002', name: 'ADMIN'}],
    },
  ],
};
export async function getCredentials() {
  if (useMock) {
    return mockedResponse(mockedCredentials);
  }
  return API.get('/credentials')
    .then((response) => {
      // console.log('Returning credentials: ' + JSON.stringify(response.data));
      // TODO check status code and validate that there's data
      return response.data;
    })
    .catch(function (error) {
      log.error('fetch error ' + error);
      throw error;
    });
}
