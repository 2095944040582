import React, {useCallback, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {deleteCamera, getCamera, updateCamera} from 'api/cameras';
import {useSnackbar} from 'notistack';

import FlexiblePaper from 'components/FlexiblePaper';

import DebugItem from '../../components/DebugItem';
import {HeaderButtonDelete, HeaderButtonRefresh} from '../../components/HeaderButtons';
import InformationalLoader from '../../components/Progress/InformationalLoader';
import {withAuthorization} from '../../oauth/Session';
import * as Conditions from '../../routes/Conditions';

const cameraUriRegex = new RegExp(/(rtsps?):\/\/([^\s@/]+):([^\s@/]+)@([^\s/:]+)(?::([0-9]+))?(\/.*)/gm);

function CameraPage(props) {
  const {did} = useParams('did');
  const history = useHistory();
  const {enqueueSnackbar} = useSnackbar();

  const [isLoadingCameraData, setIsLoadingCameraData] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [cameraData, setCameraData] = useState();
  const [isValidCameraUri, setIsValidCameraUri] = useState(true);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [cameraModel, setCameraModel] = useState('');
  const [cameraUri, setCameraUri] = useState('');
  const [fisheye, setFisheye] = useState(false);
  const [fisheyeStartAngle, setFisheyeStartAngle] = useState(0);
  const [rotateVideoFrameDegrees, setRotateVideoFrameDegrees] = useState(0);

  const handleCameraUriChange = useCallback((event) => {
    setIsValidCameraUri(cameraUriRegex.test(event.target.value));
    setCameraUri(event.target.value);
  }, []);

  const handleFisheyeChange = useCallback((event) => setFisheye(event.target.checked), []);

  const handleUpdateCamera = useCallback(() => {
    setIsProcessing(true);
    updateCamera(cameraData.id, {
      name,
      description,
      cameraModel,
      cameraUri,
      fisheye,
      fisheyeStartAngle,
      rotateVideoFrameDegrees,
    })
      .then(() => {
        enqueueSnackbar(`Camera updated successfully!`, {
          variant: 'success',
        });
      })
      .catch((e) => {
        enqueueSnackbar('Error updating camera: ' + e.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsProcessing(false);
      });
  }, [
    cameraData,
    cameraModel,
    cameraUri,
    description,
    enqueueSnackbar,
    fisheye,
    fisheyeStartAngle,
    name,
    rotateVideoFrameDegrees,
  ]);

  const handleDeleteCamera = useCallback(() => {
    setIsProcessing(true);
    deleteCamera(cameraData.id)
      .then(() => {
        enqueueSnackbar(`Camera deleted successfully!`, {
          variant: 'success',
        });
        history.push('/devices');
      })
      .catch((e) => {
        enqueueSnackbar('Error deleting camera: ' + e.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsProcessing(false);
      });
  }, [cameraData, enqueueSnackbar, history]);

  const fetchCameraData = useCallback(() => {
    setIsLoadingCameraData(true);
    getCamera(did)
      .then((data) => {
        setCameraData(data[0]);
        setName(data[0].name || '');
        setDescription(data[0].description || '');
        setCameraModel(data[0].cameraModel || '');
        setCameraUri(data[0].cameraUri || '');
        setFisheye(data[0].fisheye || false);
        setFisheyeStartAngle(data[0].fisheyeStartAngle || 0);
        setRotateVideoFrameDegrees(data[0].rotateVideoFrameDegrees || 0);
      })
      .catch((error) => enqueueSnackbar('Error getting camera data: ' + error.message, {variant: 'error'}))
      .finally(() => setIsLoadingCameraData(false));
  }, [did, enqueueSnackbar]);

  useEffect(() => {
    fetchCameraData();
  }, [fetchCameraData]);

  const useMainContent = useCallback(() => {
    if (cameraData) {
      return (
        <>
          <Grid item xs={12}>
            <FlexiblePaper>
              <Grid container spacing={3}>
                <Grid item container xs={12}>
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h4">Settings</Typography>
                  </Grid>
                  <Grid item xs={12} sm={9} align="right">
                    <HeaderButtonDelete
                      onClick={() => handleDeleteCamera()}
                      size="default"
                      disabled={isProcessing}
                      startIcon={isProcessing ? <CircularProgress size={18} /> : <DeleteIcon />}>
                      Delete
                    </HeaderButtonDelete>
                    <Button
                      variant="contained"
                      color="primary"
                      ml={2}
                      disabled={!name || !cameraUri || !isValidCameraUri || isProcessing}
                      startIcon={isProcessing && <CircularProgress color="secondary" size={18} />}
                      onClick={(e) => handleUpdateCamera()}>
                      Save
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{minWidth: {xs: 200, sm: 300}}}>
                    <TextField
                      label="Camera name"
                      aria-describedby="camera-name-helper-text"
                      variant="filled"
                      value={name}
                      required
                      autoFocus
                      type="text"
                      size="small"
                      inputProps={{maxLength: 127}}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{minWidth: {xs: 200, sm: 300}}}>
                    <TextField
                      variant="filled"
                      size="small"
                      required
                      error={!isValidCameraUri}
                      label="Camera URI"
                      value={cameraUri}
                      type="text"
                      inputProps={{maxLength: 511}}
                      onChange={handleCameraUriChange}
                      helperText={
                        isValidCameraUri
                          ? 'Network camera RTSP URL'
                          : 'Must be a valid rtsp/rtsps url with a username and a password'
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{minWidth: {xs: 200, sm: 300}}}>
                    <TextField
                      label="Camera description"
                      aria-describedby="camera-description-helper-text"
                      variant="filled"
                      value={description}
                      type="text"
                      size="small"
                      inputProps={{maxLength: 255}}
                      onChange={(event) => {
                        setDescription(event.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{minWidth: {xs: 200, sm: 300}}}>
                    <TextField
                      label="Model"
                      aria-describedby="model-helper-text"
                      variant="filled"
                      value={cameraModel}
                      type="text"
                      size="small"
                      inputProps={{maxLength: 255}}
                      onChange={(event) => {
                        setCameraModel(event.target.value);
                      }}
                    />
                    <FormHelperText id="model-helper-text">Model of the camera</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    sx={{
                      ml: 0,
                    }}
                    onChange={handleFisheyeChange}
                    checked={!!fisheye}
                    control={<Checkbox />}
                    label="Fisheye camera"
                    labelPlacement="start"
                  />
                </Grid>
                {fisheye && (
                  <Grid item xs={12}>
                    <FormControl sx={{minWidth: {xs: 200, sm: 300}}}>
                      <TextField
                        label="Fisheye start angle"
                        aria-describedby="fisheye-start-angle-helper-text"
                        variant="filled"
                        value={fisheyeStartAngle}
                        type="number"
                        size="small"
                        InputProps={{inputProps: {min: 0, max: 360}}}
                        onChange={(event) => {
                          setFisheyeStartAngle(event.target.value);
                        }}
                      />
                      <FormHelperText id="fisheye-start-angle-helper-text">
                        Starting angle of fisheye field of view
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography variant="h5">Camera Rotation</Typography>
                  <FormControl fullWidth>
                    <RadioGroup
                      name="camera_rotation"
                      value={rotateVideoFrameDegrees}
                      onChange={(event) => setRotateVideoFrameDegrees(parseInt(event.target.value))}>
                      <FormControlLabel value="0" control={<Radio />} label="Normal" />
                      <FormControlLabel value="180" control={<Radio />} label="Upside down" />
                    </RadioGroup>
                  </FormControl>
                  <Alert severity="info" sx={{maxWidth: '500px'}}>
                    If you change the camera rotation setting, you will have to request a new camera image and also
                    redraw the lines and areas in the <i>spatial configuration</i> of related deployments. It is
                    therefore importat to chose the correct camera rotation and save <b>before doing other actions</b>{' '}
                    that depend on the camera image.
                  </Alert>
                </Grid>
                <Grid item xs={12} align="right">
                  <HeaderButtonDelete
                    onClick={() => handleDeleteCamera()}
                    size="default"
                    disabled={isProcessing}
                    startIcon={isProcessing ? <CircularProgress size={18} /> : <DeleteIcon />}>
                    Delete
                  </HeaderButtonDelete>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    ml={2}
                    disabled={!name || !cameraUri || !isValidCameraUri || isProcessing}
                    startIcon={isProcessing && <CircularProgress color="secondary" size={18} />}
                    onClick={(e) => handleUpdateCamera()}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </FlexiblePaper>
          </Grid>
        </>
      );
    }

    if (isLoadingCameraData) {
      return (
        <Grid item xs={12}>
          <InformationalLoader message="Loading camera..." />
        </Grid>
      );
    }

    return (
      <Grid item xs={12}>
        <Alert severity="error">No camera data available.</Alert>
      </Grid>
    );
  }, [
    cameraData,
    isLoadingCameraData,
    isProcessing,
    name,
    cameraUri,
    isValidCameraUri,
    handleCameraUriChange,
    description,
    cameraModel,
    rotateVideoFrameDegrees,
    handleFisheyeChange,
    fisheye,
    fisheyeStartAngle,
    handleDeleteCamera,
    handleUpdateCamera,
  ]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item container xs={12} md={12} alignItems="center">
          <Typography
            variant="h4"
            sx={{
              mr: 2,
            }}>
            Camera details
          </Typography>
          <HeaderButtonRefresh
            variant="outlined"
            isLoading={isLoadingCameraData || isProcessing}
            onClick={(event) => {
              fetchCameraData();
            }}
          />
        </Grid>
        {useMainContent()}
      </Grid>
      <DebugItem item={cameraData} name={'Camera'} />
    </>
  );
}

export default withAuthorization(Conditions.ANY)(CameraPage);
