import React from 'react';
import {useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Modal from '@mui/material/Modal';

const AuthHandlerContext = React.createContext(null);

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const RedirectToLogin = (props) => {
  const {authHandler} = props;
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  setTimeout(() => {
    authHandler.checkIfUserIsLoggedInOrLogInUser();
  }, 2000);

  const body = (
    <Box
      style={modalStyle}
      sx={{
        position: 'absolute',
        width: 400,
        backgroundColor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 5,
        pt: 2,
        pr: 4,
        pl: 4,
        pb: 3,
      }}>
      <h2 id="not-logged-in-title">Not logged in...</h2>
      <p>You are not logged in, please wait to be redirected to the login page...</p>
      <LinearProgress />
    </Box>
  );

  // TODO, add another text for getting if we are logged in but waiting for credentials.

  return (
    <Modal
      open={true}
      aria-labelledby="redirect-login-modal"
      BackdropProps={{
        style: {
          backgroundColor: 'white',
        },
      }}>
      {body}
    </Modal>
  );
};

export const withAuthHandler = (Component) => (props) => {
  const loggedIn = useSelector((state) => state.session.loggedIn);
  return (
    <AuthHandlerContext.Consumer>
      {(authHandler) =>
        loggedIn ? <Component {...props} authHandler={authHandler} /> : <RedirectToLogin authHandler={authHandler} />
      }
    </AuthHandlerContext.Consumer>
  );
};

export default AuthHandlerContext;
