import React, {useCallback, useEffect, useState} from 'react';
import {FormControl, FormHelperText, TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import {getDetectionModels} from 'api/detectionModels';
import {useSnackbar} from 'notistack';

const DetectionClassesConfig = ({editableConfig, updateFromObject}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [detectionModels, setDetectionModels] = useState(null);
  const [detectionClasses, setDetectionClasses] = useState([]);
  const [selectedModel, setSelectedModel] = useState(editableConfig.detectionModel || '0');
  const [selectedDetectionClasses, setSelectedDetectionClasses] = useState(editableConfig.detectClasses || []);

  const handleModelChange = useCallback(
    (event) => {
      setSelectedModel(event.target.value);
      if (event.target.value === '0') {
        setDetectionClasses([]);
        delete editableConfig.detectionModel;
        delete editableConfig.trackClasses;
        delete editableConfig.detectClasses;
      } else {
        const modelObject = detectionModels.find((m) => m.name === event.target.value);
        setDetectionClasses(modelObject.classes);
        editableConfig.detectionModel = modelObject.name;
      }
      setSelectedDetectionClasses([]);
      updateFromObject({...editableConfig});
    },
    [detectionModels, editableConfig, updateFromObject]
  );

  const handleClassChange = useCallback(
    (event, newValue) => {
      setSelectedDetectionClasses(newValue);
      editableConfig.trackClasses = newValue;
      editableConfig.detectClasses = newValue;
      updateFromObject({...editableConfig});
    },
    [editableConfig, updateFromObject]
  );

  useEffect(() => {
    if (!detectionModels) {
      getDetectionModels()
        .then((response) => {
          setDetectionModels(response);
          if (editableConfig.detectionModel) {
            setDetectionClasses(response.find((m) => m.name === editableConfig.detectionModel).classes);
          }
        })
        .catch((error) => {
          enqueueSnackbar('Error getting detection models: ' + error.message, {variant: 'error'});
        });
    }
  }, [detectionModels, editableConfig.detectionModel, enqueueSnackbar]);

  if (!detectionModels) return <></>;

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Detection Classes</Typography>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={6}>
          <FormControl variant="standard" fullWidth>
            <InputLabel id="select-detection-model-label">Detection model</InputLabel>
            <Select
              labelId="select-detection-model-label"
              id="select-detection-model"
              value={selectedModel}
              onChange={handleModelChange}
              label="Detection model">
              <MenuItem value="0" key="0">
                None
              </MenuItem>
              {detectionModels.map((m) => (
                <MenuItem value={m.name} key={m.id}>
                  {m.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormHelperText id="select-detection-model">
            A different set of detection classes may be available in different models
          </FormHelperText>
        </Grid>
      </Grid>
      {selectedModel !== '0' && (
        <Grid item container xs={12}>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              id="detection-classes"
              options={detectionClasses}
              value={selectedDetectionClasses}
              onChange={handleClassChange}
              renderInput={(params) => (
                <>
                  <TextField {...params} variant="standard" label="Detection classes" placeholder="Detection classes" />
                  <FormHelperText id="helper-sendIntervallMillis">
                    What should the Flowity sensor detect?
                  </FormHelperText>
                </>
              )}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default React.memo(DetectionClassesConfig);
