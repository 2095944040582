import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {FormControl, FormHelperText} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import {getOrganizations} from 'api/organizations';
import {useSnackbar} from 'notistack';
import {setOrganizations} from 'redux/organizations.reducer';

import * as devices from '../../api/devices';
import Button from '../../components/Button';

const AssignDeviceForm = ({device, refresh, onClose}) => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const organizations = useSelector((state) => state.organizations.list);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState('');

  const assignDevice = () => {
    if (device.id && device.id.length > 0) {
      setIsProcessing(true);
      devices
        .assignDevice(device.id, selectedOrganizationId)
        .then(() => {
          enqueueSnackbar(`Device ${device.name} successfully assigned! It can be configured on the Devices page.`, {
            variant: 'success',
          });
          refresh();
          onClose();
        })
        .catch((e) => {
          enqueueSnackbar('Error assigning device: ' + e.message, {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsProcessing(false);
        });
    }
  };

  useEffect(() => {
    if (organizations.length === 0) {
      getOrganizations()
        .then((response) => {
          dispatch(setOrganizations(response));
        })
        .catch((e) => {
          enqueueSnackbar('Error fetching organizations: ' + e.message, {
            variant: 'error',
          });
        });
    }
  }, [dispatch, enqueueSnackbar, organizations.length]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        assignDevice();
      }}
      method="post">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">Assign</Typography>
          <div>Name: {device.name}</div>
          <div>Serial Number: {device.serialNumber}</div>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="assign-input-label">Organization</InputLabel>
            <Select
              labelId="assign-input-label"
              id="assign-input"
              aria-describedby="assign-helper-text"
              value={selectedOrganizationId}
              onChange={(e) => {
                setSelectedOrganizationId(e.target.value);
              }}>
              {organizations.map((organization) => (
                <MenuItem value={organization.id} key={organization.id}>
                  {organization.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText id="assign-helper-text">
              Select the organization you wish to assign this device to.
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} align="right">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={isProcessing ? <CircularProgress color="secondary" size={18} /> : <SaveIcon />}
            mr={2}
            onClick={(e) => {
              e.preventDefault();
              assignDevice();
            }}>
            Assign
          </Button>
          <Button
            variant="contained"
            startIcon={<CloseIcon />}
            onClick={() => {
              onClose();
            }}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AssignDeviceForm;
