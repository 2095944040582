import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import DescriptionIcon from '@mui/icons-material/Description';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import StraightenIcon from '@mui/icons-material/Straighten';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';

// import ShowChartIcon from '@mui/icons-material/ShowChart';
import {RolesContext} from '../../oauth/Session';
import * as Conditions from '../../routes/Conditions';
import * as ROUTES from '../../routes/Routes';

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
  },
  linkItem: {
    textDecoration: 'none',
    color: 'inherit',
  },
  nested: {
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  nested2: {
    paddingLeft: theme.spacing(2),
    backgroundColor: 'darkgrey',
    paddingBottom: '4px',
    paddingTop: '4px',
  },
}));

export default function ProjectListItems() {
  const classes = useStyles();
  const drawerOpen = useSelector((state) => state.ui.drawer.drawerOpen);
  const {roles} = useContext(RolesContext);

  return (
    roles.includes(Conditions.SUPER_USER) && (
      <>
        <Link to={ROUTES.GRAPHICALREPORT} className={classes.linkItem}>
          <Tooltip title="Playback" placement="right" disableHoverListener={drawerOpen}>
            <ListItem button>
              <ListItemIcon>
                <VideoCameraBackIcon />
              </ListItemIcon>
              <ListItemText primary="Playback" />
            </ListItem>
          </Tooltip>
        </Link>
        <Link to={ROUTES.DATAREPORT} className={classes.linkItem}>
          <Tooltip title="File export" placement="right" disableHoverListener={drawerOpen}>
            <ListItem button>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="File export" />
            </ListItem>
          </Tooltip>
        </Link>
        <Link to={ROUTES.MEASUREMENTS} className={classes.linkItem}>
          <Tooltip title="Measurements" placement="right" disableHoverListener={drawerOpen}>
            <ListItem button>
              <ListItemIcon>
                <StraightenIcon />
              </ListItemIcon>
              <ListItemText primary="Measurements" />
            </ListItem>
          </Tooltip>
        </Link>
        <Link to={ROUTES.DEVICES} className={classes.linkItem}>
          <Tooltip title="Hardware" placement="right" disableHoverListener={drawerOpen}>
            <ListItem button>
              <ListItemIcon>
                <DeviceHubIcon />
              </ListItemIcon>
              <ListItemText primary="Hardware" />
            </ListItem>
          </Tooltip>
        </Link>
        {/*<Link to={ROUTES.PROJECT_SETTINGS} className={classes.linkItem}>*/}
        {/*  <Tooltip title={<Trans>Project Settings</Trans>} placement="right" disableHoverListener={drawerOpen}>*/}
        {/*    <ListItem button className={classes.nested}>*/}
        {/*      <ListItemIcon>*/}
        {/*        <SettingsIcon />*/}
        {/*      </ListItemIcon>*/}
        {/*      <ListItemText primary={<Trans>Project Settings</Trans>} />*/}
        {/*    </ListItem>*/}
        {/*  </Tooltip>*/}
        {/*</Link>*/}
      </>
    )
  );
}
