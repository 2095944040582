import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Trans} from '@lingui/macro';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {refreshCameras, refreshDeployments, refreshDevices} from 'helpers';
import {useSnackbar} from 'notistack';

import {HeaderButton, HeaderButtonRefresh} from '../../components/HeaderButtons';
import Modal from '../../components/Modal';
import {withAuthorization} from '../../oauth/Session';
import * as Conditions from '../../routes/Conditions';

import CreateMeasurementForm from './components/CreateMeasurementForm';
import MeasurementsTable from './components/MeasurementsTable';

const statusFilters = ['Online', 'Offline', 'Error'];

const MeasurementsPage = () => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();

  const isLoadingDevices = useSelector((state) => state.devices.isLoadingDevices);
  const isLoadingDeployments = useSelector((state) => state.deployments.isLoadingDeployments);

  const [deviceFilters, setDeviceFilters] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const refresh = useCallback(() => {
    refreshDevices(dispatch, enqueueSnackbar);
    refreshDeployments(dispatch, enqueueSnackbar);
    refreshCameras(dispatch, enqueueSnackbar);
  }, [dispatch, enqueueSnackbar]);

  return (
    <>
      <Grid container>
        <Grid item container xs={12} md={12} alignItems="center">
          <Typography
            variant="h4"
            sx={{
              mr: 2,
            }}>
            Measurements
          </Typography>
          <Button
            size="small"
            color="primary"
            variant="contained"
            sx={{
              height: 30,
              mr: 2,
            }}
            onClick={() => setIsModalOpen(true)}>
            New measurement
          </Button>
          <HeaderButtonRefresh
            isLoading={isLoadingDeployments || isLoadingDevices}
            onClick={(event) => {
              refresh();
            }}
          />
        </Grid>
        <Grid item container xs={12} md={12} alignItems="center" sx={{pt: 1}}>
          <Typography
            component="p"
            sx={{
              mr: 2,
              mt: 1,
            }}>
            Quick filters
          </Typography>
          {statusFilters.map((statusFilter) => (
            <Chip
              sx={{
                mr: 1,
                mt: 1,
              }}
              label={statusFilter}
              key={statusFilter}
              color={deviceFilters.includes(statusFilter) ? 'primary' : 'default'}
              onClick={() => {
                if (deviceFilters.includes(statusFilter)) {
                  const newDeviceFilters = deviceFilters.filter((status) => status !== statusFilter);
                  setDeviceFilters(newDeviceFilters);
                } else {
                  const newDeviceFilters = [statusFilter, ...deviceFilters];
                  setDeviceFilters(newDeviceFilters);
                }
              }}
            />
          ))}
          {deviceFilters.length > 0 && (
            <HeaderButton
              variant="outlined"
              startIcon={<CloseIcon />}
              sx={{
                mr: 1,
                mt: 1,
              }}
              onClick={(event) => {
                setDeviceFilters([]);
              }}>
              <Trans>Clear filters</Trans>
            </HeaderButton>
          )}
        </Grid>
        <Grid item xs={12} sx={{pt: 3}}>
          <MeasurementsTable deviceFilters={deviceFilters} />
        </Grid>
      </Grid>
      <Modal
        isOpen={isModalOpen}
        onClose={(event) => {
          setIsModalOpen(false);
        }}>
        <CreateMeasurementForm setIsModalOpen={setIsModalOpen} />
      </Modal>
    </>
  );
};

export default withAuthorization(Conditions.ANY)(MeasurementsPage);
