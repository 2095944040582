import React, {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {createCamera} from 'api/cameras';
import {useSnackbar} from 'notistack';

import Button from '../../components/Button';

const cameraUriRegex = new RegExp(/(rtsps?):\/\/([^\s@/]+):([^\s@/]+)@([^\s/:]+)(?::([0-9]+))?(\/.*)/gm);

const CreateCameraForm = ({onClose}) => {
  const {enqueueSnackbar} = useSnackbar();
  const currentOrganization = useSelector((state) => state.session.currentOrganization);

  const [isProcessing, setIsProcessing] = useState(false);
  const [isValidCameraUri, setIsValidCameraUri] = useState(true);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [cameraModel, setCameraModel] = useState('');
  const [cameraUri, setCameraUri] = useState('');
  const [fisheye, setFisheye] = useState(false);
  const [fisheyeStartAngle, setFisheyeStartAngle] = useState(0);
  const [rotateVideoFrameDegrees, setRotateVideoFrameDegrees] = useState(0);

  const handleCameraUriChange = useCallback((event) => {
    setIsValidCameraUri(cameraUriRegex.test(event.target.value));
    setCameraUri(event.target.value);
  }, []);

  const handleFisheyeChange = useCallback((event) => setFisheye(event.target.checked), []);

  const handleCreateCamera = useCallback(() => {
    if (isValidCameraUri) {
      setIsProcessing(true);
      createCamera({
        name,
        description,
        cameraModel,
        cameraUri,
        fisheye,
        fisheyeStartAngle,
        rotateVideoFrameDegrees,
        organization: currentOrganization.id,
      })
        .then(() => {
          enqueueSnackbar(`Camera created successfully!`, {
            variant: 'success',
          });
          onClose();
        })
        .catch((e) => {
          enqueueSnackbar('Error creating camera: ' + e.message, {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsProcessing(false);
        });
    }
  }, [
    cameraModel,
    cameraUri,
    currentOrganization,
    description,
    enqueueSnackbar,
    fisheye,
    fisheyeStartAngle,
    isValidCameraUri,
    name,
    onClose,
    rotateVideoFrameDegrees,
  ]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">Create Camera</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <TextField
            label="Camera name"
            aria-describedby="camera-name-helper-text"
            variant="filled"
            value={name}
            required
            autoFocus
            type="text"
            size="small"
            inputProps={{maxLength: 127}}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <TextField
            variant="filled"
            size="small"
            required
            error={!isValidCameraUri}
            label="Camera URI"
            value={cameraUri}
            type="text"
            inputProps={{maxLength: 511}}
            onChange={handleCameraUriChange}
            helperText={
              isValidCameraUri
                ? 'Network camera RTSP URL'
                : 'Must be a valid rtsp/rtsps url with a username and a password'
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <TextField
            label="Camera description"
            aria-describedby="camera-description-helper-text"
            variant="filled"
            value={description}
            type="text"
            size="small"
            inputProps={{maxLength: 255}}
            onChange={(event) => {
              setDescription(event.target.value);
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <TextField
            label="Model"
            aria-describedby="model-helper-text"
            variant="filled"
            value={cameraModel}
            type="text"
            size="small"
            inputProps={{maxLength: 255}}
            onChange={(event) => {
              setCameraModel(event.target.value);
            }}
          />
          <FormHelperText id="model-helper-text">Enter the model of the new camera</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          sx={{
            ml: 0,
          }}
          onChange={handleFisheyeChange}
          checked={!!fisheye}
          control={<Checkbox />}
          label="Fisheye camera"
          labelPlacement="start"
        />
      </Grid>
      {fisheye && (
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              label="Fisheye start angle"
              aria-describedby="fisheye-start-angle-helper-text"
              variant="filled"
              value={fisheyeStartAngle}
              type="number"
              size="small"
              InputProps={{inputProps: {min: 0, max: 360}}}
              onChange={(event) => {
                setFisheyeStartAngle(event.target.value);
              }}
            />
            <FormHelperText id="fisheye-start-angle-helper-text">
              Starting angle of fisheye field of view
            </FormHelperText>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="h5">Camera Rotation</Typography>
        <FormControl fullWidth>
          <RadioGroup
            name="camera_rotation"
            value={rotateVideoFrameDegrees}
            onChange={(event) => setRotateVideoFrameDegrees(parseInt(event.target.value))}>
            <FormControlLabel value="0" control={<Radio />} label="Normal" />
            <FormControlLabel value="180" control={<Radio />} label="Upside down" />
          </RadioGroup>
        </FormControl>
        <Alert severity="info">
          If you change the camera rotation setting in the future, you will have to request a new camera image and also
          redraw lines and areas in the <i>spatial configuration</i> of related deployments. It is therefore importat to
          chose the correct camera rotation <b>before doing other actions</b> that depend on the camera image.
        </Alert>
      </Grid>
      <Grid item xs={12} align="right">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!name || !cameraUri || !isValidCameraUri}
          startIcon={isProcessing ? <CircularProgress color="secondary" size={18} /> : <SaveIcon />}
          mr={2}
          onClick={(e) => handleCreateCamera()}>
          Create
        </Button>
        <Button
          variant="contained"
          startIcon={<CloseIcon />}
          onClick={(event) => {
            if (onClose) {
              onClose();
            }
          }}>
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

export default CreateCameraForm;
