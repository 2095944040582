import React from 'react';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';

const InformationalLoader = ({message}) => {
  return (
    <>
      <Alert severity="info">{message ? message : 'Loading...'}</Alert>
      <LinearProgress />
    </>
  );
};

export default InformationalLoader;
