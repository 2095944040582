import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormControl, InputLabel, ListItemText, MenuItem, Select} from '@mui/material';
import {setDashboardChartType} from 'redux/dashboard.reducer';

const ChartTypeSelector = ({isDisabled}) => {
  const dispatch = useDispatch();
  const dashboardSettingsChartType = useSelector((state) => state.dashboard.settings.chartType);

  const handleChartTypeChange = useCallback(
    (event) => {
      dispatch(setDashboardChartType(event.target.value));
    },
    [dispatch]
  );

  return (
    <FormControl variant="standard" sx={{minWidth: 100, marginRight: 2}}>
      <InputLabel id="chart-type-label">Chart type</InputLabel>
      <Select
        labelId="chart-type-label"
        id="chart-type-select"
        disabled={isDisabled}
        value={dashboardSettingsChartType}
        label="Chart type"
        onChange={handleChartTypeChange}>
        <MenuItem key="bar" value="bar">
          <ListItemText primary="Bar" />
        </MenuItem>
        <MenuItem key="line" value="line">
          <ListItemText primary="Line" />
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default React.memo(ChartTypeSelector);
