import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import {Trans} from '@lingui/macro';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const CameraTable = () => {
  const camerasList = useSelector((state) => state.cameras.camerasList);

  const cameraTableBody = useMemo(() => {
    return camerasList
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((camera) => (
        <TableRow
          key={camera.id}
          sx={{
            height: 75,
          }}>
          <TableCell align="left">{camera.name}</TableCell>
          <TableCell align="left">{camera.cameraModel || '-'}</TableCell>
          <TableCell align="left">{camera.fisheye ? 'yes' : 'no'}</TableCell>
          <TableCell align="right">
            <RouterLink to={'/cameras/' + camera.id} style={{textDecoration: 'none'}}>
              <Button variant="contained" color="primary" size="small">
                <Trans>Edit</Trans>
              </Button>
            </RouterLink>
          </TableCell>
        </TableRow>
      ));
  }, [camerasList]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TableContainer component={Paper} elevation={0}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Camera name</TableCell>
                  <TableCell align="left">Model</TableCell>
                  <TableCell align="left">Fisheye</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{cameraTableBody}</TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default CameraTable;
