import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, TextField} from '@mui/material';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {refreshDevices} from 'helpers';
import {cloneDeep} from 'lodash';
import log from 'loglevel';

import {patchDevice} from '../../../api/devices';
import Button from '../../../components/Button';
import {putDevice} from '../../../redux/devices.reducer';
const parseErrors = [];

const getConfigOrDefault = (config, path) => {
  let data = config;
  path.split('/').forEach((p) => {
    if (typeof data !== 'undefined') {
      data = data[p];
    }
  });
  if (typeof data === 'undefined') {
    const message = `Cannot extract value ${path}`;
    parseErrors.push(message);
    log.warn(message, config);
  }
  return data;
};

const EditDeviceForm = ({device, onClose}) => {
  parseErrors.length = 0;
  const config = device.deviceConfig;
  const [name, setName] = useState(device.name);
  const [position, setPosition] = useState(device.position);
  const [savedConfigVersion] = useState(device.savedConfigVersion);
  const dispatch = useDispatch();
  const [fixedIpAddress, setFixedIpAddress] = useState(
    getConfigOrDefault(config, 'networkConfiguration/interfaces/0/addresses/0/address')
  );
  const [ipProtocol, setIpProtocol] = useState(
    getConfigOrDefault(config, 'networkConfiguration/interfaces/0/addresses/0/proto')
  );
  const [ipGateway, setIpGateway] = useState(
    getConfigOrDefault(config, 'networkConfiguration/interfaces/0/addresses/0/gateway')
  );
  const [ipMask, setIpMask] = useState(
    getConfigOrDefault(config, 'networkConfiguration/interfaces/0/addresses/0/mask')
  );
  const [dnsServers, setDnsServers] = useState(getConfigOrDefault(config, 'networkConfiguration/dns_servers'));

  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const isDhcp = ipProtocol === 'dhcp';

  if (parseErrors.length > 0) {
    return (
      <Alert severity="error">
        Unexpected config format.
        <br />
        {parseErrors.map((m) => (
          <div key={m}>{m}</div>
        ))}
      </Alert>
    );
  }

  const submitForm = (event) => {
    event.preventDefault();
    setIsSaving(true);
    setErrorMessage('');

    const newConfig = cloneDeep(config);

    try {
      newConfig.networkConfiguration.interfaces[0].addresses[0].address = fixedIpAddress;
      newConfig.networkConfiguration.interfaces[0].addresses[0].proto = ipProtocol;
      newConfig.networkConfiguration.interfaces[0].addresses[0].gateway = ipGateway;
      newConfig.networkConfiguration.interfaces[0].addresses[0].mask = ipMask;

      newConfig.networkConfiguration.dns_servers = dnsServers;
    } catch (e) {
      log.error('Unable to set config to object', e);
    }

    const patchData = {name, position, savedConfigVersion, deviceConfig: {...newConfig}};
    log.info('PATCH', patchData);

    patchDevice(device.id, patchData)
      .then((newObject) => {
        dispatch(putDevice(newObject));
        if (onClose) {
          onClose();
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      })
      .finally(() => {
        setIsSaving(false);
        refreshDevices(dispatch);
      });
  };

  return (
    <form onSubmit={submitForm} method="post">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">Edit device properties</Typography>
        </Grid>
        {device.runningConfigVersion !== device.savedConfigVersion && (
          <>
            <Grid item xs={12}>
              <Alert severity="warning">
                This device has pending updates to its configuration. Running version: {device.runningConfigVersion},
                new version: {device.savedConfigVersion}
              </Alert>
            </Grid>
          </>
        )}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              label="Device Name"
              aria-describedby="name-helper-text"
              variant="filled"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <FormHelperText id="name-helper-text">Enter a name for your device</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              label="Device Position"
              aria-describedby="position-helper-text"
              variant="filled"
              value={position}
              onChange={(event) => {
                setPosition(event.target.value);
              }}
            />
            <FormHelperText id="position-helper-text">Enter a position for your device</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Network settings</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <RadioGroup
              name="ipProtocol"
              value={ipProtocol}
              onChange={(event) => {
                setIpProtocol(event.target.value);
              }}>
              <FormControlLabel value="dhcp" control={<Radio />} label="DHCP" />
              <FormControlLabel value="static" control={<Radio />} label="Static" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {!isDhcp && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="DNS Servers"
                aria-describedby="dns-helper-text"
                variant="filled"
                value={dnsServers.join(', ')}
                onChange={(event) => {
                  setDnsServers(event.target.value.split(/\s+|\s*,\s*/));
                }}
                onBlur={(event) => {
                  setDnsServers(event.target.value.split(/\s+|\s*,\s*/).filter((ip) => ip.length > 0));
                }}
              />
              <FormHelperText id="dns-helper-text">Comma-separated list of IP addresses</FormHelperText>
            </FormControl>
          </Grid>
        )}

        {!isDhcp && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Gateway IP"
                aria-describedby="gateway-helper-text"
                variant="filled"
                value={ipGateway}
                onChange={(event) => {
                  setIpGateway(event.target.value);
                }}
              />
              <FormHelperText id="gateway-helper-text">Enter a gateway IP for the device</FormHelperText>
            </FormControl>
          </Grid>
        )}
        {!isDhcp && (
          <>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  label="Fixed IP"
                  aria-describedby="ip-helper-text"
                  variant="filled"
                  value={fixedIpAddress}
                  onChange={(event) => {
                    setFixedIpAddress(event.target.value);
                  }}
                />
                <FormHelperText id="ip-helper-text">Enter a fixed IP for the device</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  label="Network mask"
                  aria-describedby="ip-helper-text"
                  variant="filled"
                  value={ipMask}
                  type="number"
                  step="1"
                  onChange={(event) => {
                    setIpMask(event.target.value);
                  }}
                />
                <FormHelperText id="ip-helper-text">Enter a network mask as an integer</FormHelperText>
              </FormControl>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          {errorMessage !== '' && <Alert severity="error">{errorMessage}</Alert>}
        </Grid>
        <Grid item xs={12} align="right">
          <Button
            variant="text"
            sx={{
              mr: 1,
            }}
            onClick={() => {
              if (onClose) {
                onClose();
              }
            }}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary" mr={2} disabled={isSaving} onClick={submitForm}>
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditDeviceForm;
