import React, {useContext} from 'react';
import {Route} from 'react-router-dom';
import {I18nProvider} from '@lingui/react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import {adaptV4Theme, createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {SnackbarProvider} from 'notistack';
import PropTypes from 'prop-types';

import MainBar from '../../components/Bar/MainBar';
import Copyright from '../../components/Copyright';
import MainDrawer from '../../components/Drawer/MainDrawer';
import catalogEn from '../../locales/en/messages.po';
import catalogSv from '../../locales/sv/messages.po';
import {UserContext} from '../../oauth/Session';

const theme = createTheme(
  adaptV4Theme({
    typography: {
      fontFamily: ['"Montserrat"', 'sans-serif'].join(','),
    },
    palette: {
      primary: {
        main: '#405070',
        contrast: '#FFFFFF',
      },
      error: {
        main: '#d95940',
        contrast: '#FFFFFF',
      },
      other: {
        divider: '#2f3a511f',
      },
      action: {
        active: '#14316c8a',
        disabled: '#32323299',
      },
      text: {
        primary: '#405070',
        secondary: '#546891',
      },
      grey: {
        50: '#f8f8f8',
        200: '#eeeeee',
        300: '#e0e0e0',
      },
      success: {
        main: '#48ad48',
      },
      warning: {
        main: '#ee8c2b',
      },
      secondary: {
        main: '#405070', // in design #eff1f6
      },
      background: {
        paper: '#FFFFFF',
        default: '#eff1f6',
      },
    },
  })
);

export const DefaultLayoutRoute = ({component: Component, ...rest}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <DefaultLayout>
          <Component {...props} />
        </DefaultLayout>
      )}
    />
  );
};

const catalogs = {svSE: catalogSv, enUS: catalogEn};
const locales = {svSE: ['sv-SE'], enUS: ['en-US']};

function DefaultLayout({children}) {
  const {locale} = useContext(UserContext);
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <I18nProvider language={locale} catalogs={catalogs} locales={locales[locale]}>
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            ref={notistackRef}
            action={(key) => <Button onClick={onClickDismiss(key)}>Dismiss</Button>}>
            <Box
              sx={{
                display: 'flex',
              }}>
              <MainBar />
              <MainDrawer />
              <main
                style={{
                  flex: 1,
                  width: '100px',
                  backgroundColor: '#eff1f6',
                }}>
                <Box sx={(theme) => theme.mixins.toolbar} />
                <Container
                  maxWidth="lg"
                  sx={{
                    mt: 2,
                  }}>
                  {children}
                </Container>
                <Box
                  sx={{
                    p: 2,
                  }}>
                  <Copyright />
                </Box>
              </main>
            </Box>
          </SnackbarProvider>
        </I18nProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
