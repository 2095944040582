const initialState = {
  isLoadingCameras: true,
  camerasErrorCode: '',
  camerasList: [],
};

export const setCamerasList = (list) => ({
  type: 'CAMREAS_SET_LIST',
  payload: list,
});

export const setIsLoadingCameras = (isLoading) => ({
  type: 'CAMERAS_SET_IS_LOADING',
  payload: !!isLoading,
});

export const setCamerasErrorCode = (errorCode) => ({
  type: 'CAMERAS_SET_ERROR_CODE',
  payload: errorCode,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'CAMREAS_SET_LIST':
      return Object.assign({...state}, {camerasList: action.payload});

    case 'CAMERAS_SET_IS_LOADING':
      return Object.assign({...state}, {isLoadingCameras: action.payload});

    case 'CAMERAS_SET_ERROR_CODE':
      return Object.assign({...state}, {camerasErrorCode: action.payload});

    default:
      return state;
  }
}
