import React from 'react';
import {useSelector} from 'react-redux';
import {Trans} from '@lingui/macro';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import Avatar from '@mui/material/Avatar';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import {
  // AuthorizationContext,
  withAuthorization,
} from '../../oauth/Session';
// import PasswordChangeForm from '../../components/PasswordChange';
import * as Conditions from '../../routes/Conditions';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    // height: 500,
  },
  center: {
    textAlign: 'center',
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
}));

function Account(props) {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  // const centerPaper = clsx(classes.paper, classes.center);

  const account = useSelector((state) => state.session.account);
  const credentials = useSelector((state) => state.credentials);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">
            <Trans>Account</Trans>
          </Typography>
        </Grid>
        <Grid item container xs={12} md={6}>
          <TableContainer component={Paper} elevation={1}>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    height: 60,
                  }}>
                  <TableCell colSpan={2}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                      }}>
                      User information
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key="Name"
                  sx={{
                    height: 60,
                  }}>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">{account.name}</TableCell>
                </TableRow>
                <TableRow
                  key="Type"
                  sx={{
                    height: 60,
                  }}>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">{account.idTokenClaims.emails[0]}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {/* <Paper className={classes.paper}>
            <Grid item xs={12}>
              <Box className={classes.paper}>
                <Typography display="block" gutterBottom>
                  <Trans>Name</Trans>:
                </Typography>
                <Typography gutterBottom>{account.name}</Typography>
                <Typography display="block" gutterBottom>
                  <Trans>Email</Trans>:
                </Typography>
                <Typography gutterBottom>{account.idTokenClaims.emails[0]}</Typography>
              </Box>
            </Grid>
          </Paper> */}
        </Grid>
        <Grid item container xs={12}>
          <Button variant="contained" href={process.env.REACT_APP_RESET_LINK}>
            Reset password
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper className={fixedHeightPaper}>
            Account
            <pre>{JSON.stringify(account, null, 2)}</pre>
            Credentials
            <pre>{JSON.stringify(credentials, null, 2)}</pre>
          </Paper>
        </Grid>
      </Grid>
      {/* <Paper className={fixedHeightPaper}>
        Account
        <pre>{JSON.stringify(account, null, 2)}</pre>
        Credentials
        <pre>{JSON.stringify(credentials, null, 2)}</pre> */}
      {/*        <Paper className={classes.paper} elevation={3} square>*/}
      {/*          <Grid container spacing={3}>*/}
      {/*            <Grid item xs={12} md={6} lg={6}>*/}
      {/*              <Box className={centerPaper}>*/}
      {/*                <Avatar*/}
      {/*                  alt={authUser.displayName}*/}
      {/*                  color="secondary"*/}
      {/*                  className={classes.large}*/}
      {/*                  src={authUser.photoURL}></Avatar>*/}
      {/*              </Box>*/}
      {/*            </Grid>*/}
      {/*            <Grid item xs={12} md={6} lg={6}>*/}
      {/*              <Box className={classes.paper}>*/}
      {/*                <Typography variant="overline" display="block" gutterBottom>*/}
      {/*                  <Trans>Name</Trans>:*/}
      {/*                </Typography>*/}
      {/*                <Typography variant="body1" gutterBottom>*/}
      {/*                  {authUser.displayName}*/}
      {/*                </Typography>*/}
      {/*                <Typography variant="overline" display="block" gutterBottom>*/}
      {/*                  <Trans>Email</Trans>:*/}
      {/*                </Typography>*/}
      {/*                <Typography variant="body1" gutterBottom>*/}
      {/*                  {authUser.email}*/}
      {/*                </Typography>*/}
      {/*              </Box>*/}
      {/*            </Grid>*/}
      {/*          </Grid>*/}
      {/*        </Paper>*/}
      {/*        /!*<Box>*!/*/}
      {/*        /!*  <div>Email verified: {authUser.emailVerified.toString()}</div>*!/*/}
      {/*        /!*  <div>Is anonymous: {authUser.isAnonymous.toString()}</div>*!/*/}
      {/*        /!*  <div>Phone number: {authUser.phoneNumber}</div>*!/*/}
      {/*        /!*  <div>Provider id: {authUser.providerId}</div>*!/*/}
      {/*        /!*  <div>Uid: {authUser.uid}</div>*!/*/}
      {/*        /!*  <div>Metadata: {JSON.stringify(authUser.metadata)}</div>*!/*/}
      {/*        /!*</Box>*!/*/}
      {/*        /!*<PasswordChangeForm />*!/*/}
      {/*        /!*<Box>*!/*/}
      {/*        /!*  <div>Auth: </div>*!/*/}
      {/*        /!*  <code>*!/*/}
      {/*        /!*    <pre>{JSON.stringify(authUser, null, 2)}</pre>*!/*/}
      {/*        /!*  </code>*!/*/}
      {/*        /!*</Box>*!/*/}
      {/*        /!*<AuthorizationContext.Consumer>*!/*/}
      {/*        /!*  {(credentials) => (*!/*/}
      {/*        /!*    <Box>*!/*/}
      {/*        /!*      <div>Credentials: </div>*!/*/}
      {/*        /!*      <code>*!/*/}
      {/*        /!*        <pre>{JSON.stringify(credentials, null, 2)}</pre>*!/*/}
      {/*        /!*      </code>*!/*/}
      {/*        /!*    </Box>*!/*/}
      {/*        /!*  )}*!/*/}
      {/*        /!*</AuthorizationContext.Consumer>*!/*/}
      {/* </Paper> */}
    </>
  );
}

export default withAuthorization(Conditions.ANY)(Account);
