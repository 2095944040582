import React from 'react';

const ICON = `M 0 10 L 15 10 L 15 5 L 20 12 L 15 20 L 15 14 L 0 14`;

const pinStyle = {
  fill: '#d00',
  stroke: 'none',
};

function Arrow(props) {
  const {size = 20} = props;
  const {color = '#d00'} = props;
  const {angle = '0deg'} = props;
  const style = {...pinStyle, fill: color, transform: 'rotate(' + angle + ')'};

  return (
    <svg height={size} viewBox="0 0 20 20" style={style}>
      <path d={ICON} />
      <text x="0" y="20" fontSize="0.5em">
        in
      </text>
    </svg>
  );
}

export default React.memo(Arrow);
