import log from 'loglevel';

import {API} from './api';
import {mockedResponse, organizationsMock} from './mock';

const useMock = false;

export async function getOrganizations() {
  if (useMock) {
    return mockedResponse(organizationsMock.data.sort((a, b) => (a.name > b.name ? 1 : -1)));
  }

  return API.get('/organizations')
    .then((response) => {
      log.debug(
        'Returning organizations: ' + JSON.stringify(response.data.data.sort((a, b) => (a.name > b.name ? 1 : -1)))
      );
      return response.data.data;
    })
    .catch(function (error) {
      log.error('fetch error ' + error);
      throw error;
    });
}

export async function getPresets() {
  if (true) {
    return mockedResponse([
      {
        id: 'some-uu-ii-dd1',
        name: 'My preset 1',
        MQTT_BASE_TOPIC: 'someTopic1',
        MQTT_PORT: 8883,
        MQTT_HOST: '192.168.0.1',
        MQTT_CLIENT_ID: 'myClientId',
        MQTT_USER_NAME: 'username',
        MQTT_PASSWORD: 'password!secret',
        timePeriodSeconds: 10,
      },
      {
        id: 'some-uu-ii-dd2',
        name: 'My preset 2',
        MQTT_BASE_TOPIC: 'someTopic2',
        MQTT_PORT: 8883,
        MQTT_HOST: 'some-mqtt-host.com',
        MQTT_CLIENT_ID: 'Flowity',
        MQTT_USER_NAME: 'User1337',
        MQTT_PASSWORD: 'notGoingToTell123',
        timePeriodSeconds: 20,
      },
    ]);
  }

  return API.get('/organizations/{id}/mqtt_presets')
    .then((response) => {
      return response.data.data;
    })
    .catch(function (error) {
      log.error('fetch error ' + error);
      throw error;
    });
}

export async function getOrganizationSettings(organizationId) {
  return API.get('/organizations/' + organizationId + '/settings')
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

export async function patchOrganizationSettings(organizationId, payload) {
  return API.patch('/organizations/' + organizationId + '/settings', payload)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}
