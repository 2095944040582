import React, {useState} from 'react';
import log from 'loglevel';

import {RolesContext} from './context';

const withRoles = (Component) => {
  function WithRoles(props) {
    const [roles, setRoles] = useState(() => {
      const value = localStorage.getItem('roles');
      return value !== null ? JSON.parse(value) : ['SUPER_USER'];
    });

    React.useEffect(() => {
      log.debug('Storing roles: ' + roles);
      localStorage.setItem('roles', JSON.stringify(roles));
    }, [roles]);

    return (
      <RolesContext.Provider
        value={{
          roles,
          setRoles,
        }}>
        <Component {...props} />
      </RolesContext.Provider>
    );
  }

  return WithRoles;
};

export default withRoles;
