import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import {withAuthHandler} from '../../oauth/index';
import {drawerWidth} from '../Drawer/MainDrawer';
import OrganizationSelector from '../OrganizationSelector';

// import ProjectSelector from '../ProjectSelector';
import AccountAvatar from './AccountAvatar';

function MainBar(props) {
  const drawerOpen = useSelector((state) => state.ui.drawer.drawerOpen);

  const host = document.location.hostname;
  const useEnvironmentWarning = useCallback(() => {
    if (host === 'app.flowity.io') {
      return <></>;
    } else {
      const backend_host = process.env.REACT_APP_API_URL;
      const color = host === 'localhost' ? '#3F3' : '#FC0'; // #F33 = prod, if we ever have it for admins only.
      const backend_color = process.env.REACT_APP_API_URL === 'http://localhost:8080' ? '#3F3' : '#FC0';
      return (
        <div
          style={{
            position: 'absolute',
            zIndex: 1202,
            right: 0,
            top: '64px',
            lineHeight: '1.5em',
            padding: '0px',
            textAlign: 'left',
            border: '1px dashed #333',
          }}>
          <div style={{backgroundColor: color, padding: '0px 8px'}}>
            Frontend:
            {host}
          </div>
          <div style={{backgroundColor: backend_color, padding: '0px 8px'}}>
            Backend:
            {backend_host}
          </div>
        </div>
      );
    }
  }, [host]);

  return (
    <>
      {useEnvironmentWarning()}
      <AppBar position="fixed">
        <Toolbar>
          <Box
            sx={
              drawerOpen
                ? {
                    width: drawerWidth,
                    transition: (theme) =>
                      theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                      }),
                  }
                : {
                    transition: (theme) =>
                      theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                      }),
                    width: 63,
                  }
            }
          />
          <OrganizationSelector />
          <AccountAvatar />
        </Toolbar>
      </AppBar>
    </>
  );
}

export default withAuthHandler(MainBar);
