import React, {Fragment} from 'react';
import {Route, Switch} from 'react-router-dom';
import CameraPage from 'pages/hardware/CameraPage';
import MeasurementsPage from 'pages/measurements/MeasurementsPage';

import {DefaultLayoutRoute} from '../layouts/default';
import {NonAuthLayoutRoute} from '../layouts/nonauth';
import OnboardingPage from '../pages-nonauth/onboarding/Onboarding';
import Account from '../pages/account/Account';
import CreateDevice from '../pages/admin/CreateHardwarePage';
import Dashboard from '../pages/dashboard/Dashboard';
import DeploymentPage from '../pages/deployments/DeploymentPage';
import Error404 from '../pages/error404/Error404';
import DevicePage from '../pages/hardware/DevicePage';
import HardwarePage from '../pages/hardware/HardwarePage';
import DataReportsPage from '../pages/reports/DataReportsPage';
import GraphicalReportsPage from '../pages/reports/GraphicalReportsPage';
import SettingsPage from '../pages/settings/SettingsPage';
// import SuperUser from '../pages/superuser/SuperUser';
import NewUser from '../pages/users/NewUser';
import UserRoles from '../pages/users/roles/UserRoles';
import User from '../pages/users/User';
import Users from '../pages/users/Users';

export const ONBOARDING = '/onboard';

export const LANDING = '/';
export const DASHBOARD = '/dashboard';
export const SETTINGS = '/settings';

// export const SUPER_USER = '/superuser';

export const ACCOUNT = '/account';

export const USERS = '/users';
export const NEW_USER = '/users/new';
export const USER = '/users/:uid';
export const USER_ROLES = '/users/roles';

export const DEVICES = '/devices';
export const DATAREPORT = '/data';
export const GRAPHICALREPORT = '/graphical';
export const DEVICE = '/devices/:did';
export const CAMERA = '/cameras/:did';
export const IOT_EDGE_DEVICES = '/devices/iot_edge';

export const DEPLOYMENT = '/deployments/:did';
export const MEASUREMENTS = '/measurements';

export const CONFIGURATOR = '/configurator';

export const CREATE_DEVICE = '/admin/create-device';

const ReactRouter = () => {
  return (
    <Fragment>
      <Switch>
        <DefaultLayoutRoute exact path={LANDING} component={Dashboard} />
        <DefaultLayoutRoute path={DASHBOARD} component={Dashboard} />
        <DefaultLayoutRoute path={SETTINGS} component={SettingsPage} />

        {/* <DefaultLayoutRoute path={SUPER_USER} component={SuperUser} /> */}

        <DefaultLayoutRoute path={ACCOUNT} component={Account} />

        <NonAuthLayoutRoute path={ONBOARDING} component={OnboardingPage} />

        <DefaultLayoutRoute exact path={USERS} component={Users} />
        <DefaultLayoutRoute exact path={NEW_USER} component={NewUser} />
        <DefaultLayoutRoute exact path={USER_ROLES} component={UserRoles} />
        <DefaultLayoutRoute exact path={USER} component={User} />

        <DefaultLayoutRoute exact path={DEVICES} component={HardwarePage} />
        <DefaultLayoutRoute exact path={DEVICE} component={DevicePage} />
        <DefaultLayoutRoute exact path={CAMERA} component={CameraPage} />
        <DefaultLayoutRoute exact path={DEPLOYMENT} component={DeploymentPage} />
        <DefaultLayoutRoute exact path={MEASUREMENTS} component={MeasurementsPage} />

        <DefaultLayoutRoute exact path={DATAREPORT} component={DataReportsPage} />
        <DefaultLayoutRoute exact path={GRAPHICALREPORT} component={GraphicalReportsPage} />

        <DefaultLayoutRoute exact path={CREATE_DEVICE} component={CreateDevice} />

        <Route component={Error404} />
      </Switch>
    </Fragment>
  );
};

export default ReactRouter;
