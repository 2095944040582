import {cloneDeep} from 'lodash';

const initialState = {
  list: [],
};

export const setOrganizations = (list) => ({
  type: 'ORGANIZATIONS_SET',
  payload: list,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'ORGANIZATIONS_SET':
      return Object.assign({...state}, {list: cloneDeep(action.payload)});

    default:
      return state;
  }
}
