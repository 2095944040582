import React from 'react';
import {Trans} from '@lingui/macro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Appearance from './components/Appearance';
import SensorGrouping from './components/SensorGrouping';
import Sensors from './components/Sensors';

function SettingsPage(props) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">
          <Trans>Settings</Trans>
        </Typography>
      </Grid>
      <Grid item container xs={12}>
        <Sensors />
        <Accordion defaultExpanded sx={{width: '100%'}}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="grouping-content" id="grouping-header">
            <Typography>Sensor grouping</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SensorGrouping />
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded sx={{width: '100%'}}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="appearance-content" id="appearance-header">
            <Typography>Appearance</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Appearance />
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}

export default SettingsPage;
