import log from 'loglevel';

const initialState = {organizations: []};

export const setCredentials = (credentials) => ({
  type: 'SET_CREDENTIALS',
  payload: credentials,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_CREDENTIALS':
      log.info('Setting credentials: ' + JSON.stringify(action.payload));
      return action.payload;
    default:
      return state;
  }
}
