const initialState = {
  settings: {
    dashboardType: 'occupancy',
    sourceType: '',
    sourceId: '',
    view: 'day',
    resolution: 300,
    // null means that the user wants to see data for Last 24h/Last 7 days/Last 4 weeks/Last 365 days
    // user can select a specific date in the date selector for the main period. Then the request period
    // will be calculated based on this date and selected view
    period: null,
    combineGraphs: false,
    counters: ['AVG'],
    keys: [],
    selectedFlow: {entry: null, exit: null, detectionClass: null},
    highlights: [],
    showDays: [1, 2, 3, 4, 5, 6, 7],
    calculateCapacity: true,
    chartType: 'line',
  },
};

export const setDashboardSettings = (settings) => ({
  type: 'DASHBOARD_SET_SETTINGS',
  payload: settings,
});

export const setDashboardType = (dashboardType) => ({
  type: 'DASHBOARD_SET_DASHBOARD_TYPE',
  payload: dashboardType,
});

export const setDashboardSource = (source) => ({
  type: 'DASHBOARD_SET_SOURCE',
  payload: source,
});

export const setDashboardView = (settings) => ({
  type: 'DASHBOARD_SET_VIEW',
  payload: settings,
});

export const setDashboardPeriod = (period) => ({
  type: 'DASHBOARD_SET_PERIOD',
  payload: period,
});

export const setDashboardResolution = (resolution) => ({
  type: 'DASHBOARD_SET_RESOLUTION',
  payload: resolution,
});

export const setDashboardCombineGraphs = (combineGraphs) => ({
  type: 'DASHBOARD_SET_COMBINE_GRAPHS',
  payload: combineGraphs,
});

export const setDashboardCalculateCapacity = (calculateCapacity) => ({
  type: 'DASHBOARD_SET_CALCULATE_CAPACITY',
  payload: calculateCapacity,
});

export const setDashboardCounters = (countersList) => ({
  type: 'DASHBOARD_SET_COUNTERS',
  payload: countersList,
});

export const setDashboardSelectedFlow = (flow) => ({
  type: 'DASHBOARD_SET_SELECTED_FLOW',
  payload: flow,
});

export const setDashboardHighlights = (daysArray) => ({
  type: 'DASHBOARD_SET_HIGHLIGHTS',
  payload: daysArray,
});

export const setDashboardShowDays = (daysArray) => ({
  type: 'DASHBOARD_SET_INCLUDE_DAYS',
  payload: daysArray,
});

export const setDashboardKeys = (keysArray) => ({
  type: 'DASHBOARD_SET_KEYS',
  payload: keysArray,
});

export const deleteDashboardKey = (keyName) => ({
  type: 'DASHBOARD_DELETE_KEY',
  payload: keyName,
});

export const deleteDashboardKeys = () => ({
  type: 'DASHBOARD_DELETE_KEYS',
});

export const setDashboardChartType = (value) => ({
  type: 'DASHBOARD_SET_CHART_TYPE',
  payload: value,
});

export const clearDashboardSettings = () => ({
  type: 'DASHBOARD_CLEAR_SETTINGS',
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'DASHBOARD_SET_SETTINGS':
      return {
        ...state,
        settings: action.payload,
      };
    case 'DASHBOARD_SET_DASHBOARD_TYPE':
      return {
        ...state,
        settings: {
          ...state.settings,
          dashboardType: action.payload,
        },
      };
    case 'DASHBOARD_SET_SOURCE':
      return {
        ...state,
        settings: {
          ...state.settings,
          sourceId: action.payload.sourceId,
          sourceType: action.payload.sourceType,
          counters: action.payload.counters,
          calculateCapacity: action.payload.calculateCapacity || false,
        },
      };
    case 'DASHBOARD_SET_VIEW':
      return {
        ...state,
        settings: {
          ...state.settings,
          view: action.payload.view,
          resolution: action.payload.resolution,
          period: action.payload.period,
        },
      };
    case 'DASHBOARD_SET_PERIOD':
      return {
        ...state,
        settings: {
          ...state.settings,
          period: action.payload,
        },
      };
    case 'DASHBOARD_SET_RESOLUTION':
      return {
        ...state,
        settings: {
          ...state.settings,
          resolution: action.payload,
        },
      };
    case 'DASHBOARD_SET_COMBINE_GRAPHS':
      return {
        ...state,
        settings: {
          ...state.settings,
          combineGraphs: action.payload,
        },
      };
    case 'DASHBOARD_SET_CALCULATE_CAPACITY':
      return {
        ...state,
        settings: {
          ...state.settings,
          calculateCapacity: action.payload,
        },
      };
    case 'DASHBOARD_SET_COUNTERS':
      return {
        ...state,
        settings: {
          ...state.settings,
          counters: action.payload,
        },
      };
    case 'DASHBOARD_SET_SELECTED_FLOW':
      return {
        ...state,
        settings: {
          ...state.settings,
          selectedFlow: action.payload,
        },
      };
    case 'DASHBOARD_SET_HIGHLIGHTS':
      return {
        ...state,
        settings: {
          ...state.settings,
          highlights: action.payload,
        },
      };
    case 'DASHBOARD_SET_INCLUDE_DAYS':
      return {
        ...state,
        settings: {
          ...state.settings,
          showDays: action.payload,
        },
      };
    case 'DASHBOARD_SET_KEYS':
      return {
        ...state,
        settings: {
          ...state.settings,
          keys: action.payload,
        },
      };
    case 'DASHBOARD_DELETE_KEY':
      return {
        ...state,
        settings: {
          ...state.settings,
          keys: state.settings.keys.filter((d) => d.name !== action.payload),
        },
      };
    case 'DASHBOARD_DELETE_KEYS':
      return {
        ...state,
        settings: {
          ...state.settings,
          keys: [],
        },
      };
    case 'DASHBOARD_SET_CHART_TYPE':
      return {
        ...state,
        settings: {
          ...state.settings,
          chartType: action.payload,
        },
      };
    case 'DASHBOARD_CLEAR_SETTINGS':
      return {
        ...state,
        settings: initialState.settings,
      };
    default:
      return state;
  }
}
