const initialState = {
  deploymentId: 0,
  frameRate: 100,
  from: 0,
  to: 0,
};

export const setDeployment = (deploymentId) => ({
  type: 'REPORTS_SET_DEPLOYMENT',
  payload: deploymentId,
});

export const setFrameRate = (rate) => ({
  type: 'REPORTS_SET_FRAME_RATE',
  payload: rate,
});

export const setPeriod = (period) => ({
  type: 'REPORTS_SET_PERIOD',
  payload: period,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'REPORTS_SET_DEPLOYMENT':
      return {...state, deploymentId: action.payload};
    case 'REPORTS_SET_FRAME_RATE':
      return {...state, frameRate: action.payload};
    case 'REPORTS_SET_PERIOD':
      return {...state, from: action.payload[0], to: action.payload[1]};
    default:
      return state;
  }
}
