import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Trans} from '@lingui/macro';
import CreateIcon from '@mui/icons-material/Create';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';

import {deletePolygon, updatePolygon} from '../../redux/configurator';
import * as ROUTES from '../../routes/Routes';

import Label from './Label';

const useStyles = makeStyles((theme) => ({
  polygon: {
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(1),
    alignItems: 'center',
    borderRadius: 4,
  },
  polygonContent: {
    padding: theme.spacing(2),
  },
  polygonForm: {
    marginBottom: theme.spacing(2),
  },
}));

const ConfiguratorPolygon = ({
  polygon,
  onCommit,
  showAreaDescription,
  spatialSubKey,
  deviceId,
  sensorGroupUpdates,
  setSensorGroupUpdates,
}) => {
  const sensorGroups = useSelector((state) => state.sensors.sensorGroupsList);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [lastState, setLastState] = useState({id: false});
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [polygonId, setPolygonId] = useState(polygon.id);
  const [polygonAreaDescription, setPolygonAreaDescription] = useState(polygon.areaDescription);
  const [polygonColor, setPolygonColor] = useState(polygon.color);
  const [polygonCapacity, setPolygonCapacity] = useState(polygon.capacity);
  const [polygonIsFlow, setPolygonIsFlow] = useState(polygon.isFlow);

  const getPolygonType = (subKey) => {
    switch (subKey) {
      case 'footfalls':
        return 'FOOTFALL';
      case 'areaDetect':
        return 'AREA_PRESENCE';
      default:
        return 'FOOTFALL';
    }
  };

  const handleGroupsChange = useCallback(
    (event, child) => {
      const modifiedSensorGroup = {...sensorGroups.find((sg) => sg.id === child.props.value.id)};
      if (!sensorGroupUpdates[modifiedSensorGroup.id]) {
        sensorGroupUpdates[modifiedSensorGroup.id] = {...modifiedSensorGroup};
      }
      if (sensorGroupUpdates[modifiedSensorGroup.id].sensorReferences.some((sr) => sr.sensorId === polygonId)) {
        // removing sensor from a group
        const newSensorReferences = sensorGroupUpdates[modifiedSensorGroup.id].sensorReferences.filter(
          (s) => s.sensorId !== polygonId
        );
        sensorGroupUpdates[modifiedSensorGroup.id].sensorReferences = newSensorReferences;
        setSensorGroupUpdates(sensorGroupUpdates);
      } else {
        // adding sensor to a group
        const newSensorReferences = sensorGroupUpdates[modifiedSensorGroup.id].sensorReferences.concat({
          deviceId: deviceId,
          sensorId: polygonId,
          type: getPolygonType(spatialSubKey),
        });
        sensorGroupUpdates[modifiedSensorGroup.id].sensorReferences = newSensorReferences;
        setSensorGroupUpdates(sensorGroupUpdates);
      }
      setSelectedGroups(event.target.value);
    },
    [deviceId, polygonId, sensorGroupUpdates, sensorGroups, setSensorGroupUpdates, spatialSubKey]
  );

  const sensorGroupsDropdown = useMemo(
    () =>
      sensorGroups &&
      sensorGroups.map((group) => (
        <MenuItem key={group.id} value={group}>
          <Checkbox checked={selectedGroups.indexOf(group) > -1} />
          <ListItemText primary={group.name} />
        </MenuItem>
      )),
    [selectedGroups, sensorGroups]
  );

  useEffect(() => {
    setPolygonAreaDescription(polygon.areaDescription);
  }, [polygon.areaDescription]);

  useEffect(() => {
    setPolygonId(polygon.id);
  }, [polygon.id]);

  useEffect(() => {
    setPolygonColor(polygon.color);
  }, [polygon.color]);

  useEffect(() => {
    setPolygonCapacity(polygon.capacity);
  }, [polygon.capacity]);

  useEffect(() => {
    setPolygonIsFlow(polygon.isFlow);
  }, [polygon.isFlow]);

  useEffect(() => {
    if (sensorGroups) {
      setSelectedGroups(sensorGroups.filter((sg) => sg.sensorReferences.some((sr) => sr.sensorId === polygonId)));
    }
  }, [polygonId, sensorGroups]);

  const color = polygon.color && polygon.color.length === 7 ? polygon.color : '#000000';

  return (
    <Grid item container className={classes.polygon}>
      <Grid item xs={polygon.isEditable ? 12 : 5}>
        <Label text={polygon.areaDescription || polygon.id} color={color} />
      </Grid>
      {!polygon.isEditable && (
        <Grid item xs={7} align="right">
          <IconButton
            key="delete"
            aria-label="delete item"
            onClick={() => {
              setLastState({id: false});
              dispatch(deletePolygon(polygon));
              if (onCommit) {
                onCommit();
              }
            }}
            size="large">
            <DeleteForeverIcon />
          </IconButton>
          <IconButton
            key="edit"
            aria-label="edit item"
            onClick={() => {
              setLastState({...polygon, coordinates: Object.assign([], polygon.coordinates)});
              dispatch(updatePolygon({...polygon, isEditable: true}));
              setPolygonId(polygon.id);
            }}
            size="large">
            <CreateIcon />
          </IconButton>
        </Grid>
      )}
      {polygon.isEditable && (
        <Grid container item xs={12} className={classes.polygonContent}>
          <Grid container item spacing={2} xs={12} className={classes.polygonForm}>
            {showAreaDescription && (
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Name"
                    aria-describedby="name-helper-text"
                    variant="filled"
                    value={polygonAreaDescription}
                    inputProps={{maxLength: 50}}
                    onChange={(event) => {
                      setPolygonAreaDescription(event.target.value);
                    }}
                    required
                  />
                  <FormHelperText id="name-helper-text">Polygon name</FormHelperText>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  label="ID"
                  aria-describedby="id-helper-text"
                  variant="filled"
                  value={polygonId}
                  inputProps={{maxLength: 50, readOnly: true}}
                />
                <FormHelperText id="id-helper-text">Polygon ID</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <TextField
                  label="Color"
                  type="color"
                  aria-describedby="color-helper-text"
                  variant="filled"
                  value={polygonColor}
                  onChange={(event) => {
                    setPolygonColor(event.target.value);
                  }}
                />
                <FormHelperText id="color-helper-text">Polygon color</FormHelperText>
              </FormControl>
            </Grid>
            {(spatialSubKey === 'footfalls' || spatialSubKey === 'areaDetect') && (
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth variant="filled">
                  <InputLabel id="groups-select-label">Sensor groups</InputLabel>
                  <Select
                    labelId="groups-select-label"
                    id="groups-select"
                    multiple
                    value={selectedGroups}
                    onChange={handleGroupsChange}
                    renderValue={(selected) => selected.map((s) => s.name).join(', ')}>
                    {sensorGroupsDropdown}
                  </Select>
                  <FormHelperText id="groups-select-text">
                    <Link to={ROUTES.SETTINGS}>Create sensor groups</Link>
                  </FormHelperText>
                </FormControl>
              </Grid>
            )}
            {spatialSubKey === 'areaDetect' && (
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <TextField
                    label="Capacity"
                    aria-describedby="capacity-helper-text"
                    variant="filled"
                    value={polygonCapacity}
                    type="number"
                    InputProps={{inputProps: {min: 1}}}
                    onChange={(event) => setPolygonCapacity(event.target.value)}
                  />
                  <FormHelperText id="capacity-helper-text">Area capacity</FormHelperText>
                </FormControl>
              </Grid>
            )}
            {spatialSubKey === 'footfalls' && (
              <Grid item xs={12}>
                <FormControlLabel
                  onChange={(event) => setPolygonIsFlow(event.target.checked)}
                  checked={polygonIsFlow}
                  control={<Checkbox />}
                  label="This sensor is a part of flow"
                  labelPlacement="end"
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} align="right">
            <Button
              key="cancel"
              size="small"
              style={{marginLeft: '6px'}}
              variant="text"
              onClick={() => {
                if (lastState.id) {
                  dispatch(updatePolygon({...lastState}));
                } else {
                  dispatch(updatePolygon({...polygon, isEditable: false}));
                }
                setLastState({id: false});
              }}>
              <Trans>Cancel</Trans>
            </Button>
            <Button
              key="clear"
              size="small"
              style={{marginLeft: '6px'}}
              variant="outlined"
              onClick={() => {
                dispatch(updatePolygon({...polygon, coordinates: []}));
              }}>
              <Trans>Redraw</Trans>
            </Button>
            <Button
              key="save"
              size="small"
              style={{marginLeft: '6px'}}
              color="primary"
              variant="contained"
              onClick={() => {
                setLastState({id: false});
                dispatch(
                  updatePolygon({
                    ...polygon,
                    isEditable: false,
                    newId: polygonId,
                    areaDescription: polygonAreaDescription,
                    color: polygonColor,
                    capacity: polygonCapacity,
                    isFlow: polygonIsFlow,
                  })
                );
                if (onCommit) {
                  onCommit();
                }
              }}>
              <Trans>Done</Trans>
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ConfiguratorPolygon;
