export const mockedResponse = (data, delayFrom = 500, delayTo = 2000) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, delayFrom + Math.random() * (delayTo - delayFrom));
  });
};

export const mockedError = (message, delayFrom = 500, delayTo = 2000) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject({message});
    }, delayFrom + Math.random() * (delayTo - delayFrom));
  });
};

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const delta30 = new Date(Date.now() - 30000).toISOString();
const deltaDay = new Date(Date.now() - 86401000).toISOString();
const deltaDay2 = new Date(Date.now() - 2 * 86401000).toISOString();
const deltaDayFuture = new Date(Date.now() + 86401000).toISOString();

setInterval(() => {
  devicesMock.data[0].lastHeartbeat = new Date(Date.now()).toISOString();
}, 180000);

export const chartMock = {
  data: [
    {start: '2022-06-01T12:45:35.294Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T12:50:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T12:55:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T13:00:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T13:05:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T13:10:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T13:15:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T13:20:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T13:25:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T13:30:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T13:35:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T13:40:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T13:45:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T13:50:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T13:55:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T14:00:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T14:05:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T14:10:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T14:15:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T14:20:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T14:25:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T14:30:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T14:35:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T14:40:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T14:45:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T14:50:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T14:55:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T15:00:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T15:20:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T15:25:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T15:30:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T15:35:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T15:40:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T15:45:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T15:50:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T15:55:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T16:00:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T16:05:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T16:10:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T16:15:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
    {start: '2022-06-01T16:20:00.000Z', value: 0, field: 'MAX', sensorId: 'Mattan'},
  ],
};

export const devicesMock = {
  data: [
    {
      id: '231b8afd-8374-4101-94f9-15df2cb644b8',
      bootTimes: ['2021-05-19T02:23:22.123Z', '2021-05-23T02:10:05.123Z', '2021-06-21T14:59:23.494Z'],
      name: 'Test Device 3 (goes offline often...)',
      deviceType: 'FSU',
      model: 'Test Model 1',
      hardwareVersion: '1.0',
      serialNumber: '22be3698-b86a-4024-9435-31d1aa921c44',
      macAddress: '01:23:45:67:89:AB',
      osVersion: '1.0',
      organization: 'e62492b1-2ed7-4c0b-828f-1107eb68cf4d',
      position: 'Building A, floor 3',
      deviceConfig: {
        networkConfiguration: {
          general: {hostname: 'Test Hostname 1'},
          interfaces: [
            {
              name: 'eth0',
              addresses: [
                {gateway: '176.9.211.209', mask: 28, family: 'ipv4', proto: 'static', address: '176.9.211.214'},
              ],
              type: 'ethernet',
            },
          ],
          dns_servers: ['10.254.0.1', '10.254.0.2'],
        },
      },
      deviceEdgeStatus: 'CONNECTED',
      iotEdgeDeviceId: null,
      runningConfigVersion: 1,
      savedConfigVersion: 1,
      lastHeartbeat: delta30,
      lastBooting: '2021-06-21T14:59:23.494Z',
      deviceConfigUpdateStatusSuccessful: true,
      deviceConfigUpdateStatusMessage: '',
    },
    {
      id: '23340893-9d9d-4c5e-85d0-07bb3d90f6c8',
      bootTimes: ['2021-05-19T02:23:22.123Z', '2021-05-23T02:10:05.123Z', '2021-06-21T14:59:55.909Z'],
      name: 'Test Device 4 (trouble booting)',
      deviceType: 'FSU',
      model: 'Test Model 1',
      hardwareVersion: '1.0',
      serialNumber: 'c5fc7856-749d-44ab-b6c5-348d24f0bc57',
      macAddress: '01:23:45:67:89:AB',
      osVersion: '1.0',
      organization: 'e62492b1-2ed7-4c0b-828f-1107eb68cf4d',
      position: 'Building A, floor 3',
      deviceConfig: {
        networkConfiguration: {
          general: {hostname: 'Test Hostname 1'},
          interfaces: [
            {
              name: 'eth0',
              addresses: [
                {gateway: '176.9.211.209', mask: 28, family: 'ipv4', proto: 'static', address: '176.9.211.214'},
              ],
              type: 'ethernet',
            },
          ],
          dns_servers: ['10.254.0.1', '10.254.0.2'],
        },
      },
      deviceEdgeStatus: 'DISCONNECTED',
      iotEdgeDeviceId: null,
      runningConfigVersion: 1,
      savedConfigVersion: 1,
      lastHeartbeat: deltaDay2,
      lastBooting: deltaDay,
      deviceConfigUpdateStatusSuccessful: true,
      deviceConfigUpdateStatusMessage: '',
    },
    {
      id: '2fb2d864-a11c-487d-a5c3-922fc2642703',
      bootTimes: ['2021-05-19T02:23:22.123Z', '2021-05-23T02:10:05.123Z', '2021-06-21T15:00:12.559Z'],
      name: 'Test Device 1 (where is the network!?)',
      deviceType: 'FSU',
      model: 'Test Model 1',
      hardwareVersion: '1.0',
      serialNumber: 'fe499238-fe22-446f-9a98-5d8e9edffaf6',
      macAddress: '01:23:45:67:89:AB',
      osVersion: '1.0',
      organization: 'e62492b1-2ed7-4c0b-828f-1107eb68cf4d',
      position: 'Building A, floor 3',
      deviceConfig: {
        networkConfiguration: {
          general: {hostname: 'Test Hostname 1'},
          interfaces: [
            {
              name: 'eth0',
              addresses: [
                {gateway: '176.9.211.209', mask: 28, family: 'ipv4', proto: 'static', address: '176.9.211.214'},
              ],
              type: 'ethernet',
            },
          ],
          dns_servers: ['10.254.0.1', '10.254.0.2'],
        },
      },
      deviceEdgeStatus: 'DISCONNECTED',
      iotEdgeDeviceId: null,
      runningConfigVersion: 1,
      savedConfigVersion: 1,
      lastHeartbeat: '2021-06-21T15:01:18.232Z',
      lastBooting: '2021-06-21T15:00:12.559Z',
      deviceConfigUpdateStatusSuccessful: true,
      deviceConfigUpdateStatusMessage: '',
    },
    {
      id: '745a001b-0d5c-4d02-a18d-4194b24b66a9',
      bootTimes: ['2021-05-19T02:23:22.123Z', '2021-05-23T02:10:05.123Z', '2021-06-21T15:00:22.273Z'],
      name: 'Test Device 2 (Recent reboot)',
      deviceType: 'FSU',
      model: 'Test Model 1',
      hardwareVersion: '1.0',
      serialNumber: '420250ad-9165-404b-bcd4-09230c89ecdd',
      macAddress: '01:23:45:67:89:AB',
      osVersion: '1.0',
      organization: 'e62492b1-2ed7-4c0b-828f-1107eb68cf4d',
      position: 'Building A, floor 3',
      deviceConfig: {
        networkConfiguration: {
          general: {hostname: 'Test Hostname 1'},
          interfaces: [
            {
              name: 'eth0',
              addresses: [
                {gateway: '176.9.211.209', mask: 28, family: 'ipv4', proto: 'static', address: '176.9.211.214'},
              ],
              type: 'ethernet',
            },
          ],
          dns_servers: ['10.254.0.1', '10.254.0.2'],
        },
      },
      deviceEdgeStatus: 'CONNECTED',
      iotEdgeDeviceId: null,
      runningConfigVersion: 1,
      savedConfigVersion: 1,
      lastHeartbeat: '2021-06-21T15:01:08.021Z',
      lastBooting: delta30,
      deviceConfigUpdateStatusSuccessful: true,
      deviceConfigUpdateStatusMessage: '',
    },
    {
      id: 'd603bf6a-0671-4da9-951e-64adb0a2f6dd',
      bootTimes: ['2021-05-19T02:23:22.123Z', '2021-05-23T02:10:05.123Z', '2021-06-21T15:00:29.593Z'],
      name: 'Test Device 5 (I tend to work...)',
      deviceType: 'FSU',
      model: 'Test Model 1',
      hardwareVersion: '1.0',
      serialNumber: '33502b6d-3837-4c88-95d8-f53b60b90cc2',
      macAddress: '01:23:45:67:89:AB',
      osVersion: '1.0',
      organization: 'e62492b1-2ed7-4c0b-828f-1107eb68cf4d',
      position: 'Building A, floor 3',
      deviceConfig: {
        networkConfiguration: {
          general: {hostname: 'Test Hostname 1'},
          interfaces: [
            {
              name: 'eth0',
              addresses: [
                {gateway: '176.9.211.209', mask: 28, family: 'ipv4', proto: 'static', address: '176.9.211.214'},
              ],
              type: 'ethernet',
            },
          ],
          dns_servers: ['10.254.0.1', '10.254.0.2'],
        },
      },
      deviceEdgeStatus: 'CONNECTED',
      iotEdgeDeviceId: null,
      runningConfigVersion: 1,
      savedConfigVersion: 1,
      lastHeartbeat: deltaDayFuture,
      deviceConfigUpdateStatusSuccessful: true,
      deviceConfigUpdateStatusMessage: '',
      lastBooting: '2021-06-21T15:00:29.593Z',
    },
    {
      id: 'd603bf6a-0671-4da9-951e-64adb0a2f6de',
      bootTimes: ['2021-05-19T02:23:22.123Z', '2021-05-23T02:10:05.123Z', '2021-06-21T15:00:29.593Z'],
      name: 'Test Device 6 (I have updates)',
      deviceType: 'FSU',
      model: 'Test Model 1',
      hardwareVersion: '1.0',
      serialNumber: '33502b6d-3837-4c88-95d8-f53b60b90cc2',
      macAddress: '01:23:45:67:89:AB',
      osVersion: '1.0',
      organization: 'e62492b1-2ed7-4c0b-828f-1107eb68cf4d',
      position: 'Building A, floor 3',
      deviceConfig: {
        networkConfiguration: {
          general: {hostname: 'Test Hostname 1'},
          interfaces: [
            {
              name: 'eth0',
              addresses: [
                {gateway: '176.9.211.209', mask: 28, family: 'ipv4', proto: 'static', address: '176.9.211.214'},
              ],
              type: 'ethernet',
            },
          ],
          dns_servers: ['10.254.0.1', '10.254.0.2'],
        },
      },
      deviceEdgeStatus: 'CONNECTED',
      iotEdgeDeviceId: null,
      runningConfigVersion: 1,
      savedConfigVersion: 2,
      lastHeartbeat: deltaDayFuture,
      deviceConfigUpdateStatusSuccessful: true,
      deviceConfigUpdateStatusMessage: '',
      lastBooting: '2021-06-21T15:00:29.593Z',
    },
    {
      id: 'd603bf6a-0671-4da9-951e-64adb0a2f6df',
      bootTimes: ['2021-05-19T02:23:22.123Z', '2021-05-23T02:10:05.123Z', '2021-06-21T15:00:29.593Z'],
      name: 'Test Device 0 (Many deployments)',
      deviceType: 'FSU',
      model: 'Test Model 1',
      hardwareVersion: '1.0',
      serialNumber: '33502b6d-3837-4c88-95d8-f53b60b90cc2',
      macAddress: '01:23:45:67:89:AB',
      osVersion: '1.0',
      organization: 'e62492b1-2ed7-4c0b-828f-1107eb68cf4d',
      position: 'Building A, floor 3',
      deviceConfig: {
        networkConfiguration: {
          general: {hostname: 'Test Hostname 1'},
          interfaces: [
            {
              name: 'eth0',
              addresses: [
                {gateway: '176.9.211.209', mask: 28, family: 'ipv4', proto: 'static', address: '176.9.211.214'},
              ],
              type: 'ethernet',
            },
          ],
          dns_servers: ['10.254.0.1', '10.254.0.2'],
        },
      },
      deviceEdgeStatus: 'CONNECTED',
      iotEdgeDeviceId: null,
      runningConfigVersion: 1,
      savedConfigVersion: 1,
      deviceConfigUpdateStatusSuccessful: true,
      deviceConfigUpdateStatusMessage: '',
      lastHeartbeat: deltaDayFuture,
      lastBooting: '2021-06-21T15:00:29.593Z',
    },
    {
      id: 'd603bf6a-0671-4da9-951e-64adb0a2f6ef',
      bootTimes: ['2021-05-19T02:23:22.123Z', '2021-05-23T02:10:05.123Z', '2021-06-21T15:00:29.593Z'],
      name: 'Test Device 7 (error)',
      deviceType: 'FSU',
      model: 'Test Model 1',
      hardwareVersion: '1.0',
      serialNumber: '33502b6d-3837-4c88-95d8-f53b60b90cc3',
      macAddress: '01:23:45:67:89:AB',
      osVersion: '1.0',
      organization: 'e62492b1-2ed7-4c0b-828f-1107eb68cf4d',
      position: 'Building A, floor 3',
      deviceConfig: {
        networkConfiguration: {
          general: {hostname: 'Test Hostname 1'},
          interfaces: [
            {
              name: 'eth0',
              addresses: [
                {gateway: '176.9.211.209', mask: 28, family: 'ipv4', proto: 'static', address: '176.9.211.214'},
              ],
              type: 'ethernet',
            },
          ],
          dns_servers: ['10.254.0.1', '10.254.0.2'],
        },
      },
      deviceEdgeStatus: 'ERROR',
      iotEdgeDeviceId: null,
      runningConfigVersion: 1,
      savedConfigVersion: 1,
      deviceConfigUpdateStatusSuccessful: false,
      deviceConfigUpdateStatusMessage: 'Something is not the way it is supposed to be...',
      lastHeartbeat: deltaDayFuture,
      lastBooting: '2021-06-21T15:00:29.593Z',
    },
    {
      id: 'd603bf6a-0671-4da9-251e-64adb0a2f6ef',
      bootTimes: ['2021-05-19T02:23:22.123Z', '2021-05-23T02:10:05.123Z', '2021-06-21T15:00:29.593Z'],
      name: 'Test Device 8 (depl. error)',
      deviceType: 'FSU',
      model: 'Test Model 1',
      hardwareVersion: '1.0',
      serialNumber: '33502b6d-3837-4c88-95d8-f53b60b90cc3',
      macAddress: '01:23:45:67:89:AB',
      osVersion: '1.0',
      organization: 'e62492b1-2ed7-4c0b-828f-1107eb68cf4d',
      position: 'Building A, floor 3',
      deviceConfig: {
        networkConfiguration: {
          general: {hostname: 'Test Hostname 1'},
          interfaces: [
            {
              name: 'eth0',
              addresses: [
                {gateway: '176.9.211.209', mask: 28, family: 'ipv4', proto: 'static', address: '176.9.211.214'},
              ],
              type: 'ethernet',
            },
          ],
          dns_servers: ['10.254.0.1', '10.254.0.2'],
        },
      },
      deviceEdgeStatus: 'CONNECTED',
      iotEdgeDeviceId: null,
      runningConfigVersion: 1,
      savedConfigVersion: 1,
      deviceConfigUpdateStatusSuccessful: true,
      deviceConfigUpdateStatusMessage: '',
      lastHeartbeat: deltaDayFuture,
      lastBooting: '2021-06-21T15:00:29.593Z',
    },
  ],
  count: 0,
  page: 0,
};

export const newDevicesMock = {
  data: [
    {
      id: '231a8afd-8374-4101-94f9-15df2cb644b8',
      bootTimes: [],
      name: 'NewDummyDevice',
      deviceType: 'FSU',
      model: 'Test Model 1',
      hardwareVersion: '1.0',
      serialNumber: '22be3698-b86a-4024-9435-31d1aa92fdg4',
      macAddress: '01:23:45:67:89:AB',
      osVersion: '1.0',
      organization: 'e62492b1-2ed7-4c0b-828f-1107eb68cf4d',
      position: 'Building A, floor 3',
      deviceConfig: {},
      iotEdgeDeviceId: null,
      runningConfigVersion: 1,
      savedConfigVersion: 1,
      lastHeartbeat: delta30,
      lastBooting: '2021-06-21T14:59:23.494Z',
      manufacturedAt: '2021-06-21T14:59:23.494Z',
    },
  ],
};

export const deploymentsMock = {
  data: [
    {
      id: '32409328-e8d1-4a1c-9169-b29d7cedeb69',
      runtimeStatus: 'RUNNING',
      name: 'Test deployment 1',
      deviceId: '2fb2d864-a11c-487d-a5c3-922fc2642703',
      sensorView: 'Test view',
      deploymentConfig: {},
      serviceId: '32404628-e8d1-4a1c-9169-b29d7cedeb69',
      versionTag: '1.4',
      jcoreConfigUpdateStatusSuccessful: true,
      jcoreConfigUpdateStatusMessage: '',
    },
    {
      id: 'c87f4e67-c93e-44ae-ba44-7bf002f680f9',
      runtimeStatus: 'RUNNING',
      name: 'Test deployment 3',
      deviceId: '231b8afd-8374-4101-94f9-15df2cb644b8',
      sensorView: 'Test view',
      deploymentConfig: {},
      serviceId: '32404628-e8d1-4a1c-9169-b29d7cedeb69',
      versionTag: '1.4',
      jcoreConfigUpdateStatusSuccessful: true,
      jcoreConfigUpdateStatusMessage: '',
    },
    {
      id: 'c87f4e67-c93e-44ae-ba44-7bf002f680fa',
      runtimeStatus: 'RUNNING',
      name: 'Test deployment 6a',
      deviceId: 'd603bf6a-0671-4da9-951e-64adb0a2f6df',
      sensorView: 'Test view',
      deploymentConfig: {},
      serviceId: '32404628-e8d1-4a1c-9169-b29d7cedeb69',
      versionTag: '1.4',
      jcoreConfigUpdateStatusSuccessful: true,
      jcoreConfigUpdateStatusMessage: '',
    },
    {
      id: 'c87f4e67-c93e-44ae-ba44-7bf002f680fb',
      runtimeStatus: 'RUNNING',
      name: 'Test deployment 6b',
      deviceId: 'd603bf6a-0671-4da9-951e-64adb0a2f6df',
      sensorView: 'Test view',
      deploymentConfig: {},
      serviceId: '32404628-e8d1-4a1c-9169-b29d7cedeb69',
      versionTag: '1.4',
      jcoreConfigUpdateStatusSuccessful: true,
      jcoreConfigUpdateStatusMessage: '',
    },
    {
      id: 'c87f4e67-c93e-44ae-ba44-7bf002f680fc',
      runtimeStatus: 'RUNNING',
      name: 'Test deployment 5a',
      deviceId: 'd603bf6a-0671-4da9-951e-64adb0a2f6dd',
      sensorView: 'Test view',
      deploymentConfig: {},
      serviceId: '32404628-e8d1-4a1c-9169-b29d7cedeb69',
      versionTag: '1.3',
      jcoreConfigUpdateStatusSuccessful: true,
      jcoreConfigUpdateStatusMessage: '',
    },
    {
      id: 'c87f4e67-c93e-44ae-ba44-7bf002f680fd',
      runtimeStatus: 'NOT_RUNNING',
      name: 'Test deployment 5b',
      deviceId: 'd603bf6a-0671-4da9-951e-64adb0a2f6dd',
      sensorView: 'Test view',
      deploymentConfig: {},
      serviceId: '32204628-e8d1-4a1c-9169-b29d7cedeb69',
      versionTag: '1.2',
      jcoreConfigUpdateStatusSuccessful: true,
      jcoreConfigUpdateStatusMessage: '',
    },
    {
      id: 'c87f4e67-c93e-44ae-ba44-7bf002f680fe',
      runtimeStatus: 'RUNNING',
      name: 'Test deployment 8a',
      deviceId: 'd603bf6a-0671-4da9-251e-64adb0a2f6ef',
      sensorView: 'Test view',
      deploymentConfig: {},
      serviceId: '32204628-e8d1-4a1c-9169-b29d7cedeb69',
      versionTag: '1.2',
      jcoreConfigUpdateStatusSuccessful: false,
      jcoreConfigUpdateStatusMessage: 'Oooops I did it again!',
    },
  ],
  count: 0,
  page: 0,
};

export const servicesMock = {
  data: [
    {
      id: '32404628-e8d1-4a1c-9169-b29d7cedeb69',
      name: 'People Counting',
      description: 'Some description',
      extendedDexcription: 'Lorem ipsum',
      enabled: true,
      versions: [
        {tag: '1.3', name: 'OlderVersion', description: 'Some more info'},
        {tag: '1.4', name: 'NewerVersion', description: 'Some more info'},
      ],
    },
    {
      id: '32204628-e8d1-4a1c-9169-b29d7cedeb69',
      name: 'Fancy People Counting',
      description: 'Some description',
      extendedDexcription: 'This is so expensive and you have not access to it...',
      enabled: false,
      versions: [{tag: '1.2', name: 'NameOfVersion', description: 'Some more info'}],
    },
  ],
  count: 0,
  page: 0,
};

export const instancesMock = {
  data: [
    {
      id: '32404628-e8d1-4a1c-9169-b29d7gefeb60',
      deploymentId: '32409328-e8d1-4a1c-9169-b29d7cedeb69',
      instanceConfig: {},
      status: 'RUNNING',
    },
  ],
  count: 0,
  page: 0,
};

export const organizationsMock = {
  data: [
    {
      id: '32404628-e8d1-4a67-9169-b29d7gefeb61',
      name: 'SomeOrganization 1',
    },
    {
      id: '32404628-e8d1-4a67-9169-b29d7gefeb62',
      name: 'SomeOrganization 2',
    },
  ],
  count: 2,
  page: 0,
};

export const iotEdgeDevicesMock = {
  data: [
    {
      deviceId: '32404628-e8d1-4a1c-9169-b29d7gefeb60',
    },
  ],
  count: 0,
  page: 0,
};

export const deploymentJsonConfig = {
  json: {
    someProperty: 123,
    someArray: [
      {key: 'k1', value: 'v1'},
      {key: 'k2', value: 'v2'},
    ],
    someObject: {
      key1: 'value1',
      key2: 'value2',
    },
  },
};
