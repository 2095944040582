export const API_REFRESH_RATE = 60;

export const HEARTBEAT_SECONDS = 65;
export const HEARTBEAT_SECONDS_OFFLINE = API_REFRESH_RATE + HEARTBEAT_SECONDS;

const delta = (delta) => {
  if (delta > 3600) {
    return parseInt(delta / 3600) + 'h ' + (parseInt(delta / 60) % 60) + 'm';
  }
  return (parseInt(delta / 60) % 60) + 'm ' + (delta % 60) + 's';
};

export const decorateDevice = (device, nowSeconds) => {
  // lastHeartbeat is not being used for vFSU
  let tmp = device.lastHeartbeat ? device.lastHeartbeat.split(/[^0-9]/) : [2000, 1, 1, 0, 0, 0];
  const lastHeartbeat = new Date(Date.UTC(tmp[0], tmp[1] - 1, tmp[2], tmp[3], tmp[4], tmp[5]));
  // lastBooting is an old idea that never got finished
  tmp = device.lastBooting ? device.lastBooting.split(/[^0-9]/) : [2000, 1, 1, 0, 0, 0];
  const lastBooting = new Date(Date.UTC(tmp[0], tmp[1] - 1, tmp[2], tmp[3], tmp[4], tmp[5]));

  const heartbeatDelta = nowSeconds - lastHeartbeat.getTime() / 1000;
  const lastBootingDelta = nowSeconds - lastBooting.getTime() / 1000;

  let deviceStatus = '';
  let deviceStatusTip = '';
  let timeElapsed = '';
  // // ERROR HANDLING HERE

  if (device.deviceEdgeStatus === 'CONNECTED') {
    deviceStatus = 'Online';
  } else if (device.deviceEdgeStatus === 'DISCONNECTED') {
    deviceStatus = 'Offline';
    deviceStatusTip = lastHeartbeat.toLocaleString('sv-SE');
    timeElapsed = heartbeatDelta > 86400 ? '' : delta(heartbeatDelta);
  } else {
    deviceStatus = 'Error';
    deviceStatusTip = lastHeartbeat.toLocaleString('sv-SE');
    timeElapsed = heartbeatDelta > 86400 ? '' : delta(heartbeatDelta);
  }

  return {
    ...device,
    _: {
      lastHeartbeat,
      heartbeatDelta,
      lastBooting,
      lastBootingDelta,
      deviceStatus,
      deviceStatusTip,
      timeElapsed,
    },
  };
};
