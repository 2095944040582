import React from 'react';
import {useSelector} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import {Trans} from '@lingui/macro';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import log from 'loglevel';

import {withAuthHandler} from '../../oauth/index';
import * as ROUTES from '../../routes/Routes';

function AccountAvatar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const account = useSelector((state) => state.session.account);
  const handlePopperOpenClose = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    props.authHandler.logoutCurrentUser().then((response) => {
      log.info('Logout response: ' + JSON.stringify(response));
    });
  };

  const YourAccountLink = React.forwardRef((props, ref) => <RouterLink ref={ref} to={ROUTES.ACCOUNT} {...props} />);

  return (
    <>
      <Popper open={open} anchorEl={anchorEl} placement="bottom-end" transition>
        {({TransitionProps}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center top',
            }}>
            <Paper elevation={10}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box
                  sx={{
                    minWidth: 350,
                    minHeight: 200,
                  }}>
                  <Box
                    sx={{
                      m: 1,
                    }}>
                    <Grid container spacing={3} justifyContent="space-around" alignItems="center">
                      <Grid item xs={4}>
                        <Avatar
                          alt={account.name}
                          color="secondary"
                          src={account.photoURL}
                          sx={{
                            width: 64,
                            height: 64,
                          }}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <Typography component="div" variant="body1">
                          <Box fontWeight="fontWeightBold">{account.name}</Box>
                          <Box>{account.email}</Box>
                        </Typography>
                        <Button
                          color="primary"
                          component={YourAccountLink}
                          size="small"
                          variant="contained"
                          aria-label="your account"
                          sx={{
                            m: 2,
                          }}
                          onClick={handleClickAway}>
                          <Trans>Your account</Trans>
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      minHeight: 70,
                      backgroundColor: 'background.default',
                    }}></Box>
                  <Divider />
                  <Box
                    sx={{
                      p: 1,
                      backgroundColor: 'background.default',
                      textAlign: 'right',
                    }}>
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      aria-label="Log out"
                      onClick={handleLogout}>
                      Log out
                    </Button>
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <Tooltip title={'Account: ' + account.name + ' (' + account.email + ')'} placement="bottom-end">
        <Box
          color="inherit"
          alt="Hello"
          id="avatar-button"
          sx={{
            marginLeft: 'auto',
            textAlign: 'right',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={handlePopperOpenClose}>
          <Avatar
            alt={account.name}
            color="secondary"
            src={account.photoURL}
            sx={{
              mr: 2,
            }}></Avatar>
          <Typography
            component="div"
            variant="body1"
            color="inherit"
            noWrap
            sx={{
              flexGrow: 1,
            }}>
            {account.name}
            {account.email}
          </Typography>
          <KeyboardArrowDownIcon />
        </Box>
      </Tooltip>
    </>
  );
}

export default withAuthHandler(AccountAvatar);
