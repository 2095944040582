import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import ConfiguratorImage from 'components/Configurator/ConfiguratorImage';

import ReportMap from './ReportMap';

const DEFAULT_IMAGE_MODE_SETTINGS = {
  mode: 'IMAGE',
  overlayUrl: '/img/floorplan.jpg',
  viewport: {
    latitude: 0.5,
    longitude: 0.5,
    zoom: 8.5,
  },
  imageLoaded: false,

  clamp: true,
  minLatitude: 0,
  maxLatitude: 1,
  minLongitude: 0,
  maxLongitude: 1,

  minZoom: 8,
  maxZoom: 10,
};

const GraphicalReport = ({polygons}) => {
  const deploymentId = useSelector((state) => state.reports.deploymentId);

  const [imageModeSettings, setImageModeSettings] = useState(DEFAULT_IMAGE_MODE_SETTINGS);

  const [tabIndex, setTabIndex] = useState(0);
  const [reportType, setReportType] = useState('Playback');
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    switch (newValue) {
      case 0:
        setReportType('Playback');
        break;
      case 1:
        setReportType('Tracks Diagram');
        break;
      case 2:
        setReportType('Heatmap');
        break;
      default:
        break;
    }
  };

  useEffect(() => setImageModeSettings(DEFAULT_IMAGE_MODE_SETTINGS), [deploymentId]);

  return (
    <>
      {imageModeSettings.imageLoaded && (
        <>
          <Grid item container xs={12}>
            <Box style={{marginBottom: '10px', width: '100%'}}>
              <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="Playback" />
                <Tab label="Tracks Diagram" />
                <Tab label="Heatmap" />
              </Tabs>
              {imageModeSettings.imageLoaded && (
                <ReportMap polygons={polygons} reportType={reportType} {...imageModeSettings}></ReportMap>
              )}
            </Box>
          </Grid>
        </>
      )}
      <ConfiguratorImage
        deploymentId={deploymentId}
        imageModeSettings={imageModeSettings}
        setImageModeSettings={setImageModeSettings}
      />
    </>
  );
};

export default GraphicalReport;
