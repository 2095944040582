import {combineReducers} from 'redux';

import camerasReducer from './cameras.reducer';
import configuratorReducer from './configurator';
import credentialsReducer from './credentials';
import dashboardReducer from './dashboard.reducer';
import deploymentsReducer from './deployments.reducer';
import devicesReducer from './devices.reducer';
import organizationsReducer from './organizations.reducer';
import reportsReducer from './reports.reducer';
import sensorsReducer from './sensors.reducer';
import sessionReducer from './session';
import uiReducer from './ui';

export default combineReducers({
  session: sessionReducer,
  credentials: credentialsReducer,
  ui: uiReducer,
  configurator: configuratorReducer,
  reports: reportsReducer,
  devices: devicesReducer,
  cameras: camerasReducer,
  deployments: deploymentsReducer,
  organizations: organizationsReducer,
  sensors: sensorsReducer,
  dashboard: dashboardReducer,
});
