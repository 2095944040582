import React, {useCallback, useEffect, useState} from 'react';
import {FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {getPresets} from '../../../api/organizations';
import Button from '../../../components/Button';

const defaultMqttPort = 8883;

const defaultMqttSettings = {
  MQTT_BASE_TOPIC: '',
  MQTT_PORT: 0, // 8883
  MQTT_HOST: '',
  MQTT_CLIENT_ID: '',
  MQTT_USER_NAME: '',
  MQTT_PASSWORD: '',
  timePeriodSeconds: 10,
};

const MqttOutputConfig = ({editableConfig, updateFromObject}) => {
  const mqttSettings = editableConfig.output.mqtt;
  const [hasMqttSettings, setHasMqttSettings] = useState(false);

  const [presets, setPresets] = useState([]);

  useEffect(() => {
    if (mqttSettings) {
      setHasMqttSettings(mqttSettings.MQTT_PORT > 0);
    } else {
      setHasMqttSettings(false);
    }
  }, [mqttSettings]);

  useEffect(() => {
    getPresets().then((data) => {
      setPresets(data);
    });
  }, []);

  const toggleHasMqttSettings = useCallback(() => {
    const newSettings = {
      ...editableConfig,
      output: {
        ...editableConfig.output,
        mqtt: {...defaultMqttSettings, MQTT_PORT: defaultMqttPort},
      },
    };
    if (hasMqttSettings) {
      delete newSettings.output.mqtt;
    }
    updateFromObject(newSettings);
  }, [editableConfig, hasMqttSettings, updateFromObject]);

  const updateProperty = useCallback(
    (key, newValue) => {
      const outputObject = {
        ...editableConfig.output,
        mqtt: {...mqttSettings},
      };
      outputObject.mqtt[key] = newValue;
      updateFromObject({...editableConfig, output: outputObject});
    },
    [editableConfig, mqttSettings, updateFromObject]
  );

  const readFromPreset = (selectedId) => {
    const found = presets.filter((preset) => preset.id === selectedId);
    if (found.length > 0) {
      const preset = found[0];
      const newMqttObject = {};
      Object.keys(preset)
        .filter((key) => key !== 'id' && key !== 'name')
        .forEach((key) => {
          newMqttObject[key] = preset[key];
        });

      updateFromObject({
        ...editableConfig,
        output: {
          ...editableConfig.output,
          mqtt: {...newMqttObject},
        },
      });
    } else if (selectedId === 'clear') {
      updateFromObject({
        ...editableConfig,
        output: {
          ...editableConfig.output,
          mqtt: {...defaultMqttSettings, MQTT_PORT: defaultMqttPort},
        },
      });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">MQTT Output Config</Typography>
        </Grid>
        {hasMqttSettings && mqttSettings && (
          <>
            <Grid item xs={12}>
              <Alert severity="info">
                MQTT is enabled. Providing incorrect MQTT settings may result in data not being sent from the device
                properly.
              </Alert>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                onClick={(event) => {
                  toggleHasMqttSettings();
                }}>
                Disable MQTT
              </Button>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="presets-header">Presets</InputLabel>
                <Select
                  variant="filled"
                  labelId="presets-header"
                  aria-describedby="presets-select-help"
                  id="presets-select"
                  value={0}
                  label="Age"
                  onChange={(event) => {
                    readFromPreset(event.target.value);
                  }}>
                  <MenuItem value={0}>Select a preset</MenuItem>
                  <MenuItem value={'clear'}>Clear</MenuItem>
                  {presets.map((preset) => {
                    return (
                      <MenuItem key={preset.id} value={preset.id}>
                        {preset.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText id="presets-select-help">
                  If your organization has presets, those will be listed here.
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  label="MQTT_HOST"
                  aria-describedby="helper-MQTT_HOST"
                  variant="filled"
                  value={mqttSettings.MQTT_HOST + ''}
                  onChange={(event) => {
                    updateProperty('MQTT_HOST', event.target.value);
                  }}
                />
                <FormHelperText id="helper-MQTT_HOST">MQTT_HOST</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  label="MQTT_PORT"
                  aria-describedby="helper-MQTT_PORT"
                  variant="filled"
                  value={mqttSettings.MQTT_PORT + ''}
                  type="number"
                  onChange={(event) => {
                    updateProperty('MQTT_PORT', event.target.value);
                  }}
                />
                <FormHelperText id="helper-MQTT_PORT">MQTT_PORT</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  label="MQTT_CLIENT_ID"
                  aria-describedby="helper-MQTT_CLIENT_ID"
                  variant="filled"
                  value={mqttSettings.MQTT_CLIENT_ID + ''}
                  onChange={(event) => {
                    updateProperty('MQTT_CLIENT_ID', event.target.value);
                  }}
                />
                <FormHelperText id="helper-MQTT_CLIENT_ID">MQTT_CLIENT_ID</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  label="MQTT_BASE_TOPIC"
                  aria-describedby="helper-MQTT_BASE_TOPIC"
                  variant="filled"
                  value={mqttSettings.MQTT_BASE_TOPIC + ''}
                  onChange={(event) => {
                    updateProperty('MQTT_BASE_TOPIC', event.target.value);
                  }}
                />
                <FormHelperText id="helper-MQTT_BASE_TOPIC">MQTT_BASE_TOPIC</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  label="MQTT_USER_NAME"
                  aria-describedby="helper-MQTT_USER_NAME"
                  variant="filled"
                  value={mqttSettings.MQTT_USER_NAME + ''}
                  onChange={(event) => {
                    updateProperty('MQTT_USER_NAME', event.target.value);
                  }}
                />
                <FormHelperText id="helper-MQTT_USER_NAME">MQTT_USER_NAME</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  label="MQTT_PASSWORD"
                  aria-describedby="helper-MQTT_PASSWORD"
                  variant="filled"
                  type="password"
                  value={mqttSettings.MQTT_PASSWORD + ''}
                  onChange={(event) => {
                    updateProperty('MQTT_PASSWORD', event.target.value);
                  }}
                />
                <FormHelperText id="helper-MQTT_PASSWORD">MQTT_PASSWORD</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  label="timePeriodSeconds"
                  aria-describedby="helper-timePeriodSeconds"
                  variant="filled"
                  value={mqttSettings.timePeriodSeconds + ''}
                  type="number"
                  onChange={(event) => {
                    updateProperty('timePeriodSeconds', event.target.value);
                  }}
                />
                <FormHelperText id="helper-timePeriodSeconds">timePeriodSeconds</FormHelperText>
              </FormControl>
            </Grid>
          </>
        )}
        {!hasMqttSettings && (
          <>
            <Grid item xs={12}>
              <Alert severity="info">MQTT is disabled.</Alert>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={(event) => {
                  toggleHasMqttSettings();
                }}>
                Enable MQTT
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default MqttOutputConfig;
