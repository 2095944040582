const initialState = {
  sensorGroupsList: [],
  sensorsList: [],
};

export const setSensorGroupsList = (list) => ({
  type: 'SENSOR_GROUPS_SET_LIST',
  payload: list,
});

export const setSensorsList = (list) => ({
  type: 'SENSORS_SET_LIST',
  payload: list,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SENSOR_GROUPS_SET_LIST':
      return {...state, sensorGroupsList: action.payload};
    case 'SENSORS_SET_LIST':
      return {...state, sensorsList: action.payload};
    default:
      return state;
  }
}
