/* eslint-disable */ // Ignoring because of strict import order
import React, {Component} from 'react';
import ReactRouter from './routes/Routes';
import {BrowserRouter as Router} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import {compose} from 'recompose';

import {withUser, withRoles} from './oauth/Session';
import 'moment/locale/en-gb';
import AuthHandler, {AuthHandlerContext} from './oauth';
import {useStore} from 'react-redux';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const App = () => {
  const store = useStore();
  return (
    <>
      <CssBaseline />
      <Router>
        <AuthHandlerContext.Provider value={new AuthHandler(store)}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <RouterApp />
          </LocalizationProvider>
        </AuthHandlerContext.Provider>
      </Router>
    </>
  );
};

export default App;

class RouterAppBase extends Component {
  render() {
    return <ReactRouter />;
  }
}

const RouterApp = compose(withUser, withRoles)(RouterAppBase);
