import React, {useContext} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Trans} from '@lingui/macro';
import {i18nMark} from '@lingui/react';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import AddIcon from '@mui/icons-material/Add';
import log from 'loglevel';
import {compose} from 'recompose';

import {deleteUser, getUsers} from '../../api/users';
import NavigationToolbar from '../../components/NavigationToolbar';
import Table from '../../components/Table';
import Timestamp from '../../components/Timestamp';
import Title from '../../components/Typography/Title';
import {UserContext, withAuthorization} from '../../oauth/Session';
import * as Conditions from '../../routes/Conditions';

// const NewUserLink = React.forwardRef((props, ref) => <RouterLink ref={ref} to="/users/new" {...props} />);

function Users(props) {
  const {currentOrganization} = useContext(UserContext);

  function removeUser(uid) {
    deleteUser(uid)
      .then((response) => {
        log.debug('Deleted user: ' + JSON.stringify(response));
      })
      .catch((error) => {
        log.error('Delete user threw error: ' + JSON.stringify(error));
      });
  }

  function getRoleChips(roles) {
    if (!roles) {
      return null;
    }
    return roles.map((role) => (
      <Chip
        key={role.role_name}
        size="small"
        color="primary"
        label={role.role_name}
        clickable
        component={RouterLink}
        to={'users/roles/' + role.rid}
      />
    ));
  }

  return (
    <>
      <NavigationToolbar routes={[<Trans>Users</Trans>]} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              overflow: 'auto',
              flexDirection: 'column',
            }}>
            <Trans>Users in the organization {currentOrganization}</Trans>
            <Title>
              <Trans>Users</Trans>
            </Title>
            <Typography component="p" variant="h4">
              N/A
            </Typography>
            <Title>
              <Trans>Pending invitations</Trans>
            </Title>
            <Typography component="p" variant="h4">
              N/A
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              overflow: 'auto',
              flexDirection: 'column',
            }}></Paper>
        </Grid>
        <Grid item xs={12}>
          <Toolbar>
            <Typography variant="h3">
              <Trans>Users</Trans>
            </Typography>
          </Toolbar>
          <Table
            tableHeaders={[
              i18nMark('Name'),
              i18nMark('Email'),
              i18nMark('Onboarded at'),
              i18nMark('Roles'),
              i18nMark('Status'),
              i18nMark(''),
            ].map((item) => (
              <TableCell align="left" key={item}>
                <Trans id={item}>{item}</Trans> {/*Fix translation here */}
              </TableCell>
            ))}
            dataSource={getUsers}
            tableRows={(data) => {
              return data.map((row) => (
                <TableRow key={row.uid} hover>
                  <TableCell align="left" scope="row">
                    {row.displayName}
                  </TableCell>
                  <TableCell align="left">
                    {/*<Tooltip title={row.uid} aria-label="uid">*/}
                    {row.email}
                    {/*</Tooltip>*/}
                  </TableCell>
                  <TableCell align="left">
                    <Timestamp time={row.onboarded_at} />
                  </TableCell>
                  <TableCell align="left">{getRoleChips(row.roles)}</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right" sx={{p: 0}}>
                    <Button
                      color="primary"
                      size="small"
                      component={RouterLink}
                      to={'users/' + row.id}
                      variant="outlined"
                      aria-label="view user"
                      sx={{
                        m: 0,
                        ml: 1,
                      }}>
                      <Trans>View</Trans>
                    </Button>
                    <Button
                      color="primary"
                      size="small"
                      onClick={() => removeUser(row.uid)}
                      variant="outlined"
                      aria-label="remove user"
                      sx={{
                        m: 0,
                        ml: 1,
                      }}>
                      <Trans>Remove</Trans>
                    </Button>
                  </TableCell>
                </TableRow>
              ));
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default compose(withAuthorization(Conditions.ANY))(Users);
