import log from 'loglevel';

import {API} from './api';

export async function getUsers() {
  return API.get('/users')
    .then((response) => {
      log.debug('Returning users: ' + JSON.stringify(response.data));
      // TODO check status code and validate that there's data
      return response.data;
    })
    .catch(function (error) {
      log.error('fetch error ' + error);
      throw error;
    });
}

export async function getUserRoles() {
  return API.get('/users/roles')
    .then((response) => {
      log.debug('Returning user roles: ' + JSON.stringify(response.data));
      // TODO check status code and validate that there's data
      return response.data;
    })
    .catch(function (error) {
      log.error('fetch error ' + error);
      throw error;
    });
}

export async function getUserRole(rid) {
  return API.get('/users/roles/' + rid)
    .then((response) => {
      log.debug('Returning user role: ' + JSON.stringify(response.data));
      // TODO check status code and validate that there's data
      return response.data;
    })
    .catch(function (error) {
      log.error('fetch error ' + error);
      throw error;
    });
}

export async function getInvitedUsers() {
  return API.get('/users/invited')
    .then((response) => {
      log.debug('Returning invited users: ' + JSON.stringify(response.data));
      // TODO check status code and validate that there's data
      return response.data;
    })
    .catch(function (error) {
      log.error('fetch error ' + error);
      throw error;
    });
}

export async function getUser(uid) {
  return API.get('/users/' + uid)
    .then((response) => {
      log.debug('Returning user: ' + JSON.stringify(response.data));
      // TODO check status code and validate that there's data
      return response.data;
    })
    .catch(function (error) {
      log.error('fetch error ' + error);
      throw error;
    });
}

export async function getInvitedUser(uid) {
  return API.get('/users/invited/' + uid)
    .then((response) => {
      log.debug('Returning invited user: ' + JSON.stringify(response.data));
      // TODO check status code and validate that there's data
      return response.data;
    })
    .catch(function (error) {
      log.error('fetch error ' + error);
      throw error;
    });
}

export async function patchUser(uid, user_patch) {
  return API.patch('/users/' + uid, user_patch)
    .then((response) => {
      log.debug('Returning patched user: ' + JSON.stringify(response.data));
      // TODO check status code and validate that there's data
      return response.data;
    })
    .catch(function (error) {
      log.error('fetch error ' + error);
      throw error;
    });
}

export async function patchInvitedUser(uid, invited_user_patch) {
  return API.patch('/users/invited/' + uid, invited_user_patch)
    .then((response) => {
      log.debug('Returning patched user: ' + JSON.stringify(response.data));
      // TODO check status code and validate that there's data
      return response.data;
    })
    .catch(function (error) {
      log.error('fetch error ' + error);
      throw error;
    });
}

export async function deleteInvitedUser(uid) {
  return API.delete('/users/invited/' + uid)
    .then((response) => {
      log.debug('Deleted invited user: ' + JSON.stringify(response.data));
      // TODO check status code and validate that there's data
      return response.data;
    })
    .catch(function (error) {
      log.error('fetch error ' + error);
      throw error;
    });
}

export async function deleteUser(uid) {
  return API.delete('/users/' + uid)
    .then((response) => {
      log.debug('Deleted user: ' + JSON.stringify(response.data));
      // TODO check status code and validate that there's data
      return response.data;
    })
    .catch(function (error) {
      log.error('fetch error ' + error);
      throw error;
    });
}

export async function postInviteUser(invitedUser) {
  return API.post('/users/invited', invitedUser)
    .then((response) => {
      log.debug('Returning invited user: ' + JSON.stringify(response.data));
      // TODO check status code and validate that there's data
      return response.data;
    })
    .catch(function (error) {
      log.error('fetch error ' + error);
      throw error;
    });
}
