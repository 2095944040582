import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    borderRadius: 4,
    lineHeight: '32px',
  },
}));

const TypographyTheme = ({text, color}) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{borderLeft: '10px solid' + color}}>
      {text}
    </div>
  );
};

export default React.memo(TypographyTheme);
