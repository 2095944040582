import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Trans} from '@lingui/macro';
// import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import DashboardIcon from '@mui/icons-material/Dashboard';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';

// import {RolesContext} from '../../oauth/Session';
// import * as Conditions from '../../routes/Conditions';
import * as ROUTES from '../../routes/Routes';

const useStyles = makeStyles((theme) => ({
  linkItem: {
    textDecoration: 'none',
    color: 'inherit',
  },
  nested: {
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  iconButton: {
    // color: theme.palette.secondary.darkest
  },
  iconButtonDrawerClosed: {
    marginLeft: -theme.spacing(4),
  },
}));

export default function MainListItems(props) {
  const classes = useStyles();
  const drawerOpen = useSelector((state) => state.ui.drawer.drawerOpen);
  // const {roles} = useContext(RolesContext);
  return (
    <List>
      <Link to={ROUTES.DASHBOARD} className={classes.linkItem}>
        <Tooltip title={<Trans>Dashboard</Trans>} placement="right" disableHoverListener={drawerOpen}>
          <ListItem button>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={<Trans>Dashboard</Trans>} />
          </ListItem>
        </Tooltip>
      </Link>
      {/* {roles.includes(Conditions.SUPER_USER) && (
        <Link to={ROUTES.SUPER_USER} className={classes.linkItem}>
          <Tooltip title={<Trans>Super user</Trans>} placement="right" disableHoverListener={drawerOpen}>
            <ListItem button>
              <ListItemIcon>
                <AssignmentIndIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary={<Trans>Super user</Trans>} />
            </ListItem>
          </Tooltip>
        </Link>
      )} */}
    </List>
  );
}
