const initialState = {
  isLoadingDeployments: true,
  deploymentsErrorCode: '',
  deploymentsList: [],
};

export const setDeploymentsList = (list) => ({
  type: 'DEPLOYMENTS_SET_LIST',
  payload: list,
});

export const setIsLoadingDeployments = (isLoading) => ({
  type: 'DEPLOYMENTS_SET_IS_LOADING',
  payload: !!isLoading,
});

export const setDeploymentsErrorCode = (errorCode) => ({
  type: 'DEPLOYMENTS_SET_ERROR_CODE',
  payload: errorCode,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'DEPLOYMENTS_SET_LIST':
      return Object.assign({...state}, {deploymentsList: action.payload});

    case 'DEPLOYMENTS_SET_IS_LOADING':
      return Object.assign({...state}, {isLoadingDeployments: action.payload});

    case 'DEPLOYMENTS_SET_ERROR_CODE':
      return Object.assign({...state}, {deploymentsErrorCode: action.payload});

    default:
      return state;
  }
}
