import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HelpIcon from '@mui/icons-material/Help';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import WarningIcon from '@mui/icons-material/Warning';
import {green, grey, red, yellow} from '@mui/material/colors';

import './styles.css';

export const StyledWarningIcon = () => {
  return <WarningIcon fontSize="inherit" style={{color: yellow[800]}} />;
};

export const GreyWarningIcon = () => {
  return <WarningIcon fontSize="inherit" style={{color: grey[500]}} />;
};

export const StyledCheckIcon = () => {
  return <CheckIcon fontSize="inherit" style={{color: green[800]}} />;
};

export const StyledOkIcon = () => {
  return <FiberManualRecordIcon fontSize="inherit" style={{color: green[700]}} />;
};

export const StyledErrorIcon = () => {
  return <ErrorIcon fontSize="inherit" style={{color: red[800]}} />;
};
export const StyledUnknownIcon = () => {
  return <HelpIcon fontSize="inherit" style={{color: grey[800]}} />;
};

export const StyledOrangeCircle = () => {
  return <FiberManualRecordIcon fontSize="inherit" style={{color: yellow[800]}} />;
};
export const StyledGreyCircle = () => {
  return <FiberManualRecordIcon fontSize="inherit" style={{color: grey[500]}} />;
};
export const StyledHourglass = () => {
  return <HourglassFullIcon fontSize="inherit" style={{color: grey[500]}} />;
};
