import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import {Trans} from '@lingui/macro';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import {getDeviceStatus} from 'helpers';
import moment from 'moment';

const DeviceTable = () => {
  const devicesList = useSelector((state) => state.devices.devicesList);

  const useDeviceList = useCallback(() => {
    return devicesList
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((device) => {
        const deviceStatus = getDeviceStatus(device);
        const deviceStatusTooltip =
          deviceStatus !== 'Online' ? moment(device.lastHeartbeat || '2000-01-01').format('MMM Do yyyy, HH:mm:ss') : '';
        return (
          <TableRow
            key={device.id}
            sx={{
              height: 75,
            }}>
            <TableCell align="left">{device.name}</TableCell>
            <TableCell align="left">{device.deviceType === 'vFSU' ? 'Server' : device.deviceType}</TableCell>
            <TableCell align="left">
              <Tooltip title={deviceStatusTooltip} arrow>
                <Chip label={deviceStatus} color="default" />
              </Tooltip>
            </TableCell>
            <TableCell align="right">
              <RouterLink to={'/devices/' + device.id} style={{textDecoration: 'none'}}>
                <Button variant="contained" color="primary" size="small">
                  <Trans>Details</Trans>
                </Button>
              </RouterLink>
            </TableCell>
          </TableRow>
        );
      });
  }, [devicesList]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TableContainer component={Paper} elevation={0}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Processing device</TableCell>
                  <TableCell align="left">Type</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{useDeviceList()}</TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default DeviceTable;
