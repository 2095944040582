import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CircularProgress, FormControl, FormHelperText, MenuItem, Select} from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {DateTimePicker} from '@mui/x-date-pickers';
import {refreshCameras, refreshDeployments} from 'helpers';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import {setDeployment} from 'redux/reports.reducer';

import Button from 'components/Button';

const ReportForm = ({getReportData, isDownloading}) => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const deploymentsList = useSelector((state) => state.deployments.deploymentsList);
  const camerasList = useSelector((state) => state.cameras.camerasList);
  const currentOrganization = useSelector((state) => state.session.currentOrganization);
  const [deploymentId, setDeploymentId] = useState(0);
  const [fromDate, setFromDate] = useState(moment().subtract(20, 'm'));
  const [toDate, setToDate] = useState(moment());

  const refresh = useCallback(() => {
    refreshDeployments(dispatch, enqueueSnackbar, currentOrganization);
    refreshCameras(dispatch, enqueueSnackbar, currentOrganization);
  }, [dispatch, enqueueSnackbar, currentOrganization]);

  const getPlaybackCallback = useCallback(() => {
    const payload = {
      deploymentId,
      from: fromDate.toISOString(),
      to: toDate.toISOString(),
    };
    if (deploymentId === 0) {
      enqueueSnackbar('Please, select at least one device', {variant: 'error'});
      return;
    }
    if (toDate.isSameOrBefore(fromDate)) {
      enqueueSnackbar('Please, provide a valid From and To interval', {variant: 'error'});
      return;
    }
    getReportData(payload);
  }, [deploymentId, fromDate, toDate, getReportData, enqueueSnackbar]);

  const handleFromChange = useCallback((value) => {
    setFromDate(value);
    setToDate(moment(value).add(20, 'm'));
  }, []);

  const handleToChange = useCallback((value) => {
    setToDate(value);
  }, []);

  const deploymentsDropdown = useMemo(
    () =>
      deploymentsList.map((deployment) => {
        const cameraName = camerasList.find((camera) => camera.id === deployment.cameraId)?.name;
        return (
          <MenuItem key={deployment.id} value={deployment.id}>
            {(cameraName && cameraName + ' - ') + deployment.name}
          </MenuItem>
        );
      }),
    [camerasList, deploymentsList]
  );

  useEffect(() => {
    refresh();
    setDeploymentId(0);
    dispatch(setDeployment(0));
  }, [currentOrganization, dispatch, refresh]);

  return (
    <>
      <Grid item xs={12} sm={6} md={5}>
        <FormControl fullWidth>
          <Select
            variant="filled"
            aria-describedby="helper-deploymentId"
            id="deploymentId-select"
            value={deploymentId}
            size="small"
            onChange={(event) => {
              setDeploymentId(event.target.value);
              dispatch(setDeployment(event.target.value));
            }}>
            <MenuItem value={0}>Select a measurement</MenuItem>
            {deploymentsDropdown}
          </Select>
          <FormHelperText id="helper-deploymentId">Select the measurement to request data for</FormHelperText>
        </FormControl>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12} sm={6} md={5}>
          <FormControl fullWidth>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} size="small" variant="filled" />}
              value={fromDate}
              disableFuture
              ampm={false}
              onChange={handleFromChange}
              label="From"
              showTodayButton
            />
            <FormHelperText id="helper-from">Recommended playback length is 20 minutes</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <FormControl fullWidth>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} size="small" variant="filled" />}
              value={toDate}
              disableFuture
              ampm={false}
              size="small"
              onChange={handleToChange}
              label="To"
              showTodayButton
            />
          </FormControl>
        </Grid>
        <Grid container item xs={12} md={2}>
          <Button
            disabled={isDownloading}
            variant="contained"
            color="primary"
            startIcon={isDownloading && <CircularProgress size={18} color="primary" />}
            onClick={() => {
              getPlaybackCallback();
            }}
            sx={{height: 47, minWidth: 123}}>
            Get report
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ReportForm;
