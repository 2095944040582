import React from 'react';
import CodeIcon from '@mui/icons-material/Code';
import {grey, yellow} from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const showDebug = true;

function DebugItem({item, name}) {
  return (
    <>
      {showDebug && (
        <Grid container spacing={3} style={{marginTop: '20px'}}>
          <Grid item xs={12}>
            <Paper
              sx={{
                padding: 1,
                display: 'block',
                overflow: 'auto',
                fontSize: '11px',
                backgroundColor: yellow[50],
                width: '100%',
              }}>
              <Grid container spacing={1}>
                <Grid item xs={11}>
                  <b style={{fontFamily: 'monospace'}}>{name}</b>
                  <pre style={{width: '80vw', overflow: 'auto'}}>{JSON.stringify(item, null, 2)}</pre>
                </Grid>
                <Grid item xs={1} align="right">
                  <CodeIcon style={{color: grey[500]}} />
                  <br />
                  Debug
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default DebugItem;
