import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {FormControl, FormHelperText, TextField} from '@mui/material';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {patchDeployment} from 'api/deployments';
import {refreshDeployments} from 'helpers';

import Button from '../../components/Button';

const EditDeploymentForm = ({deployment, onClose}) => {
  const dispatch = useDispatch();
  const [name, setName] = useState(deployment.name);
  const [sensorView, setSensorView] = useState(deployment.sensorView);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const submitForm = (event) => {
    event.preventDefault();
    setIsSaving(true);
    setErrorMessage('');

    const patchData = {name, sensorView, savedConfigVersion: deployment.savedConfigVersion};

    patchDeployment(deployment.id, patchData)
      .then((data) => {
        if (onClose) {
          onClose();
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      })
      .finally(() => {
        setIsSaving(false);
        refreshDeployments(dispatch);
      });
  };

  return (
    <form onSubmit={submitForm} method="post">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">Edit deployment properties</Typography>
        </Grid>
        {deployment.runningConfigVersion !== deployment.savedConfigVersion && (
          <>
            <Grid item xs={12}>
              <Alert severity="warning">
                This deployment has pending updates to its configuration. Running version:{' '}
                {deployment.runningConfigVersion}, new version: {deployment.savedConfigVersion}
              </Alert>
            </Grid>
          </>
        )}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              label="Deployment name"
              aria-describedby="name-helper-text"
              variant="filled"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <FormHelperText id="name-helper-text">Enter a name for your deployment</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              label="Deployment view"
              aria-describedby="view-helper-text"
              variant="filled"
              value={sensorView}
              onChange={(event) => {
                setSensorView(event.target.value);
              }}
            />
            <FormHelperText id="view-helper-text">Enter a view for your deployment</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {errorMessage !== '' && <Alert severity="error">{errorMessage}</Alert>}
        </Grid>
        <Grid item xs={12} align="right">
          <Button
            variant="text"
            sx={{
              mr: 1,
            }}
            onClick={() => {
              if (onClose) {
                onClose();
              }
            }}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            mr={2}
            disabled={isSaving || (deployment.name === name && deployment.sensorView === sensorView)}
            onClick={submitForm}>
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditDeploymentForm;
