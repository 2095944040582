import React from 'react';
import {Layer, Source} from 'react-map-gl';

const HeatmapShape = ({data}) => {
  const geojson = {
    type: 'FeatureCollection',
    features: data.map((d) => ({
      type: 'Feature',
      properties: {id: d.id, intensity: d.intensity},
      geometry: {type: 'Point', coordinates: d.coordinates},
    })),
  };

  const heatmapStyle = {
    maxzoom: 11,
    type: 'heatmap',
    paint: {
      // Increase the heatmap weight based on frequency and property magnitude
      'heatmap-weight': ['interpolate', ['linear'], ['get', 'intensity'], 0, 0, 6, 1],
      // Increase the heatmap color weight weight by zoom level
      // heatmap-intensity is a multiplier on top of heatmap-weight
      'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, 9, 3],
      // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
      // Begin color ramp at 0-stop with a 0-transparancy color
      // to create a blur-like effect.
      'heatmap-color': [
        'interpolate',
        ['linear'],
        ['heatmap-density'],
        0,
        'rgba(10,112,243, 0)',
        0.2,
        'rgb(100,168,147)',
        0.4,
        'rgb(161,205,86)',
        0.6,
        'rgb(220,246,20)',
        0.8,
        'rgb(243,190,6)',
        1,
        'rgb(252,42,4)',
      ],
      // Adjust the heatmap radius by zoom level
      'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 9, 20],
    },
  };

  return (
    <Source id={'polygon'} key={'polygon'} type="geojson" data={geojson}>
      <Layer {...heatmapStyle} />
    </Source>
  );
};

export default HeatmapShape;
