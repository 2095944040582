import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {Trans} from '@lingui/macro';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import {useTheme} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import log from 'loglevel';
import {compose} from 'recompose';

import {getUserRoles, postInviteUser} from '../../api/users';
import NavigationToolbar from '../../components/NavigationToolbar';
import {withAuthorization} from '../../oauth/Session';
import * as Conditions from '../../routes/Conditions';
import * as ROUTES from '../../routes/Routes';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, roles, theme) {
  let x;
  for (x in roles) {
    if (roles[x].role_name === name) {
      return {fontWeight: theme.typography.fontWeightMedium};
    }
  }
  return {fontWeight: theme.typography.fontWeightRegular};
}

function NewUser(props) {
  const theme = useTheme();

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [roles, setRoles] = React.useState([]);
  const [availableRoles, setAvailableRoles] = React.useState([]);
  const [error, setError] = React.useState(null);

  useEffect(() => {
    getUserRoles()
      .then((roles) => {
        log.debug('Setting roles to: ' + JSON.stringify(roles));
        setAvailableRoles(roles.data);
      })
      .catch((error) => {
        log.error('Unable to get roles');
        setError(error);
      });
  }, []);

  const handlePostNewUser = () => {
    // TODO make sure all fields contains data
    const invitedUser = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      roles: roles,
    };
    postInviteUser(invitedUser)
      .then((data) => {
        log.debug('Got create new user response: ' + JSON.stringify(data));
        // Go back to user page
        props.history.push(ROUTES.USERS);
      })
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <>
      <NavigationToolbar routes={[<Trans>Users</Trans>, <Trans>New User</Trans>]} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              overflow: 'auto',
              flexDirection: 'column',
            }}>
            <form noValidate autoComplete="off">
              <div>
                <TextField
                  sx={{m: 1}}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                  id="standard-required"
                  label={<Trans>First Name</Trans>}
                />
                <TextField
                  sx={{m: 1}}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                  id="standard-required"
                  label={<Trans>Last Name</Trans>}
                />
              </div>
              <div>
                <TextField
                  sx={{m: 1}}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  error
                  id="email"
                  label={<Trans>Email</Trans>}
                  helperText="Enter email"
                />
              </div>
              <div>
                <FormControl
                  sx={{
                    m: 1,
                    width: 400,
                  }}>
                  <InputLabel id="roles-mutiple-chip-label">Roles</InputLabel>
                  <Select
                    labelId="role-mutiple-chip-label"
                    id="role-mutiple-chip"
                    multiple
                    value={roles}
                    onChange={(e) => setRoles(e.target.value)}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                        }}>
                        {selected.map((value) => (
                          <Chip key={value.rid} label={value.role_name} sx={{m: 2}} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}>
                    {availableRoles.map((role) => (
                      <MenuItem key={role.rid} value={role} style={getStyles(role, roles, theme)}>
                        {role.role_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </form>
            <Button
              color="primary"
              variant="contained"
              aria-label="create user"
              sx={{
                m: 1,
              }}
              onClick={handlePostNewUser}>
              <Trans>Create user</Trans>
            </Button>
            {!!error && <Typography>{error.message}</Typography>}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              overflow: 'auto',
              flexDirection: 'column',
            }}>
            <Typography>
              <Trans>When a new user is created, the new user will get an email with a link to sign up.</Trans>
            </Typography>
            <pre>{JSON.stringify(roles)}</pre>
          </Paper>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </>
  );
}

export default compose(withRouter, withAuthorization(Conditions.ANY))(NewUser);
