import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Trans} from '@lingui/macro';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useSnackbar} from 'notistack';

import {addDeployment} from '../../../api/deployments';
import {getAvailableServicesForDevice} from '../../../api/devices';
import FlexiblePaper from '../../../components/FlexiblePaper';
import {HeaderButton} from '../../../components/HeaderButtons';
import Modal from '../../../components/Modal';

import DeploymentGlance from './DeploymentGlance';

function DeviceDeploymentsCard({device}) {
  const deployments = useSelector((state) => state.deployments.deploymentsList);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const [availableServices, setAvailableServices] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const history = useHistory();

  useEffect(() => {
    setErrorMessage('');
    setAvailableServices([]);
    if (device) {
      getAvailableServicesForDevice(device.id)
        .then((data) => setAvailableServices(data))
        .catch((e) => {
          setAvailableServices([]);
          setErrorMessage(e.message);
        });
    }
  }, [device]);

  const add = (serviceId, versionTag) => {
    addDeployment(device.id, serviceId, versionTag)
      .then((deployment) => {
        history.push('/deployments/' + deployment.id);
      })
      .catch((error) => {
        enqueueSnackbar('Error adding deployment: ' + error.message, {variant: 'error'});
      });
  };

  // getAvailableServicesForDevice
  if (!device) {
    return <></>;
  }

  if (errorMessage !== '') {
    return <></>;
  }

  const filteredDeployments = deployments.filter((d) => d.deviceId === device.id);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item container xs={12} md={12} alignItems="center">
          <Typography
            variant="h4"
            sx={{
              mr: 2,
            }}>
            Deployments
          </Typography>
          <HeaderButton
            sx={{
              height: 30,
            }}
            color="primary"
            startIcon={<AddIcon />}
            onClick={(event) => {
              setIsModalOpen(true);
            }}>
            <Trans>Add new</Trans>
          </HeaderButton>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              Active deployment limit for this device is {device.deviceType === 'FSU' ? '1' : '10'}.
            </Typography>
          </Grid>
        </Grid>
        {filteredDeployments.map((d) => (
          <Grid item xs={12} sm={12} md={6} lg={6} key={d.id}>
            <DeploymentGlance
              deployment={d}
              active={device.currentDeployments.filter((cd) => cd.id === d.id).length > 0}
            />
          </Grid>
        ))}
      </Grid>
      <Modal
        isOpen={isModalOpen}
        onClose={(event) => {
          setIsModalOpen(false);
        }}>
        <Grid container spacing={3}>
          {availableServices.map((as) => (
            <Grid item xs={12} key={as.id}>
              <Typography variant="h4">
                <Trans>{as.name}</Trans>
              </Typography>
              <p>{as.description}</p>
              <Grid container spacing={1}>
                {as.versions.map((v) => (
                  <Grid item xs={12} sm={12} md={6} key={v.tag}>
                    <FlexiblePaper style={{backgroundColor: '#F3F3F3'}}>
                      <b>
                        {v.name} ({v.tag})
                      </b>
                      <p>{v.description}</p>
                      {as.enabled ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(event) => {
                            add(as.id, v.tag);
                          }}>
                          Add
                        </Button>
                      ) : (
                        <Alert severity="info" variant="outlined">
                          Currently not available. Contact support.
                        </Alert>
                      )}
                    </FlexiblePaper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} align="right">
            <Button
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={(event) => {
                setIsModalOpen(false);
              }}>
              Close
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}

export default DeviceDeploymentsCard;
