import {API} from './api';
import {devicesMock, mockedError, mockedResponse, newDevicesMock, servicesMock} from './mock';

const useMock = false;
const newDeviceUseMock = false;

export async function getDevices() {
  if (useMock) {
    return mockedResponse(devicesMock.data);
  }
  return API.get('/devices')
    .then((response) => {
      return response.data.data.sort((a, b) => (a.name > b.name ? 1 : -1));
    })
    .catch(function (error) {
      throw error;
    });
}

export async function getNewDevices() {
  if (newDeviceUseMock) {
    return mockedResponse(newDevicesMock.data);
  }
  return API.get('/devices/unassigned')
    .then((response) => {
      return response.data.data.sort((a, b) => (a.name > b.name ? 1 : -1));
    })
    .catch(function (error) {
      throw error;
    });
}

export async function createDevice(device) {
  if (newDeviceUseMock) {
    newDevicesMock.data.push({
      ...device,
      id: Math.random() + '',
      manufacturedAt: new Date().toISOString(),
      name: 'NewDummyDevice',
    });
    return mockedResponse({});
  }
  return API.post('/devices', device)
    .then((response) => {
      return true; // response.data.data.sort((a, b) => (a.name > b.name ? 1 : -1));
    })
    .catch(function (error) {
      throw error;
    });
}

export async function createVirtualDevice() {
  if (newDeviceUseMock) {
    newDevicesMock.data.push({
      serialNumber: Math.random() + '',
      id: Math.random() + '',
      manufacturedAt: new Date().toISOString(),
      name: 'NewDummyDevice',
    });
    return mockedResponse({});
  }
  return API.post('/devices/virtual')
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      throw error;
    });
}

export async function patchDevice(id, payload) {
  if (useMock) {
    const device = devicesMock.data.filter((d) => d.id === id);
    if (device.length > 0) {
      Object.entries(payload).forEach(([key, value]) => {
        device[0][key] = value;
      });
      device[0].savedConfigVersion++;
    }
    if (Math.random() > 0.5) {
      return mockedError('oooooops, some error!');
    }

    return mockedResponse(
      {
        message: 'ok',
      },
      1000,
      2000
    );
  }
  return API.patch('/devices/' + id, JSON.stringify(payload))
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export async function assignDevice(deviceId, organizationId) {
  if (useMock) {
    newDevicesMock.data = newDevicesMock.data.filter((d) => d.id !== deviceId);
    return mockedResponse({
      message: 'ok',
    });
  }
  return API.patch('/devices/' + deviceId + '/assign', {organization: organizationId})
    .then((response) => {
      return response.data.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export async function getAvailableServicesForDevice(deviceId) {
  if (useMock) {
    return mockedResponse(servicesMock.data);
  }
  return API.get('/devices/' + deviceId + '/available_services')
    .then((response) => {
      return response.data.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export async function createIotEdgeDevice(deviceId) {
  if (useMock) {
    return mockedResponse({message: 'No mocked data here.'});
  }
  return API.post('/devices/' + deviceId + '/iot_edge', {})
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
}

export async function getImageStatus(deviceId) {
  if (useMock) {
    return mockedResponse({});
  }
  return API.get('/devices/' + deviceId + '/image/status')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getImage(deviceId) {
  if (useMock) {
    return mockedResponse({});
  }
  return API.get('/devices/' + deviceId + '/image')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function takeImage(deviceId) {
  if (useMock) {
    return mockedResponse({});
  }
  return API.post('/devices/' + deviceId + '/take_image')
    .then((response) => {
      return true;
    })
    .catch((error) => {
      throw error;
    });
}
