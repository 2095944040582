import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  Checkbox,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import {useSnackbar} from 'notistack';

import {createSensorGroup, getSensors, updateSensorGroup} from '../../../api/sensors';

function SensorGroupForm({setIsEditingGroup, selectedGroup, setSelectedGroup}) {
  const {enqueueSnackbar} = useSnackbar();

  const currentOrganization = useSelector((state) => state.session.currentOrganization);
  const [isLoading, setIsLoading] = useState(false);
  const [allSensors, setAllSensors] = useState();
  const [sensorGroupName, setSensorGroupName] = useState(selectedGroup?.name || '');
  const [sensorGroupDescription, setSensorGroupDescription] = useState(selectedGroup?.description || '');
  const [sensorGroupCapacity, setSensorGroupCapacity] = useState(
    !selectedGroup?.capacity || selectedGroup?.capacity === 0 ? '' : selectedGroup?.capacity
  );
  const [checkedSensors, setCheckedSensors] = useState(selectedGroup?.sensorReferences || []);

  const handleToggle = useCallback(
    (value) => {
      const currentSensor = checkedSensors.find((chs) => chs.sensorId === value.sensorId);
      const newChecked = [...checkedSensors];

      if (!currentSensor) {
        newChecked.push(value);
      } else {
        const currentIndex = checkedSensors.indexOf(currentSensor);
        newChecked.splice(currentIndex, 1);
      }

      setCheckedSensors(newChecked);
    },
    [checkedSensors]
  );

  const handleEditSensorGroup = useCallback(() => {
    const payload = {
      id: selectedGroup.id,
      organizationId: currentOrganization.id,
    };
    if (selectedGroup.name !== sensorGroupName) {
      payload.name = sensorGroupName;
    }
    if (selectedGroup.description !== sensorGroupDescription) {
      payload.description = sensorGroupDescription;
    }

    if (selectedGroup.sensorReferences !== checkedSensors) {
      payload.sensorReferences = checkedSensors;
    }
    if (selectedGroup.capacity !== sensorGroupCapacity) {
      payload.capacity = Number.isInteger(+sensorGroupCapacity) ? sensorGroupCapacity : 0;
    }

    setIsLoading(true);
    updateSensorGroup(payload)
      .then((data) => {
        enqueueSnackbar(`Sensor group ${sensorGroupName} updated successfully`, {
          variant: 'success',
        });
        setSelectedGroup(data);
      })
      .catch((error) => {
        enqueueSnackbar('Error updating sensor group: ' + error.message, {
          variant: 'error',
        });
      })
      .finally(() => setIsLoading(false));
  }, [
    checkedSensors,
    currentOrganization.id,
    enqueueSnackbar,
    selectedGroup,
    sensorGroupCapacity,
    sensorGroupDescription,
    sensorGroupName,
    setSelectedGroup,
  ]);

  const handleCreateSensorGroup = useCallback(() => {
    const payload = {
      name: sensorGroupName,
      description: sensorGroupDescription,
      organizationId: currentOrganization.id,
      sensorReferences: checkedSensors,
      capacity: Number.isInteger(+sensorGroupCapacity) ? sensorGroupCapacity : 0,
    };
    setIsLoading(true);
    createSensorGroup(payload)
      .then(() => {
        enqueueSnackbar(`Sensor group ${sensorGroupName} created successfully`, {
          variant: 'success',
        });
        setIsEditingGroup(false);
      })
      .catch((error) => {
        enqueueSnackbar('Error creating sensor group: ' + error.message, {
          variant: 'error',
        });
      })
      .finally(() => setIsLoading(false));
  }, [
    sensorGroupName,
    sensorGroupDescription,
    currentOrganization.id,
    checkedSensors,
    sensorGroupCapacity,
    enqueueSnackbar,
    setIsEditingGroup,
  ]);

  const listContent = useMemo(
    () =>
      allSensors && allSensors.length > 0 ? (
        allSensors.map((s) => (
          <ListItem
            key={s.sensorId}
            button
            sx={{
              '&:hover button': {
                display: 'flex',
              },
            }}
            onClick={() => handleToggle(s)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checkedSensors.some((chs) => chs.sensorId === s.sensorId)}
                tabIndex={-1}
                disableRipple
                inputProps={{'aria-labelledby': s.sensorId}}
              />
            </ListItemIcon>
            <ListItemText
              sx={{
                '& > *': {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                },
              }}
              primary={s.description || s.sensorId}
              secondary={s.type}
            />
          </ListItem>
        ))
      ) : (
        <ListItem>
          <ListItemText primary="There are no sensors yet." />
        </ListItem>
      ),
    [allSensors, checkedSensors, handleToggle]
  );

  const getSensorsList = useCallback(() => {
    setIsLoading(true);
    getSensors()
      .then((response) => {
        setAllSensors(response.data);
      })
      .catch((error) => {
        enqueueSnackbar(`Error getting sensors: ${error.message}`, {variant: 'error'});
      })
      .finally(() => setIsLoading(false));
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (setIsEditingGroup) {
      getSensorsList();
    }
  }, [getSensorsList, setIsEditingGroup]);

  return (
    <Grid item container xs={12} spacing={3}>
      <Grid item xs={12}>
        <Button
          variant="text"
          size="small"
          sx={{
            mr: 1,
            textDecoration: 'none',
          }}
          disableRipple
          startIcon={<ArrowBackIosNewIcon fontSize="small" />}
          onClick={() => {
            setIsEditingGroup(false);
            setSelectedGroup(null);
          }}>
          Cancel
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          variant="filled"
          label="Sensor group name"
          value={sensorGroupName}
          type="text"
          required
          fullWidth
          inputProps={{maxLength: 50}}
          onChange={(event) => {
            setSensorGroupName(event.target.value);
          }}
          sx={{mb: 3}}
        />
        <TextField
          variant="filled"
          label="Sensor group description"
          value={sensorGroupDescription}
          type="text"
          required
          fullWidth
          multiline
          inputProps={{maxLength: 256}}
          rows={4}
          onChange={(event) => {
            setSensorGroupDescription(event.target.value);
          }}
          sx={{mb: 3}}
        />
        <TextField
          id="group-capacity"
          variant="filled"
          label="Sensor group capacity"
          value={sensorGroupCapacity}
          type="number"
          fullWidth
          InputProps={{inputProps: {min: 1}}}
          onChange={(event) => {
            setSensorGroupCapacity(event.target.value);
          }}
        />
      </Grid>
      <Grid item container xs={12} md={6}>
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            border: '1px solid #0000001f',
            borderRadius: 1,
            maxHeight: 300,
            '& ul': {padding: 0},
          }}
          subheader={
            <ListSubheader
              sx={{
                borderBottom: '1px solid #0000001f',
              }}>
              <Grid item container xs={12} alignItems="center" justifyContent="space-between">
                Sensors
              </Grid>
            </ListSubheader>
          }>
          {isLoading ? <LinearProgress /> : listContent}
        </List>
      </Grid>
      <Grid item xs={12} align="right">
        <Button
          variant="text"
          size="small"
          sx={{
            mr: 1,
          }}
          onClick={() => {
            setIsEditingGroup(false);
            setSelectedGroup(null);
          }}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          mr={2}
          size="small"
          disabled={
            selectedGroup
              ? isLoading ||
                (sensorGroupName === selectedGroup.name &&
                  sensorGroupDescription === selectedGroup.description &&
                  JSON.stringify(checkedSensors) === JSON.stringify(selectedGroup.sensorReferences) &&
                  sensorGroupCapacity === selectedGroup.capacity)
              : !sensorGroupName || !sensorGroupDescription || isLoading
          }
          onClick={selectedGroup ? handleEditSensorGroup : handleCreateSensorGroup}>
          {selectedGroup ? 'Save' : 'Create'}
        </Button>
      </Grid>
    </Grid>
  );
}

export default SensorGroupForm;
