import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormControl, InputLabel, ListItemText, MenuItem, Select} from '@mui/material';
import Grid from '@mui/material/Grid';
import {refreshCameras} from 'helpers';
import {useSnackbar} from 'notistack';

const CameraConfig = ({cameraId, setCameraId}) => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();

  const camerasList = useSelector((state) => state.cameras.camerasList);

  const camerasDropdown = useMemo(
    () =>
      camerasList.map((camera) => (
        <MenuItem key={camera.id} value={camera.id}>
          <ListItemText primary={camera.name} />
        </MenuItem>
      )),
    [camerasList]
  );

  const hanldeCameraChange = useCallback((event) => setCameraId(event.target.value), [setCameraId]);

  useEffect(() => refreshCameras(dispatch, enqueueSnackbar), [dispatch, enqueueSnackbar]);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <FormControl variant="standard" fullWidth>
          <InputLabel id="camera-label">Connected camera</InputLabel>
          <Select
            labelId="camera-label"
            id="camera-select"
            value={cameraId}
            label="Camera"
            onChange={hanldeCameraChange}>
            <MenuItem key={'none'} value={''}>
              <ListItemText primary="Select camera" />
            </MenuItem>
            {camerasDropdown}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};

export default CameraConfig;
