import React from 'react';
import {withRouter} from 'react-router-dom';
import {Link as RouterLink} from 'react-router-dom';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

function NavigationToolbar(props) {
  const {routes} = props;

  return (
    <Toolbar>
      {routes.map((route, i, arr) => {
        if (arr.length - 1 === i) {
          // last one
          return (
            // TODO don't use index as key
            <Typography key={i} variant="h2" component="span">
              {route}
            </Typography>
          );
        } else {
          // not last one
          return (
            // TODO don't use index as key
            <Typography key={i} variant="h2" component="span">
              <RouterLink
                to={'/managers'}
                sx={{
                  textDecoration: 'none',
                  color: 'inherit',
                }}>
                {route}
              </RouterLink>
              <ArrowRightIcon />
            </Typography>
          );
        }
      })}
    </Toolbar>
  );
}

export default withRouter(NavigationToolbar);
