import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Trans} from '@lingui/macro';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useSnackbar} from 'notistack';

import * as devices from '../../api/devices';
import {HeaderButton, HeaderButtonRefresh} from '../../components/HeaderButtons';
import Modal from '../../components/Modal';
import {withAuthorization} from '../../oauth/Session';
import {setIsLoadingDevices, setNewDevices} from '../../redux/devices.reducer';
import * as Conditions from '../../routes/Conditions';

import AssignDeviceForm from './AssignDeviceForm';
import CreateCameraForm from './CreateCameraForm';
import CreatePhysicalDeviceForm from './CreatePhysicalDeviceForm';
import NewDevicesTable from './NewDevicesTable';

const CreateHardwarePage = (props) => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const newDevices = useSelector((state) => state.devices.newDevices);
  const currentOrganization = useSelector((state) => state.session.currentOrganization);
  const credentials = useSelector((state) => state.credentials);
  const isLoadingDevices = useSelector((state) => state.devices.isLoadingDevices);
  const [createFSUModalOpen, setCreateFSUModalOpen] = useState(false);
  const [createCameraModalOpen, setCreateCameraModalOpen] = useState(false);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [deviceToAssign, setDeviceToAssign] = useState({id: 0});

  const refresh = useCallback(() => {
    dispatch(setIsLoadingDevices(true));
    devices
      .getNewDevices(currentOrganization)
      .then((data) => {
        dispatch(setNewDevices(data));
      })
      .catch((e) => {
        enqueueSnackbar('Error fetching devices: ' + e.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        dispatch(setIsLoadingDevices(false));
      });
  }, [dispatch, enqueueSnackbar, currentOrganization]);

  const createVirtualDevice = () => {
    setIsWorking(true);
    devices
      .createVirtualDevice()
      .then((response) => {
        refresh();
        enqueueSnackbar(`Created a virtul device with serial number ${response.serialNumber}!`, {
          variant: 'success',
        });
      })
      .catch((e) => {
        enqueueSnackbar('Error creating device: ' + e.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsWorking(false);
      });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item container xs={12} alignItems="center">
          <Typography variant="h4" sx={{mr: 2}}>
            Create hardware
          </Typography>
          <HeaderButtonRefresh
            isLoading={isLoadingDevices}
            onClick={() => {
              refresh();
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <HeaderButton
            size="small"
            color="primary"
            variant="contained"
            onClick={() => {
              setCreateCameraModalOpen(true);
            }}>
            <Trans>New camera</Trans>
          </HeaderButton>
          {credentials.userTypes?.includes(Conditions.FLOWITY_USER) && (
            <>
              <HeaderButton
                size="small"
                color="primary"
                variant="contained"
                onClick={() => {
                  setCreateFSUModalOpen(true);
                }}>
                <Trans>New FSU</Trans>
              </HeaderButton>
              <HeaderButton
                size="small"
                color="primary"
                variant="contained"
                disabled={isWorking}
                startIcon={isWorking && <CircularProgress size={18} />}
                onClick={createVirtualDevice}>
                <Trans>New server</Trans>
              </HeaderButton>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          {newDevices.length > 0 ? (
            <NewDevicesTable
              title="Devices without organization"
              assignDevice={(device) => {
                setDeviceToAssign(device);
                setIsAssignModalOpen(true);
              }}
            />
          ) : (
            <Typography variant="body1">There are no unassigned devices.</Typography>
          )}
        </Grid>
        <Modal
          isOpen={createCameraModalOpen}
          onClose={() => {
            setCreateCameraModalOpen(false);
          }}>
          <CreateCameraForm
            onClose={() => {
              setCreateCameraModalOpen(false);
            }}
          />
        </Modal>
        <Modal
          isOpen={createFSUModalOpen}
          onClose={() => {
            setCreateFSUModalOpen(false);
          }}>
          <CreatePhysicalDeviceForm
            refresh={refresh}
            onClose={() => {
              setCreateFSUModalOpen(false);
            }}
          />
        </Modal>
        {credentials.userTypes?.includes(Conditions.FLOWITY_USER) && (
          <Modal
            isOpen={isAssignModalOpen}
            onClose={() => {
              setIsAssignModalOpen(false);
            }}>
            <AssignDeviceForm
              device={deviceToAssign}
              refresh={refresh}
              onClose={() => {
                setIsAssignModalOpen(false);
              }}
            />
          </Modal>
        )}
      </Grid>
    </>
  );
};

export default withAuthorization(Conditions.ANY)(CreateHardwarePage);
