import React, {useState} from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import Button from '../../../components/Button';
import {decorateDevice} from '../helpers';

function DeviceStatusCard({device}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const nowSeconds = parseInt(new Date(Date.now()).getTime() / 1000);

  const deviceCopy = {...decorateDevice(device, nowSeconds)};

  const additionalProperties = [
    ['Model', device.model],
    ['Hardware Version', device.hardwareVersion],
    ['Device Id', device.id],
    ['MAC Address', device.macAddress],
    ['Serial Number', device.serialNumber],
    ['Saved Config Version', device.savedConfigVersion],
    ['Running Config Version', device.runningConfigVersion],
    //['Fixed IP', config.ipAddress],
  ];

  return (
    <>
      <Grid container>
        <TableContainer component={Paper} elevation={1}>
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{
                  height: 60,
                }}>
                <TableCell colSpan="2">
                  <Typography
                    sx={{
                      fontWeight: 600,
                    }}>
                    Status
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key="Status"
                sx={{
                  height: 60,
                }}>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">{deviceCopy._.deviceStatus}</TableCell>
              </TableRow>
              <TableRow
                key="Started"
                sx={{
                  height: 60,
                }}>
                <TableCell align="left">Device Started</TableCell>
                <TableCell align="left">{deviceCopy._.lastBooting.toLocaleString('sv-SE')}</TableCell>
              </TableRow>
              <TableRow
                key="Heartbeat"
                sx={{
                  height: 60,
                }}>
                <TableCell align="left">Last Heartbeat</TableCell>
                <TableCell align="left">{deviceCopy._.lastHeartbeat.toLocaleString('sv-SE')}</TableCell>
              </TableRow>
              {isExpanded &&
                additionalProperties.map((entry) => (
                  <TableRow
                    key={entry[0]}
                    sx={{
                      height: 60,
                    }}>
                    <TableCell align="left">{entry[0]}</TableCell>
                    <TableCell align="left">{entry[1]}</TableCell>
                  </TableRow>
                ))}
              <TableRow key="expand">
                <TableCell
                  colSpan="2"
                  sx={{
                    p: 0,
                    '&:last-child': {
                      pr: 0,
                    },
                  }}>
                  <Button
                    variant="text"
                    endIcon={isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    fullWidth
                    sx={{
                      textTransform: 'none',
                    }}
                    onClick={() => {
                      setIsExpanded(!isExpanded);
                    }}>
                    {isExpanded ? 'Show less' : 'Show more'}
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}

export default DeviceStatusCard;
