import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FileDownloadIcon from '@mui/icons-material/ArrowDownward';
import {FormControl, FormHelperText, MenuItem, Select} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {DateTimePicker} from '@mui/x-date-pickers';
import {getCSV} from 'api/measurements';
import {localDownload, refreshDevices} from 'helpers';
import moment from 'moment';
import {useSnackbar} from 'notistack';
import {withAuthorization} from 'oauth/Session';
import * as Conditions from 'routes/Conditions';

import Button from 'components/Button';

const DataReportsPage = () => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const devicesList = useSelector((state) => state.devices.devicesList);
  const currentOrganization = useSelector((state) => state.session.currentOrganization);
  const [deviceId, setDeviceId] = useState(0);
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());
  const [aggregationWindow, setAggregationWindow] = useState(60);
  const [isDownloading, setIsDownloading] = useState(false);

  const refresh = useCallback(() => {
    refreshDevices(dispatch, enqueueSnackbar);
  }, [dispatch, enqueueSnackbar]);

  const getCSVCallback = useCallback(() => {
    if (deviceId === 0) {
      enqueueSnackbar('Please, select at least one device', {variant: 'error'});
      return;
    }
    if (toDate.isSameOrBefore(fromDate) || toDate.diff(fromDate, 'seconds') < aggregationWindow) {
      enqueueSnackbar('Please, provide a valid From and To interval', {variant: 'error'});
      return;
    }
    setIsDownloading(true);
    const payload = {
      deviceIds: [deviceId],
      from: fromDate.toISOString(),
      to: toDate.toISOString(),
      aggregationWindow,
    };
    getCSV(payload)
      .then((data) => {
        const filename =
          'flowity-' + deviceId + '-' + payload.from.substring(0, 10) + '-' + payload.to.substring(0, 10) + '.csv';
        localDownload(data, filename);
      })
      .catch((error) => {
        enqueueSnackbar('Error creating file: ' + error.message, {variant: 'error'});
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }, [deviceId, fromDate, toDate, aggregationWindow, enqueueSnackbar]);

  const downloadIcon = useCallback(() => {
    if (isDownloading) {
      return <CircularProgress size={18} />;
    }
    return <FileDownloadIcon />;
  }, [isDownloading]);

  useEffect(() => {
    setDeviceId(0);
    refresh();
  }, [currentOrganization, refresh]);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">Reports</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Select
              variant="filled"
              labelId="deviceId-label"
              aria-describedby="helper-deviceId"
              id="deviceId-select"
              value={deviceId}
              label="Device ID"
              onChange={(event) => {
                setDeviceId(event.target.value);
              }}>
              <MenuItem value={0}>Select a device</MenuItem>
              {devicesList.map((device) => {
                return (
                  <MenuItem key={device.id} value={device.id}>
                    {device.name}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText id="helper-deviceId">Select the device to request data for</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Select
              variant="filled"
              labelId="aggregation-label"
              aria-describedby="helper-aggregation"
              id="aggregation-select"
              value={aggregationWindow}
              label="Aggregation window"
              onChange={(event) => {
                setAggregationWindow(event.target.value);
              }}>
              <MenuItem value={1}>1 second</MenuItem>
              <MenuItem value={60}>1 minute</MenuItem>
              <MenuItem value={300}>5 minutes</MenuItem>
              <MenuItem value={600}>10 minutes</MenuItem>
              <MenuItem value={3600}>1 hour</MenuItem>
            </Select>
            <FormHelperText id="helper-aggregation">
              Select aggregation window, i.e. the resolution of the data.
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} variant="filled" />}
              value={fromDate}
              disableFuture
              ampm={false}
              onChange={setFromDate}
              label="From"
              showTodayButton
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} variant="filled" />}
              value={toDate}
              disableFuture
              ampm={false}
              onChange={setToDate}
              label="To"
              showTodayButton
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Button
            startIcon={downloadIcon()}
            disabled={isDownloading}
            variant="contained"
            onClick={(event) => {
              getCSVCallback();
            }}>
            Download CSV
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default withAuthorization(Conditions.ANY)(DataReportsPage);
