import React, {useContext} from 'react';
import {Trans} from '@lingui/macro';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {AuthorizationContext} from 'oauth/Session/context';

function NoAccess(props) {
  const credentials = useContext(AuthorizationContext);
  return (
    <>
      <Toolbar />
      <Paper
        sx={{
          padding: 2,
          display: 'flex',
          overflow: 'auto',
          flexDirection: 'column',
        }}>
        <Typography variant="body1">
          <Trans>
            You do not have access to this page, if you believe this is incorrect, please contact example@example.com
          </Trans>
        </Typography>
        <Typography variant="body2">Credentials: {JSON.stringify(credentials)}</Typography>
      </Paper>
    </>
  );
}

export default NoAccess;
