import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link as RouterLink} from 'react-router-dom';
import {Trans} from '@lingui/macro';
import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import {refreshCameras, refreshDeployments, refreshDevices} from 'helpers';
import {getDeviceStatus} from 'helpers';
import moment from 'moment';
import {useSnackbar} from 'notistack';

import {StyledErrorIcon, StyledOkIcon, StyledUnknownIcon, StyledWarningIcon} from 'components/Icons';

const MeasurementsTable = ({deviceFilters}) => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();

  const currentOrganization = useSelector((state) => state.session.currentOrganization.id);
  const deploymentsList = useSelector((state) => state.deployments.deploymentsList);
  const camerasList = useSelector((state) => state.cameras.camerasList);
  const devicesList = useSelector((state) => state.devices.devicesList);
  const isLoadingDeployments = useSelector((state) => state.deployments.isLoadingDeployments);

  const filteredDeployments = useMemo(() => {
    if (deviceFilters.length > 0) {
      return deploymentsList.filter((deployment) => {
        const device = devicesList.find((device) => device.id === deployment.deviceId);
        return deviceFilters.includes(getDeviceStatus(device));
      });
    } else {
      return deploymentsList;
    }
  }, [deploymentsList, deviceFilters, devicesList]);

  const sortedDeploymentList = useMemo(
    () =>
      filteredDeployments.reduce((result, currentDeployment) => {
        // find camera array
        const cameraIndex = result.findIndex((array) => array[0][0]?.cameraId === currentDeployment.cameraId);

        if (cameraIndex >= 0) {
          // find device array
          const deviceIndex = result[cameraIndex].findIndex(
            (array) => array[0]?.deviceId === currentDeployment.deviceId
          );
          if (deviceIndex >= 0) {
            result[cameraIndex][deviceIndex].push(currentDeployment);
          } else {
            // if device array does not exist yet
            result[cameraIndex][result[cameraIndex].length] = [currentDeployment];
          }
        } else {
          // if camera array does not exist yet
          result[result.length] = [[currentDeployment]];
        }
        return result;
      }, []),
    [filteredDeployments]
  );

  const getDeploymentStatus = useCallback((deployment, deviceStatus) => {
    if (!deployment.cameraId) {
      return (
        <div>
          <StyledUnknownIcon />
          &nbsp;Not configured
        </div>
      );
    }
    if (deviceStatus !== 'Online') {
      return '-';
    }
    switch (deployment.runtimeStatus) {
      case 'RUNNING':
        return (
          <Tooltip
            title={moment(deployment.lastSeen).format('YYYY-MM-DD HH:mm:ss')}
            arrow
            key={deployment.id + '-runtimeStatus'}>
            <div>
              <StyledOkIcon />
              &nbsp;Streaming
            </div>
          </Tooltip>
        );
      case 'STOPPED':
        return (
          <Tooltip
            title={moment(deployment.lastSeen).format('YYYY-MM-DD HH:mm:ss')}
            arrow
            key={deployment.id + '-runtimeStatus'}>
            <div>
              <StyledWarningIcon />
              &nbsp;{deployment.runtimeStatus.charAt(0) + deployment.runtimeStatus.slice(1).toLowerCase()}
            </div>
          </Tooltip>
        );
      default:
        // 'ERROR' or null
        return (
          <div key={deployment.id + '-runtimeStatus'}>
            <StyledErrorIcon /> &nbsp;
            {deployment.runtimeStatus.charAt(0) + deployment.runtimeStatus.slice(1).toLowerCase()}
          </div>
        );
    }
  }, []);

  const measurementsBody = useMemo(() => {
    if (deploymentsList.length === 0 && isLoadingDeployments) {
      return (
        <TableRow
          sx={{
            height: 75,
          }}>
          <TableCell align="center" colSpan={7}>
            <LinearProgress />
          </TableCell>
        </TableRow>
      );
    }
    if (deploymentsList.length === 0 && !isLoadingDeployments) {
      return (
        <TableRow
          sx={{
            height: 75,
          }}>
          <TableCell align="center" colSpan={7}>
            There are no measurements.
          </TableCell>
        </TableRow>
      );
    }
    return sortedDeploymentList.map((cameraGroup) =>
      cameraGroup.map((deviceGroup, deviceIndex) =>
        deviceGroup.map((deployment, deploymentIndex) => {
          const relatedDevice = devicesList.find((device) => device.id === deployment.deviceId);
          if (!relatedDevice) {
            return null;
          }
          const deviceStatus = getDeviceStatus(relatedDevice);
          const deviceStatusTooltip =
            deviceStatus !== 'Online'
              ? moment(relatedDevice.lastHeartbeat || '2000-01-01').format('MMM Do yyyy, HH:mm:ss')
              : '';
          return (
            <TableRow
              key={deployment.id}
              sx={{
                height: 75,
              }}>
              {deviceIndex === 0 && deploymentIndex === 0 && (
                <TableCell align="left" rowSpan={cameraGroup.flat().length}>
                  {camerasList.find((camera) => camera.id === deployment.cameraId)?.name || '-'}
                </TableCell>
              )}
              {deploymentIndex === 0 && (
                <TableCell align="left" rowSpan={deviceGroup.length} sx={{display: {xs: 'none', sm: 'table-cell'}}}>
                  {relatedDevice.name}
                </TableCell>
              )}
              {deploymentIndex === 0 && (
                <TableCell align="left" rowSpan={deviceGroup.length} sx={{display: {xs: 'none', md: 'table-cell'}}}>
                  {relatedDevice.deviceType === 'FSU' ? relatedDevice.deviceType : 'Server'}
                </TableCell>
              )}
              <TableCell align="left">
                <Tooltip title={deviceStatusTooltip} arrow>
                  <Chip label={deviceStatus} color="default" />
                </Tooltip>
              </TableCell>
              <TableCell align="left" sx={{width: '116px'}}>
                {getDeploymentStatus(deployment, deviceStatus)}
              </TableCell>
              <TableCell align="left">{deployment.name}</TableCell>
              <TableCell align="right">
                <RouterLink to={'/deployments/' + deployment.id} style={{textDecoration: 'none'}}>
                  <Button variant="contained" color="primary" size="small">
                    <Trans>Config</Trans>
                  </Button>
                </RouterLink>
              </TableCell>
            </TableRow>
          );
        })
      )
    );
  }, [
    camerasList,
    deploymentsList.length,
    devicesList,
    getDeploymentStatus,
    isLoadingDeployments,
    sortedDeploymentList,
  ]);

  const refresh = useCallback(() => {
    refreshDevices(dispatch, enqueueSnackbar);
    refreshDeployments(dispatch, enqueueSnackbar);
  }, [dispatch, enqueueSnackbar]);

  useEffect(() => {
    // making sure we get latest info for status tooltips
    refreshCameras(dispatch, enqueueSnackbar);
    const interval = setInterval(refresh, 60000);
    refresh();
    return () => clearInterval(interval);
  }, [currentOrganization, refresh, dispatch, enqueueSnackbar]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Camera view</TableCell>
                <TableCell align="left" sx={{display: {xs: 'none', sm: 'table-cell'}}}>
                  Processing device
                </TableCell>
                <TableCell align="left" sx={{display: {xs: 'none', md: 'table-cell'}}}>
                  Type
                </TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Data</TableCell>
                <TableCell align="left">Deployment</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{measurementsBody}</TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default MeasurementsTable;
