import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import {Alert} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {refreshDeployments, refreshDevices} from 'helpers';
import {useSnackbar} from 'notistack';

import {toggleDeployment} from '../../../api/deployments';

const DeploymentGlance = ({deployment, active}) => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const currentOrganization = useSelector((state) => state.session.currentOrganization);
  const [isActivating, setIsActivating] = useState(false);

  const refresh = useCallback(() => {
    refreshDevices(dispatch, enqueueSnackbar, currentOrganization);
    refreshDeployments(dispatch);
  }, [currentOrganization, dispatch, enqueueSnackbar]);

  useEffect(() => {
    const interval = setInterval(refresh, 60000);
    refresh();
    return () => clearInterval(interval);
  }, [refresh]);

  const handleToggleDeployment = () => {
    setIsActivating(true);
    toggleDeployment(deployment.id, {isEnabled: !deployment.isEnabled})
      .catch((error) => {
        enqueueSnackbar('Error editing deployment: ' + error.response.data.message, {variant: 'error'});
      })
      .finally(() => {
        setIsActivating(false);
        refresh();
      });
  };
  return (
    <>
      <Grid container>
        <TableContainer component={Paper} elevation={1}>
          <Table size="small">
            <TableHead>
              <TableRow
                sx={{
                  height: 60,
                }}>
                <TableCell colSpan="2">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                      }}>
                      {deployment.name}
                    </Typography>
                    <Link to={'/deployments/' + deployment.id} style={{textDecoration: 'none'}}>
                      <IconButton aria-label="edit device" size="large">
                        <EditIcon />
                      </IconButton>
                    </Link>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key="Name"
                sx={{
                  height: 60,
                }}>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">{deployment.name}</TableCell>
              </TableRow>
              <TableRow
                key="Position"
                sx={{
                  height: 60,
                }}>
                <TableCell align="left">View</TableCell>
                <TableCell align="left">{deployment.sensorView}</TableCell>
              </TableRow>
              <TableRow
                key="Status"
                sx={{
                  height: 60,
                }}>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">
                  {deployment.isEnabled
                    ? deployment.runtimeStatus.charAt(0) + deployment.runtimeStatus.slice(1).toLowerCase()
                    : 'Inactive'}
                  {deployment.jcoreConfigUpdateStatusSuccessful === false && (
                    <Alert severity="error">{deployment.jcoreConfigUpdateStatusMessage}</Alert>
                  )}
                </TableCell>
              </TableRow>
              <TableRow key="activate">
                <TableCell
                  colSpan="2"
                  sx={{
                    height: 60,
                    textAlign: 'center',
                  }}>
                  <Button
                    startIcon={isActivating && <CircularProgress size={18} />}
                    disabled={isActivating}
                    color="primary"
                    variant="contained"
                    onClick={handleToggleDeployment}>
                    {active ? 'Deactivate' : 'Activate'}
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default DeploymentGlance;
