import React, {useContext} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Trans} from '@lingui/macro';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {compose} from 'recompose';

import {getUserRoles} from '../../../api/users';
import NavigationToolbar from '../../../components/NavigationToolbar';
import Table from '../../../components/Table';
import {UserContext, withAuthorization} from '../../../oauth/Session';
import * as Conditions from '../../../routes/Conditions';

function UserRoles(props) {
  const {currentOrganization} = useContext(UserContext);

  return (
    <>
      <NavigationToolbar routes={[<Trans>Users</Trans>, <Trans>User Roles</Trans>]} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              overflow: 'auto',
              flexDirection: 'column',
              height: 240,
            }}>
            <Trans>User roles in the organization {currentOrganization}</Trans>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              overflow: 'auto',
              flexDirection: 'column',
              height: 240,
            }}></Paper>
        </Grid>
        <Grid item xs={12}>
          <Toolbar>
            <Typography variant="h3">
              <Trans>User Roles</Trans>
            </Typography>
          </Toolbar>
          <Table
            tableHeaders={[
              <Trans>Role</Trans>,
              <Trans>Role id</Trans>,
              <Trans>Permissions</Trans>,
              <Trans>Actions</Trans>,
            ]}
            dataSource={getUserRoles}
            tableRows={(data) => {
              return data.map((row) => (
                <TableRow key={row.uid} hover>
                  <TableCell align="left" scope="row">
                    {row.first_name} {row.last_name}
                  </TableCell>
                  <TableCell align="left">{row.role_name}</TableCell>
                  <TableCell align="left">{row.rid}</TableCell>
                  <TableCell align="left">{row.permissions}</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      p: 0,
                    }}>
                    <Button
                      color="primary"
                      size="small"
                      component={RouterLink}
                      to={'users/roles/' + row.rid}
                      variant="outlined"
                      aria-label="view user role"
                      sx={{
                        m: 0,
                        ml: 1,
                      }}>
                      <Trans>View</Trans>
                    </Button>
                    <Button
                      color="primary"
                      size="small"
                      variant="outlined"
                      aria-label="remove user role"
                      sx={{
                        m: 0,
                        ml: 1,
                      }}>
                      <Trans>Remove</Trans>
                    </Button>
                  </TableCell>
                </TableRow>
              ));
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default compose(withAuthorization(Conditions.ANY))(UserRoles);
