import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {refreshCameras, refreshDevices} from 'helpers';
import {useSnackbar} from 'notistack';

import FlexiblePaper from '../../components/FlexiblePaper';
import {HeaderButtonRefresh} from '../../components/HeaderButtons';
import InformationalLoader from '../../components/Progress/InformationalLoader';
import {withAuthorization} from '../../oauth/Session';
import * as Conditions from '../../routes/Conditions';
import * as ROUTES from '../../routes/Routes';

import CameraTable from './components/CameraTable';
import DeviceTable from './components/DeviceTable';

const HardwarePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();

  const currentOrganization = useSelector((state) => state.session.currentOrganization);
  const devicesList = useSelector((state) => state.devices.devicesList);

  const isLoadingDevices = useSelector((state) => state.devices.isLoadingDevices);
  const isLoadingDeployments = useSelector((state) => state.deployments.isLoadingDeployments);
  const devicesErrorCode = useSelector((state) => state.devices.devicesErrorCode);

  const refresh = useCallback(() => {
    refreshDevices(dispatch, enqueueSnackbar, currentOrganization);
  }, [dispatch, enqueueSnackbar, currentOrganization]);

  useEffect(() => {
    const interval = setInterval(refresh, 60000);
    refreshCameras(dispatch, enqueueSnackbar, currentOrganization);
    refresh();
    return () => clearInterval(interval);
  }, [currentOrganization, dispatch, enqueueSnackbar, refresh]);

  const getDeviceTable = useCallback(() => {
    if (devicesList.length === 0 && !isLoadingDevices) {
      return <FlexiblePaper>No devices!</FlexiblePaper>;
    }
    if (devicesList.length === 0 && isLoadingDevices) {
      return <InformationalLoader message="Loading your devices..." />;
    }

    if (devicesErrorCode !== '') {
      return (
        <Grid item xs={12}>
          <Alert severity="error">Error loading devices: {devicesErrorCode}</Alert>
        </Grid>
      );
    }
    return <DeviceTable />;
  }, [devicesErrorCode, devicesList.length, isLoadingDevices]);

  return (
    <>
      <Grid container>
        <Grid item container xs={12} md={12} alignItems="center">
          <Typography
            variant="h4"
            sx={{
              mr: 2,
            }}>
            Hardware
          </Typography>
          <Button
            size="small"
            color="primary"
            variant="contained"
            sx={{
              height: 30,
              mr: 2,
            }}
            onClick={() => history.push(ROUTES.CREATE_DEVICE)}>
            New hardware
          </Button>
          <HeaderButtonRefresh
            isLoading={isLoadingDeployments || isLoadingDevices}
            onClick={(event) => {
              refresh();
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{pt: 3}}>
          <CameraTable />
        </Grid>
        <Grid item xs={12} sx={{pt: 3}}>
          {getDeviceTable()}
        </Grid>
      </Grid>
    </>
  );
};

export default withAuthorization(Conditions.ANY)(HardwarePage);
