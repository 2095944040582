const initialState = {
  configuratorItems: [],
  idCounter: 0,
  polygons: [
    {
      id: 'polygon-123',
      shapeType: 'polygon',
      coordinates: [
        [0, 0.1],
        [0.5, 0.6],
        [0.4, 1],
      ],
      isEditable: false,
    },
    {
      id: 'line-321',
      shapeType: 'line',
      coordinates: [
        [0.1, 0.1],
        [0.5, 0.5],
        [0.9, 0.1],
      ],
      isEditable: false,
    },
  ],
};

export const addItem = (name) => ({
  type: 'CONFIGURATOR_ADD_ITEM',
  payload: {name: name},
});

export const updatePolygon = (polygon, index) => ({
  type: 'CONFIGURATOR_UPDATE_POLYGON',
  payload: {...polygon, coordinates: Object.assign([], polygon.coordinates)},
});
export const addPolygon = (polygon) => ({
  type: 'CONFIGURATOR_ADD_POLYGON',
  payload: Object.assign({}, polygon),
});
export const deletePolygon = (polygon) => ({
  type: 'CONFIGURATOR_DELETE_POLYGON',
  payload: {id: polygon.id},
});
export const setPolygons = (polygons) => ({
  type: 'CONFIGURATOR_SET_POLYGONS',
  payload: polygons,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'CONFIGURATOR_ADD_ITEM':
      const item = {
        ...action.payload,
        id: state.idCounter,
      };
      return {
        configuratorItems: [...state.configuratorItems, item],
        idCounter: state.idCounter + 1,
        polygons: state.polygons,
      };
    case 'CONFIGURATOR_UPDATE_POLYGON':
      return {
        configuratorItems: state.configuratorItems,
        idCounter: state.idCounter,
        polygons: state.polygons.map((polygon) => {
          if (polygon.id === action.payload.id) {
            if (action.payload.newId) {
              return {...action.payload, id: action.payload.newId, newId: undefined};
            } else {
              return {...action.payload};
            }
          } else {
            return polygon;
          }
        }),
      };
    case 'CONFIGURATOR_ADD_POLYGON':
      return {
        configuratorItems: state.configuratorItems,
        idCounter: state.idCounter,
        polygons: [
          ...state.polygons.map((polygon) => {
            return {...polygon, isEditable: false};
          }),
          action.payload,
        ],
      };
    case 'CONFIGURATOR_DELETE_POLYGON':
      return {
        configuratorItems: state.configuratorItems,
        idCounter: state.idCounter,
        polygons: state.polygons.filter((polygon) => polygon.id !== action.payload.id),
      };
    case 'CONFIGURATOR_SET_POLYGONS':
      return {...state, polygons: action.payload};
    default:
      return state;
  }
}
