import {createChartDataPoints} from 'pages/dashboard/helpers';

import {API} from './api';
import {chartMock, mockedResponse} from './mock';

const useMock = false;
const useTestFile = false;

export async function getCSV(payload) {
  if (useMock) {
    if (!useTestFile) {
      return mockedResponse('row1\tcolumn2\tcolumn3\nrow2\tcolumn2\tcolumn3', 2000, 5000);
    } else {
      return API.get('https://sample-videos.com/csv/Sample-Spreadsheet-500000-rows.csv')
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    }
  }

  return API.post('/measurements', payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getJSONForSensor(payload) {
  if (useMock) {
    return mockedResponse(chartMock);
  }

  return API.post('/measurements/sensor', payload)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getJSONForGroup(payload) {
  if (useMock) {
    return mockedResponse([
      {
        timestamp: '2019-08-24T14:15:22Z',
        presenceCount: 0,
      },
    ]);
  }

  return API.post('/groupmeasurements/presencedata', payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getCSVForGroup(payload) {
  return API.post('/groupmeasurements/presencedata_csv', payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getArguments() {
  if (useMock) {
    return mockedResponse(chartMock);
  }

  return API.get('/measurements/sensor/arguments')
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getPlayback(payload) {
  return API.post('/measurements/tracks', payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function getFlowJSON(payload) {
  if (false) {
    const chartDataPoints = createChartDataPoints(
      Date.parse(payload.from),
      Date.parse(payload.to),
      payload.aggregationWindow * 1000
    );
    const data = {};
    data.scanData = [];
    const sensors = ['Lärkvägen', 'Sverigegatan', 'Södergatan North', 'Södergatan South'];
    const classes = ['car', 'person', 'bus', 'bicycle'];
    for (let i = 0; i < 300; i++) {
      const randomTime = chartDataPoints[Math.floor(Math.random() * chartDataPoints.length)].start;
      data.scanData.push({
        trackId: i,
        class: classes[Math.floor(Math.random() * classes.length)],
        matchType: 'matched',
        entry: {
          sensor: sensors[Math.floor(Math.random() * sensors.length)],
          timestamp: new Date(randomTime).toISOString(),
        },
        exit: {
          sensor: sensors[Math.floor(Math.random() * sensors.length)],
          timestamp: new Date(randomTime + payload.aggregationWindow * 100).toISOString(),
        },
      });
    }
    return mockedResponse(data);
  }

  return API.post('/scanmeasurements/entryexits', payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
