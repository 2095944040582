import React from 'react';
import {Layer, Source} from 'react-map-gl';

const TrackShape = ({id, coordinates, color = '#d00', shapeType = 'line'}) => {
  const geojson = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString', // Polygon / LineString
          coordinates,
        },
      },
    ],
  };

  const lineStyle = {
    id: 'polygon-' + id,
    type: 'line',
    layout: {},
    paint: {
      'line-color': color,
      'line-width': 1.5,
      'line-opacity': 1,
    },
  };

  return (
    <Source id={'polygon-' + id} key={'polygon-' + id} type="geojson" data={geojson}>
      <Layer {...lineStyle} />
    </Source>
  );
};

export default TrackShape;
