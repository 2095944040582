import React from 'react';
import {useSelector, useStore} from 'react-redux';
import {Trans} from '@lingui/macro';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import logoSmall from '../../assets/img/icon.png';
import logo from '../../assets/img/logo.png';
import {toggleDrawerOpen} from '../../redux/ui';

import FooterListItems from './FooterListItems';
import MainListItems from './MainListItems';
// import OrganizationListItems from './OrganizationListItems';
import ProjectListItems from './ProjectListItems';

export const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
  },
  drawerSpace: {
    height: '100vh',
  },
  drawer: {
    flexShrink: 0,
    zIndex: theme.zIndex.appBar + 1,
    position: 'fixed',
  },
  drawerPaper: {
    overflowX: 'hidden',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8),
    },
  },
  logoBox: {
    paddingLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
  },
  logo: {
    height: 30,
  },
  logoSmall: {
    height: 32,
  },
  logoLarge: {
    width: 200,
    height: 'auto',
  },
  footerItems: {
    marginTop: 'auto',
  },
}));

function MainDrawer(props) {
  const classes = useStyles();
  const store = useStore();
  const drawerOpen = useSelector((state) => state.ui.drawer.drawerOpen);
  const credentials = useSelector((state) => state.credentials);

  return (
    <>
      <Box className={clsx(classes.drawerSpace, classes.drawerPaper, !drawerOpen && classes.drawerPaperClose)} />
      <Drawer
        className={clsx(classes.drawerSpace, classes.drawer)}
        anchor="left"
        elevation={16}
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose),
        }}
        open={drawerOpen}>
        {drawerOpen ? (
          <Box p={2}>
            <img src={logo} alt="Logo" className={classes.logoLarge} />
          </Box>
        ) : (
          <Box className={classes.logoBox}>
            <img src={logoSmall} alt="Logo" className={classes.logoSmall} />
          </Box>
        )}
        <Divider />
        {credentials && (
          <List className={classes.list}>
            <ListItem button aria-label="open drawer" onClick={() => store.dispatch(toggleDrawerOpen())}>
              <ListItemIcon>
                <MenuIcon />
              </ListItemIcon>
            </ListItem>
            <MainListItems />
            {/* <Divider /> */}
            {/* <OrganizationListItems /> */}
            {/* <Divider /> */}
            <ProjectListItems />
          </List>
        )}
        <Box className={classes.footerItems}>
          {drawerOpen && (
            <>
              <Typography align="center" variant="overline" display="block">
                <Trans>USER TYPES</Trans>: {credentials && credentials.userTypes && credentials.userTypes.toString()}
              </Typography>
              <Typography align="center" variant="overline" display="block">
                v1.0.23d
              </Typography>
            </>
          )}
          <Divider />
          <FooterListItems />
        </Box>
      </Drawer>
    </>
  );
}

export default MainDrawer;
