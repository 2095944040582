import React, {useState} from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FileIcon from '@mui/icons-material/FileCopy';
import SaveIcon from '@mui/icons-material/Save';
import {FormControl, FormHelperText, TextField} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {useSnackbar} from 'notistack';

import * as devices from '../../api/devices';
import Button from '../../components/Button';

const MACREGEX = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}\\.[0-9a-fA-F]{4}\\.[0-9a-fA-F]{4})$/gim;

const CreatePhysicalDeviceForm = ({refresh, onClose}) => {
  const {enqueueSnackbar} = useSnackbar();

  const [isProcessing, setIsProcessing] = useState(false);
  const [serialNumber, setSerialNumber] = useState('');
  const [macAddress, setMacAddress] = useState('');
  const [model, setModel] = useState('Model 1');
  const [hardwareVersion, setHardwareVersion] = useState('1.0');
  const [certificate, setCertificate] = useState('');

  const createDevice = () => {
    if (serialNumber && serialNumber.length > 5 && macAddress && model && hardwareVersion && certificate) {
      setIsProcessing(true);
      if (!MACREGEX.test(macAddress)) {
        enqueueSnackbar(`Please, provide a valid MAC address!`, {
          variant: 'error',
        });
        setIsProcessing(false);
        return;
      }
      devices
        .createDevice({
          serialNumber,
          macAddress,
          model,
          deviceType: 'FSU',
          hardwareVersion,
          certificate,
        })
        .then(() => {
          setSerialNumber('');
          setCertificate('');
          refresh();
          enqueueSnackbar(`Created ${serialNumber}! Enter more serialNumber's to create more devices!`, {
            variant: 'success',
          });
          onClose();
        })
        .catch((e) => {
          enqueueSnackbar('Error creating device: ' + e.message, {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsProcessing(false);
        });
    } else {
      enqueueSnackbar(`Please, fill in the form and provide a certificate.`, {
        variant: 'error',
      });
    }
  };

  const fileUploadHandler = ({target}) => {
    const fileReader = new FileReader();
    fileReader.readAsText(target.files[0]);
    fileReader.onload = (e) => {
      const data = e.target.result;

      if (data && data.indexOf('BEGIN CERTIFICATE') > 0) {
        setCertificate(data);
        enqueueSnackbar('Certificate file appears to be OK', {
          variant: 'success',
        });
      } else {
        setCertificate('');
        enqueueSnackbar('Make sure this is a real certificate file', {
          variant: 'warning',
        });
      }
    };
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        createDevice();
      }}
      method="post">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">Create Device</Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              label="Serial Number"
              aria-describedby="serial-helper-text"
              variant="filled"
              value={serialNumber}
              required
              autoFocus
              type="text"
              size="small"
              onChange={(event) => {
                setSerialNumber(event.target.value);
              }}
            />
            <FormHelperText id="serial-helper-text">
              Enter the serial number of the device you are creating
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              label="MAC address"
              aria-describedby="mac-helper-text"
              variant="filled"
              value={macAddress}
              required
              type="text"
              size="small"
              onChange={(event) => {
                setMacAddress(event.target.value);
              }}
            />
            <FormHelperText id="mac-helper-text">Enter the MAC address of the new device</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              label="Model"
              aria-describedby="model-helper-text"
              variant="filled"
              value={model}
              required
              type="text"
              size="small"
              onChange={(event) => {
                setModel(event.target.value);
              }}
            />
            <FormHelperText id="model-helper-text">Enter the model of the new device</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              label="Hardware version"
              aria-describedby="hardware-helper-text"
              variant="filled"
              value={hardwareVersion}
              required
              type="text"
              size="small"
              onChange={(event) => {
                setHardwareVersion(event.target.value);
              }}
            />
            <FormHelperText id="hardware-helper-text">Enter the hardware version of the new device</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <input
              accept="*/*"
              id="icon-button-photo"
              type="file"
              onChange={fileUploadHandler}
              style={{display: 'none'}}
            />
            <label htmlFor="icon-button-photo" style={{cursor: 'pointer'}}>
              <IconButton variant="filled" component="span" size="large">
                {certificate === '' ? <FileIcon /> : <CheckIcon style={{color: '#008000'}} />}
              </IconButton>
              &nbsp; {certificate === '' ? 'Select Certificate File' : 'Certificate Selected'}
            </label>

            <FormHelperText id="certificate-helper-text">
              Select a certificate (in .pem format) from your local machine
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} align="right">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={serialNumber.length < 5 || !macAddress || !model || !hardwareVersion || !certificate}
            startIcon={isProcessing ? <CircularProgress color="secondary" size={18} /> : <SaveIcon />}
            mr={2}
            onClick={(e) => {
              e.preventDefault();
              createDevice();
            }}>
            Create
          </Button>
          <Button
            variant="contained"
            startIcon={<CloseIcon />}
            onClick={(event) => {
              if (onClose) {
                onClose();
              }
            }}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreatePhysicalDeviceForm;
