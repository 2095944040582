import {applyMiddleware, compose, createStore} from 'redux';
import logger from 'redux-logger';
import {createMigrate, persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import rootReducer from './redux/root.reducer';

const migrations = {
  0: (state) => {
    // migration clear out device state
    return {
      ...state,
      device: undefined,
    };
  },
  1: (state) => {
    return {
      ...state,
      device: 'test',
    };
  },
};

const rootPersistConfig = {
  key: 'root',
  storage,
  version: 1,
  migrate: createMigrate(migrations, {debug: true}), //https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = () => {
  let store = createStore(persistedReducer, composeEnhancers(applyMiddleware(logger)));
  let persistor = persistStore(store);
  return {store, persistor};
};

export default store;
