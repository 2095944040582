import React from 'react';
import {Layer, Source} from 'react-map-gl';

const Polygon = ({id, coordinates, color = '#d00', clazz}) => {
  const geojson = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Polygon', // Polygon / LineString
          coordinates: [Object.assign([], coordinates)],
        },
        properties: {t: '18C'},
      },
    ],
  };

  const symbolStyle = {
    id: 'polygon-symbol' + id,
    layout: {'icon-image': clazz === 'car' ? 'car-15' : 'pitch-15'},
    type: 'symbol',
  };

  const lineStyle = {
    id: 'polygon-outline' + id,
    type: 'line',
    layout: {},
    paint: {
      'line-color': color,
      'line-width': 2,
      'line-opacity': 0.8,
    },
  };

  //
  return (
    <Source id={'polygon-' + id} key={'polygon-' + id} type="geojson" data={geojson}>
      <Layer {...symbolStyle} />
      <Layer {...lineStyle} />
    </Source>
  );
};

export default Polygon;
