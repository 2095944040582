import React from 'react';
import {Layer, Source} from 'react-map-gl';

const Polygon = ({id, coordinates, color = '#d00', shapeType = 'polygon'}) => {
  const newCoordinates = Object.assign([], coordinates);

  // Auto-close polygons
  if (coordinates.length > 0 && shapeType === 'polygon') {
    const lastCoordinate = coordinates[coordinates.length - 1];
    if (coordinates[0][0] !== lastCoordinate[0] || coordinates[0][1] !== lastCoordinate[1]) {
      newCoordinates.push(coordinates[0]);
    }
  }

  const geojson = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Polygon', // Polygon / LineString
          coordinates: [Object.assign([], newCoordinates)],
        },
        properties: {},
      },
    ],
  };

  const fillStyle = {
    id: 'polygon-fill' + id,
    type: 'fill',
    paint: {
      'fill-color': color,
      'fill-opacity': shapeType === 'polygon' ? 0.3 : 0,
    },
  };

  const lineStyle = {
    id: 'polygon-outline' + id,
    type: 'line',
    layout: {},
    paint: {
      'line-color': color,
      'line-width': 3,
      'line-opacity': shapeType === 'polygon' ? 0.5 : 0.8,
    },
  };

  const pointStyle = {
    id: 'poligon-point' + id,
    type: 'circle',
    paint: {
      'circle-radius': 5,
      'circle-color': color,
      'circle-opacity': 0.8,
    },
  };

  //
  return (
    <Source id={'polygon-' + id} key={'polygon-' + id} type="geojson" data={geojson}>
      <Layer {...fillStyle} />
      <Layer {...lineStyle} />
      <Layer {...pointStyle} />
    </Source>
  );
};

export default Polygon;
