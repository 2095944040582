import React, {useState} from 'react';
import Grid from '@mui/material/Grid';

import SensorGroupForm from './SensorGroupForm';
import SensorGroupList from './SensorGroupList';

function SensorGrouping() {
  const [isEditingGroup, setIsEditingGroup] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);

  return (
    <>
      <Grid container spacing={3}>
        {isEditingGroup ? (
          <SensorGroupForm
            setIsEditingGroup={setIsEditingGroup}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
          />
        ) : (
          <SensorGroupList setIsEditingGroup={setIsEditingGroup} setSelectedGroup={setSelectedGroup} />
        )}
      </Grid>
    </>
  );
}

export default SensorGrouping;
