import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Trans} from '@lingui/macro';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {display} from '@mui/system';
import {useSnackbar} from 'notistack';
import {setIsLoadingDevices, setNewDevices} from 'redux/devices.reducer';

import * as devices from '../../api/devices';

const MyTableCell = styled(TableCell)(display);

const timestampToLocalDate = (timestamp) => {
  if (timestamp) {
    const tmp = timestamp.split(/[^0-9]/);
    return new Date(Date.UTC(tmp[0], tmp[1] - 1, tmp[2], tmp[3], tmp[4], tmp[5])).toLocaleString('sv-SE');
  }
  return '?';
};

const NewDevicesTable = ({assignDevice, title = 'Devices'}) => {
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const currentOrganization = useSelector((state) => state.session.currentOrganization);
  const newDevices = useSelector((state) => state.devices.newDevices);
  const userTypes = useSelector((state) => state.credentials.userTypes);

  const refresh = useCallback(() => {
    dispatch(setIsLoadingDevices(true));
    devices
      .getNewDevices(currentOrganization)
      .then((data) => {
        dispatch(setNewDevices(data));
      })
      .catch((e) => {
        enqueueSnackbar('Error fetching devices: ' + e.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        dispatch(setIsLoadingDevices(false));
      });
  }, [dispatch, enqueueSnackbar, currentOrganization]);

  const useDeviceList = useCallback(() => {
    return newDevices
      .sort((a, b) => (a.manufacturedAt < b.manufacturedAt ? 1 : -1))
      .map((row) => {
        return (
          <TableRow
            key={row.id}
            sx={{
              height: 75,
            }}>
            <TableCell align="left">{row.serialNumber}</TableCell>
            <MyTableCell align="left" display={{xs: 'none', md: 'table-cell'}}>
              {timestampToLocalDate(row.manufacturedAt)}
            </MyTableCell>

            {userTypes.includes('FLOWITY_USER') && (
              <TableCell align="right">
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={(e) => {
                    assignDevice(row);
                  }}>
                  <Trans>Assign</Trans>
                </Button>
              </TableCell>
            )}
          </TableRow>
        );
      });
  }, [newDevices, userTypes, assignDevice]);

  useEffect(() => {
    const interval = setInterval(refresh, 60000);
    refresh();
    return () => clearInterval(interval);
  }, [refresh]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5">{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Device Serial Number</TableCell>
                  <MyTableCell align="left" display={{xs: 'none', md: 'table-cell'}}>
                    Manufactured At
                  </MyTableCell>
                  {userTypes.includes('FLOWITY_USER') && <TableCell align="right">Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>{useDeviceList()}</TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default NewDevicesTable;
