import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import moment from 'moment-timezone';

export default function Timestamp(props) {
  const {time} = props;

  if (time === undefined) {
    return null;
  }

  let m;
  if (typeof time === 'number') {
    m = moment.utc(time * 1000);
  } else {
    m = moment.utc(time);
  }

  // TODO handle timezone
  const longTitle = m.format('YYYY-MM-DD HH:mm:ss [UTC+0]');
  const timeString = m.tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm:ss');

  return (
    <Tooltip title={longTitle} placement="bottom" arrow>
      <Typography variant="body1" {...props}>
        {timeString}
      </Typography>
    </Tooltip>
  );
}
